import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'algoliahits',
    pure: false
})
export class AlgoliaHitsPipe implements PipeTransform {
    transform(items: any[], filter: Object): any {
        if (!items || !filter) {
            return items
        }
        // console.log(filter)
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        // items.filter(item => item.title.indexOf(filter.title) !== -1);
        const filtered = []
        for (let i = 0; i < items.length; i++) {
            const nick = filter['nick']
            // console.log(items[i].active, nick, items[i][nick])
            if (items[i].active && !items[i][nick]) {
                if (!filter['exclude'].includes(items[i].subcategory) && !filter['exclude'].includes(items[i].category)) {
                    filtered.push(items[i])
                }
            }
        }
        // console.log(filtered)
        return filtered
    }
}
