import { registerLocaleData } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import ptPT from '@angular/common/locales/pt-PT'
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injector, LOCALE_ID, NgModule, NO_ERRORS_SCHEMA } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ServiceWorkerModule } from '@angular/service-worker'
import { provideTransloco, TranslocoConfig, TranslocoModule } from '@ngneat/transloco'
import { AdblockModalComponent } from '@shared/adblock-modal/adblock-modal.component'
import { AppInitService, customMissingHandler, NotifyService, PipesModule, TokenInterceptor, translocoLoader, YesErrorHandler } from '@y4w-core'
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { env } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

export function init(appInitService: AppInitService, injector: Injector) {
  window[ 'ctorinje' ] = injector // torna o Angular Injector acessivel em qualquer sítio
  return () => appInitService.init()
}

registerLocaleData(ptPT)
@NgModule({
  declarations: [
    AppComponent,
    AdblockModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: env.production, registrationStrategy: 'registerWhenStable:30000' }),
    TranslocoModule,
    PipesModule
  ],
  providers: [
    AppInitService,
    DialogService,
    DynamicDialogRef,
    DynamicDialogConfig,
    { provide: APP_INITIALIZER, useFactory: init, deps: [ AppInitService, Injector ], multi: true },
    env.production && !localStorage.getItem('debug') ? { provide: ErrorHandler, useClass: YesErrorHandler } : ErrorHandler,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-PT' },
    translocoLoader,
    customMissingHandler,
    provideTransloco({
      config: {
        availableLangs: ['pt-PT'], // availableLangs.map(lang => lang.name),
        defaultLang: 'pt-PT', // defaultLang,
        fallbackLang: `pt-PT`,
        prodMode: env.production,
        reRenderOnLangChange: true
      } as TranslocoConfig
    }),
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {
  constructor(dialog: DialogService, notify: NotifyService, injector: Injector) {
    window[ 'ctorinje' ] = injector // torna o Angular Injector acessivel em qualquer sítio
    notify.defineDialogService(dialog)
    // window['timings'].push({ time: new Date().getTime(), name: 'start AppModule - constructor' })
  }

}
