import { DOCUMENT } from '@angular/common'
import { Directive, EventEmitter, HostListener, Inject, Input, Output } from '@angular/core'
import { UploadService } from '../upload.service'
import { NgxSpinnerService } from 'ngx-spinner'

export interface ResizeOptions {
  resizeMaxHeight?: number
  resizeMaxWidth?: number
  resizeQuality?: number
  resizeType?: string
}

export interface ImageResult {
  file: File
  url: string
  dataURL?: string | ArrayBuffer
  error?: string
  resized?: {
    dataURL: string;
    type: string;
  }
}

@Directive({
  selector: 'input[type=file][imageUpload]'
})
export class ImageUploadDirective {

  @Input() resizeOptions: ResizeOptions
  @Output() imageSelected = new EventEmitter<Partial<ImageResult>>()

  private allowedExtensions = [
    'png',
    'jpg',
    'jpeg',
    'gif'
  ]
  private resizeAreaId: string

  constructor(@Inject(DOCUMENT) private document: any, private uploadSrv: UploadService, private spin: NgxSpinnerService) {
    this.resizeAreaId = `upload-${new Date().getTime()}`
  }

  @HostListener('change', ['$event'])
  readFiles(event: any) {
    this.spin.show()
    for (const file of event.target.files as File[]) {
      const result: any = {
        file: file,
        url: URL.createObjectURL(file)
      }

      let ext: string = file.name.split('.').pop()
      ext = ext && ext.toLowerCase()

      if (ext && this.allowedExtensions && this.allowedExtensions.length && this.allowedExtensions.indexOf(ext) === -1) {
        result.error = 'Extension Not Allowed'
        this.imageSelected.emit(result)
      } else {
        this.fileToDataURL(file, result).then(r => this.resize(r))
          .then(r => this.imageSelected.emit(r))
          .catch(e => {
            result.error = 'Image processing error'
            this.imageSelected.emit(result)
          })
      }
    }
  }

  private resize(result: Partial<ImageResult>): Promise<Partial<ImageResult>> {
    return this.uploadSrv.resizeImage((result.dataURL || result.url) as string, { width: this.resizeOptions.resizeMaxWidth, height: this.resizeOptions.resizeMaxHeight })
      .then(res => ({ ...result, resized: { dataURL: res, type: 'jpeg' } }) as Partial<ImageResult>)
  }

  private fileToDataURL(file: File, result: ImageResult): Promise<ImageResult> {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = function (e) {
        result.dataURL = reader.result
        resolve(result)
      }
      reader.readAsDataURL(file)
    })
  }

}
