import { Cry } from '../../cry.service'
import { timeOut } from '../../utils'
import { GatewayPartner, IPaymentBase2, PaymentMethod, PaymentStatus } from '../payment.interface'
import { PaymentService } from '../payment.service'
import { PaymentBase } from './payment.base'
import { Observable } from 'rxjs'
import { format } from 'date-fns'

export class PaymentEasypay extends PaymentBase implements IPaymentBase2 {

  private type = 'sale'
  private capture: {
    transaction_key: string;
    capture_date: string; // YYYY-mm-DD
    descriptive: string; // purchase description
  }
  private customer: {
    id?: string;
    name: string;
    email: string;
    phone: string;
    phone_indicative: string;
    fiscal_number: string;
    key: string;
    language: string;
  }
  private key: string // internal transaction ID
  private entity: string = null
  private reference: string = null

  constructor(paymentSrv: PaymentService) {
    super()
    this._paymentSrv = paymentSrv
  }

  createInstance(payment: { value: number; method: PaymentMethod }, user: any, paymentConfig: any): PaymentEasypay {
    const id = this._paymentSrv.generatePaymentID()
    this.currency = paymentConfig.currency || 'EUR'
    this.key = id
    this.value = payment.value
    this.method = payment.method
    this.paymentID = id
    this.partner = GatewayPartner.EASYPAY
    this.useLibrary = paymentConfig.useLibrary || false
    this.customer = {
      email: user.email,
      name: user.displayName,
      fiscal_number: user.fiscalNumber,
      language: 'PT',
      phone: '',
      phone_indicative: user.deliveryTo && user.deliveryTo.prefix ? user.deliveryTo.prefix : '+351',
      key: id
    }
    this.capture = {
      capture_date: format(new Date(), 'YYYY-MM-DD'),
      descriptive: `Compra no sistema de prémios YES Marketing REF ${id}`,
      transaction_key: id
    }
    return this
  }

  createNewPayment(user: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (!this.gatewayData || (this.gatewayData && !this.gatewayData.id)) {
        this._paymentSrv.requestNewPayment(user, this, this.paymentID).then((payment: IPaymentBase2) => {
          this.gatewayData = payment.getGatewayData()
          resolve(this.processResult())
        }).catch(err => reject(err))
      } else resolve(this)
    })
  }

  dispatchSuccessHook(api: any = null, data: any = null) { }

  fromJson(json: any): PaymentEasypay {
    super.fromJson(json)
    if (this['transaction']) {
      this.gatewayData = { transaction: this['transaction'] }
    }
    return this
  }

  getConfig(): any {
    return {
      currency: this.currency,
      method: this.method,
      partner: this.partner,
      paymentID: this.paymentID,
      value: this.value,
      transaction: this.transaction,
      status: this.status
    }
  }

  getMBReference(): { entity: string, reference: string } {
    return { entity: this.entity, reference: this.reference }
  }

  getPayload(): any {
    let obj: any = {}
    for (const prop in this) {
      if (!prop.startsWith('_') && prop !== 'gatewayData') obj[prop] = this[prop]
    }
    obj['capture'].transaction_key = obj['capture'].transaction_key || this.paymentID
    obj = { ...obj, ...this.gatewayData }
    return JSON.parse(JSON.stringify(obj))
  }

  getTransactionStatus(): { paymentDate: string; partner: string } {
    if (this.gatewayData && this.gatewayData.transaction && this.gatewayData.transaction.transaction && this.gatewayData.transaction.transaction.values) {
      const transaction = this.gatewayData.transaction.transaction
      if (+transaction.values.paid) {
        return {
          paymentDate: transaction.date,
          partner: GatewayPartner.EASYPAY
        }
      } else console.log('# sem pagamento')
    } else if (this.method !== PaymentMethod.CC) {
      return {
        paymentDate: this['payload'] ? this['payload'].capture.capture_date : new Date().toISOString(),
        partner: GatewayPartner.EASYPAY
      }
    }
    return null
  }

  hasFirstPayload(): boolean {
    return true
  }

  hasMBWPaid(): boolean {
    const hasPaid = this.method === PaymentMethod.MBW && this.gatewayData && this.gatewayData.method && this.gatewayData.method.status === 'success'
    if (hasPaid) this.status = PaymentStatus.PAID
    return hasPaid
  }

  onRedirect(data: any): Observable<{ success: boolean; transaction: any }> {
    return new Observable<any>(obs => {
      if (this.g.isBrowser && data.fwd && data.fwd === 'api' && data.id && data.id === Cry.get('finishPurchase', true).gatewayData.id) {
        this._paymentSrv.verifyPaymentStatus(data.id, this.partner).subscribe(result => {
          if (result.payment_status && result.payment_status === 'paid' && result.transactions) {
            this.gatewayData = { ...this.gatewayData, transaction: result.transactions.length ? result.transactions[result.transactions.length - 1] : null }
            this.status = PaymentStatus.PAID
            obs.next({
              success: true,
              transaction: result.transactions.length ? result.transactions[result.transactions.length - 1] : null
            })
          } else obs.next({ success: false })

          obs.complete()
        })
      } else {
        obs.next({ success: false })
        obs.complete()
      }
    })
  }

  processResult(data: any = null): PaymentEasypay {
    if (this.gatewayData) {
      if (this.gatewayData.method.type === 'mb') {
        return this
      } else if (this.gatewayData.method.type === 'mbw') {
        return this
      } else if (this.gatewayData.method && this.gatewayData.method.url) {
        if (!this.useLibrary) { if (this.g.isBrowser) (window as any).open(this.gatewayData.method.url) }
        return this
      }
    } else return null
  }

  setMBWPhone(phone: string): void {
    if (phone) this.customer.phone = phone
  }

  setResult(data: any): void {
    this.gatewayData = data
    if (data.method && data.method.type === 'mb') {
      this.entity = data.method.entity || null
      this.reference = data.method.reference || null
    }
  }

  // specialist method

  makeCCPayment(Easypay: any, card: any): Promise<Partial<{ payment: IPaymentBase2, error: any }>> {
    // only for tests
    Easypay.endpoint = 'https://cc.test.easypay.pt/gateway/external/api'

    return new Promise<any>(resolve => {
      Easypay.Single
        .payment(this.gatewayData.id, card)
        .then((data: any) => {
          // console.log(data);
          if (data.status === 'error' || data.status === false) {
            resolve({ payment: null, error: data.message })
          } else {
            if (data.type === '3ds') {
              if (this.g.isBrowser) {
                Cry.set('finishPurchase', this, true)
                timeOut(() => (window as any).location.href = data.url, 150)
              }
            } else if (data.type === 'authorisation') {
              resolve({ payment: this })
            }
          }
        })
    })
  }

  onClientResult(srv: PaymentService): Promise<{ payment: IPaymentBase2, orderId: string }> {
    // console.log('# onClientResult', this.paymentID, this.method, this.useLibrary)
    return new Promise<any>(resolve => {
      if (this.method === PaymentMethod.CC && this.useLibrary) {
        timeOut(() => {
          srv.verifyPaymentStatus(this.gatewayData.id, this.partner).subscribe(result => {
            console.log('# verifyPaymentStatus', result)
            if (result.payment_status && result.payment_status === 'paid' && result.transactions) {
              this.gatewayData = { ...this.gatewayData, transaction: result.transactions.length ? result.transactions[result.transactions.length - 1] : null }
              this.status = PaymentStatus.PAID
              resolve({ payment: this, orderId: this.paymentID })
            } else if (result.payment_status === 'pending') {
              resolve({ payment: this, orderId: this.paymentID })
            } else {
              resolve({ payment: this, orderId: this.paymentID })
            }
          })
        }, 1500)
      } else {
        resolve({ payment: this, orderId: this.paymentID })
      }
    })
  }

}
