import { Cry } from './cry.service'
import { GlobalService } from './global.service'
import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, UrlTree } from '@angular/router'
import { AuthService } from './auth.service'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { timeOut } from "./utils"
import { NotifyService } from "./notifications/notify.service"

@Injectable({providedIn: "root"})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    public auth: AuthService,
    private router: Router,
    public g: GlobalService,
    private notify: NotifyService
  ) { console.info(`## ${this.constructor.name}`) }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    this.verifySoftwareUpdate()
    return this.verifyProceed(next, state)
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.verifyProceed(childRoute, state)
  }

  verifyProceed(next, state): Observable<boolean> | boolean {
    if (this.g.type === 'site') return true
    if (this.g.access === 'public') return true
    return this.auth.user.pipe(
      map(user => {
        if (!user) {
          return false
        } else if (next.data.roles && next.data.roles.indexOf(user['role']) === -1) {
          this.router.navigate([`/${this.g.type}/home`])
          this.notify.update('Não tens permissão para aceder a esta página', 'btn-danger', 2000)
          return false
        } else return true
      })
    )
  }

  verifySoftwareUpdate() {
    // test for SSR
    if (this.g.isBrowser) {
      const oldVersion = Cry.getLocal('ov') || null
      const newVersion = Cry.get('nv') || null

      if (oldVersion !== null && newVersion !== null && +oldVersion < +newVersion) {
        timeOut(() => {
          Cry.setLocal('ov', `${newVersion}`);
          (<any>document).location.reload()
        }, 500)
      }
    }
  }
}
