import { TRANSLOCO_LOADER, TRANSLOCO_MISSING_HANDLER, TranslocoConfig, TranslocoLoader, TranslocoMissingHandler } from '@ngneat/transloco'
import { Injectable, OnDestroy } from '@angular/core'
import { of } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { SubSink } from 'subsink'
import { Cry } from './cry.service'
import { FirestoreService2 } from './firestore.service2'
import { GlobalService } from './global.service'
import { use } from './utils'

@Injectable({ providedIn: 'root' })
export class TranslationLoader implements TranslocoLoader, OnDestroy {
  private subs = new SubSink()
  private fss: FirestoreService2

  constructor(private g: GlobalService) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    this.listenLangs()
  }

  ngOnDestroy(): void { this.subs.unsubscribe() }

  /**
   * listenLangs OLD
   * Escula as alterações nas langs em tempo real
   * @private
   */
  private listenLangs__OLD() {
    /** Fica a escutar atualizações nas langs depois do primeiro carregamento **/
    this.subs.add(this.fss.get('langs', 'pt-PT').subscribe(lang => {
      if (this.g.isBrowser) Cry.set('pt-PT', lang, false)
      this.g.set('pt-PT', lang)
    }))
    // test for SSR
    if (this.g.isBrowser && Cry.get('nick'))
      this.subs.add(this.fss.get('langs', `pt-${this.g.nick}`).subscribe(lang => {
        Cry.set(`pt-${this.g.nick}`, lang, false)
        this.g.set(`pt-${this.g.nick}`, lang)
      }))
  }

  /**
   * listenLangs
   * Escula as alterações nas langs em tempo real
   * @private
   */
  private listenLangs() {
    /** novo metodo das langs **/
    this.subs.add(this.fss.list('languages').subscribe((terms: any[]) => {
      const lang: any = {}
      for (const term of terms) lang[term.term] = term['pt-PT']
      if (this.g.isBrowser) Cry.set('pt-PT', lang, false)
      this.g.set('pt-PT', lang)

      if (this.g.isBrowser && Cry.get('nick')) {
        const nickLang: any = {}
        for (const term of terms) nickLang[term.term] = term[`pt-${this.g.nick}`] || term['pt-PT']
        Cry.set(`pt-${this.g.nick}`, nickLang, false)
        this.g.set(`pt-${this.g.nick}`, nickLang)
      }
    }))
  }

  /**
   * getTranslation OLD
   * Versão anterior das langs
   * @param {string} langPath
   * @returns {Observable<null>}
   */
  getTranslation__OLD(langPath: string) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    console.log('## langPath', langPath)
    return (this.g.get(langPath) || Cry.get(langPath, false)
      ? of(this.g.get(langPath) || Cry.get(langPath, false))
      // ? this.g.get$(langPath).pipe(map(lang => lang || Cry.get('pt-PT', false)))
      : this.fss.get('langs', langPath))
      .pipe(tap((lang) => {
        this.g.set(langPath, lang)
        // test for SSR
        if (this.g.isBrowser) {
          Cry.set(langPath, lang, false)
          window['timings'].push({ time: new Date().getTime(), name: 'TranslocoLoader - getTranslation() loaded' })
        }
      }))
  }

  /**
   * getTranslation
   * Nova versão das langs
   * @param {string} langPath
   * @returns {Observable<{}>}
   */
  getTranslation(langPath: string) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    return (this.g.get(langPath) || Cry.get(langPath, false)
      ? of(this.g.get(langPath) || Cry.get(langPath, false))
      : this.fss.list('languages'))
      .pipe(map((terms: any) => {
        if (Array.isArray(terms)) {
          const lang: any = {}
          for (const term of terms) lang[term.term] = term[langPath] || term['pt-PT']
          this.g.set(langPath, lang)
          // test for SSR
          if (this.g.isBrowser) {
            Cry.set(langPath, lang, false)
          }
          return lang
        } else return terms
      }))
  }

}

export const translocoLoader = { provide: TRANSLOCO_LOADER, useClass: TranslationLoader }

@Injectable({ providedIn: 'root' })
export class CustomHandler implements TranslocoMissingHandler {
  constructor(private g: GlobalService) { }

  handle(key: string, config: TranslocoConfig) {
    const lang = this.g.get('pt-PT') || (window ? Cry.get('pt-PT', false) : null)
    if (lang && lang[key]) return lang[key]
    return key
  }
}

export const customMissingHandler = { provide: TRANSLOCO_MISSING_HANDLER, useClass: CustomHandler }
