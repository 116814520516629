import {Component, Input, OnInit} from '@angular/core'
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog"

@Component({
  selector: 'confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.sass']
})
export class ConfirmModalComponent implements OnInit {

  @Input() title: string
  @Input() message: string
  @Input() btnOkText: string
  @Input() btnCancelText: string

  constructor(private config: DynamicDialogConfig, private ref: DynamicDialogRef) {
  }

  ngOnInit() {
    this.title = this.config.data.title || ''
    this.message = this.config.data.message || ''
    this.btnOkText = this.config.data.btnOkText || ''
    this.btnCancelText = this.config.data.btnCancelText || ''
  }

  close() {
    this.ref.close(false)
  }

  confirmAction() {
    this.ref.close(true)
  }

}
