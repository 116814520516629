import { NotifyService } from './notifications/notify.service'
import { FirestoreApiService } from './firestore-api.service'
import { GlobalService } from './global.service'
import { Injectable } from '@angular/core'
import { map, tap } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { chunkArray, use } from './utils'
import firebase from 'firebase/app'
import 'firebase/firestore'

let env: any

export const valuesWithId = map((actions: any[]) => actions.map((a: any) => ({ ...a.payload.doc.data(), id: a.payload.doc.id })))
export const docWithId = map((action: any) => action.payload.exists ? ({ ...action.payload.data(), id: action.payload.id }) : null)

declare let window: any

@Injectable({ providedIn: 'root' })
export class FirestoreService2 {
  subcollections: any = {
    programs: false,
    dynamic: false,
    langs: false,
    languages: false,
    cards: false,
    debug: false,
    formsConfig: false,
    transactionsGateway: false,
    versions: 'settings/yes/versions',
    campaignCalendars: 'settings/nick/campaignCalendars',
    campaignTemplates: 'settings/nick/campaignTemplates',
    chains: 'settings/nick/chains',
    collection_logs: false,
    storeCategories: 'settings/nick/storeCategories',
    storeDisplays: 'settings/nick/storeDisplays',
    storeTypes: 'settings/nick/storeTypes',
    storeZones: 'settings/nick/storeZones',
    '__swap': false,
  }
  private privateWriteCollections = ['transactions', 'users', 'prospects', 'profiles', 'programs']
  private privateListCollections = ['transactions', 'users', 'prospects', 'profiles']
  fs: any = null

  private showConsoles = false

  constructor(
    public g: GlobalService,
    // public notify: NotifyService,
    private fbApi: FirestoreApiService
  ) {
    env = (window as any).y4wenvs.ENV
    window['timings'].push({ time: new Date().getTime(), name: 'start FirestoreService2 - constructor' })
    const isDebug = localStorage.getItem('debug')
    this.showConsoles = env.production ? !!isDebug : !env.production && true

    try {
      if (env.firebaseConfig) {
        firebase.initializeApp(env.firebaseConfig)
      } else throw Error('Firebase não foi iniciado')
    } catch (e) {
      if (e.message.indexOf('already exists') === -1) console.log('** ERROR', e.message)
    }

    this.fs = firebase.firestore()

    this.g.globalFetchProgram(this, this.g.nick)

    this.showConsoles = env.production ? false : this.showConsoles
  }

  nick(collection) {
    return this.g.program && this.g.program.nicks && this.g.program.nicks[collection] ? this.g.program.nicks[collection] : this.g.program?.nick || this.g.nick || 'yes'
  }

  path(collection, nick = null, subcol = null) {
    nick = nick ? nick : this.nick(collection)
    subcol = subcol || null
    let coll
    if (typeof this.subcollections[collection] === 'string')
      this.subcollections[collection].split('/')[1] === 'nick' ? coll = this.subcollections[collection].replace('nick', this.g.nick) : coll = this.subcollections[collection]
    else if (subcol)
      coll = `${collection}/${nick}/${subcol}`
    else
      coll = nick ? `${collection}/${nick}/${collection}` : `${collection}/${nick}/${collection}`
    return this.subcollections[collection] === false ? collection : coll
  }

  prepareQuery(collection, args, data = null, action = null) {
    const nick = args && args.nick ? args.nick : this.nick(collection)
    const path = args && args.rawPath ? collection : this.path(collection, nick, (args || {}).subcol || null)
    let _data = null
    if (data) {
      _data = JSON.parse(JSON.stringify(data))
      if (collection === 'langs') {
        _data._updatedAt = this.now
        _data._updatedBy = this.updatedBy
      } else {
        _data.updatedAt = this.now
        _data.updatedBy = this.updatedBy
      }
      if (action !== 'update') _data.createdAt = _data.createdAt || this.now
    }
    return { nick, path, objData: _data }
  }

  get createId() { return this.fs.collection('_').doc().id }
  get now() { return new Date().toISOString() }
  get timestamp() { return window.firebase.firestore.FieldValue.serverTimestamp() }
  get updatedBy() { return this.g && this.g.user ? this.g.user.email : '' }

  private saveCollectionLog(collection: string, total: number, type: string, args: any = null) {
    const user = this.g.user ? this.g.user.email : 'not logged'
    const _uniq = btoa(collection + JSON.stringify(args) + (args && args.api ? 'false' : 'true') + user + type)
  }

  list(collection, args = null) {
    const { path } = this.prepareQuery(collection, args)
    if (args && args.group) return this.listGroup(collection, args).pipe(tap(items => this.saveCollectionLog(path, items.length, 'list', args)))
    if (args && args.api) return this.fbApi.list(path, args).pipe(tap(items => this.saveCollectionLog(path, items.length, 'list', args)))
    return new Observable<any[]>(obs => {
      let ref = this.fs.collection(path)
      this.showConsoles && console.groupCollapsed(`%c[FSS] list ${path}`, 'color: limegreen')
      this.showConsoles && console.log('ARGS:', JSON.stringify(args))
      this.showConsoles && console.groupEnd()

      if (args) {
        if (args.where) for (const where of args.where) ref = ref.where(where[0], where[1], where[2])
        if (args.orderBy) ref = ref.orderBy(args.orderBy, args.direction ? args.direction : 'asc')
        if (args.limit) ref = ref.limit(args.limit)
      }

      let count = 0

      if (this.privateListCollections.includes(collection)) {
        this.fbApi.listViaApi(collection, args).then((res: any) => {
          obs.next(res)
          obs.complete()
        }).catch(error => console.log('[FSS] ERROR list', error.message, path))
      } else {
        ref.onSnapshot(snap => {
          count++
          const items = []
          snap.docs.forEach(doc => items.push({ ...doc.data(), id: doc.id }))
          obs.next(args && args.select && args.select.length ? this.selectFields(items, args.select) : items)
          this.saveCollectionLog(path, items.length, 'list', args)
          if (args && args.take && args.take >= count) obs.complete()
        }, error => console.log('[FSS] ERROR list', error.message, path))
      }
    })
  }

  private selectFields(docs: any[], fields: string[]): any[] {
    const selected: any[] = []
    for (const doc of docs) {
      const tmp: any = {}
      for (const key in doc) if (fields.includes(key)) tmp[key] = doc[key]
      selected.push(tmp)
    }
    return selected
  }

  listGroup(collection, args = null) {
    if (args && args.api) return this.fbApi.list(collection, { ...args, group: true })
      .pipe(tap(items => this.saveCollectionLog(`${collection}/*`, items.length, 'list', args)))
    return new Observable<any[]>(obs => {
      let ref = this.fs.collectionGroup(collection)
      this.showConsoles && console.groupCollapsed(`%c[FSS] listGroup ${collection}`, 'color: limegreen')
      this.showConsoles && console.log('ARGS:', args)
      this.showConsoles && console.groupEnd()

      if (args && args.where) for (const where of args.where) ref = ref.where(where[0], where[1], where[2])
      if (args && args.limit) ref = ref.limit(args.limit)

      let count = 0

      ref.onSnapshot(snap => {
        count++
        const items = []
        snap.docs.forEach(doc => items.push({ ...doc.data(), id: doc.id }))
        obs.next(items)
        this.saveCollectionLog(`${collection}/*`, items.length, 'list', args)
        if (args && args.take && args.take >= count) obs.complete()
      }, error => console.log('[FSS] ERROR list', error.message, `${collection}/*`))
    })
  }

  get(collection, id, args = null) {
    const { path } = this.prepareQuery(collection, args)
    if (args && args.api) return this.fbApi.get(path, id).pipe(tap(items => this.saveCollectionLog(path, 1, 'get', args)))
    return new Observable<any>(obs => {
      this.showConsoles && console.groupCollapsed(`%c[FSS] get ${path}`, 'color: limegreen')
      this.showConsoles && console.log('ID:', id)
      this.showConsoles && console.log('ARGS:', args)
      this.showConsoles && console.groupEnd()
      try {
        let count = 0
        this.fs.collection(path).doc(id).onSnapshot(snap => {
          count++
          obs.next(snap.data() ? { ...snap.data(), id: snap.id } : null)
          this.saveCollectionLog(path, 1, 'get', args)
          if (args && args.take && args.take >= count) obs.complete()
        }, error => console.log('[FSS] ERROR get', error.message, path))
      } catch (e) {
        console.log('[FSS] ERR', e.message)
        /*if (!env.production) */console.log('[FSS] get', collection, id, args)
      }
    })
  }

  getBy(collection, field, value, args = null) {
    const { path } = this.prepareQuery(collection, args)
    if (args && args.api) return this.fbApi.list(path, { ...args, where: [[field, '==', value]] })
      .pipe(map(values => values.length ? values[0] : null))
      .pipe(tap(items => this.saveCollectionLog(path, items ? 1 : 0, 'getBy', args)))
    return new Observable<any>(obs => {
      const { path } = this.prepareQuery(collection, args)
      this.showConsoles && console.groupCollapsed(`%c[FSS] getBy ${path}`, 'color: limegreen')
      this.showConsoles && console.log('FIELD:', field)
      this.showConsoles && console.log('VALUE:', value)
      this.showConsoles && console.log('ARGS:', args)
      this.showConsoles && console.groupEnd()
      let count = 0
      if (this.privateWriteCollections.includes(collection)) {
        this.fbApi.listViaApi(collection, {
          ...args,
          queryArray: [[field, '==', value]]
        }).then((res: any[]) => res.length ? res[0] : null).then((res: any) => {
          obs.next(res)
          obs.complete()
        }).catch(error => console.log('[FSS] ERROR list', error.message, path))
      } else {
        this.fs.collection(path).where(field, '==', value).onSnapshot(snap => {
          count++
          obs.next(snap.docs.length ? { ...snap.docs[0].data(), id: snap.docs[0].id } : null)
          this.saveCollectionLog(path, snap.docs.length ? 1 : 0, 'getBy', args)
          if (args && args.take && args.take >= count) obs.complete()
        }, error => console.log('[FSS] ERROR getBy', error.message, path))
      }
    })
  }

  // to update some fields of a document without overwriting the entire document, use the update() method
  // update will update fields but will fail if the document doesn't exist
  update(collection, id, data, args = null): Promise<any> {
    const { objData, path } = this.prepareQuery(collection, args, data, 'update')
    objData.id = id
    this.showConsoles && console.groupCollapsed(`%c[FSS] update ${path}`, 'color: cyan')
    this.showConsoles && console.log('ARGS:', args)
    this.showConsoles && console.log('DATA:', objData)
    this.showConsoles && console.groupEnd()
    const notify = use<NotifyService>(NotifyService)
    return (this.privateWriteCollections.includes(collection) ? this.fbApi.updateViaApi(collection, id, objData, args) : this.fs.collection(path).doc(id).update(objData))
      .then((result) => { if (!args || !args.silent) notify.update(`${this.g.user && this.g.user.role === 'admin' ? collection + ':' : ''} Dados gravados com sucesso.`, 'btn-success', 3000); return result })
      .catch(error => console.log('[FSS] ERROR update', error.message, path))
  }

  // to create or overwrite a single document, use set()
  // set without merge will overwrite a document or create it if it doesn't exist yet
  // when you use set() to create a document, you must specify an ID for the document to create
  // if the document does not exist, it will be created
  // if the document does exist, its contents will be overwritten with the newly provided data, unless you
  // specify that the data should be merged into the existing document
  // If you're not sure whether the document exists, use merge() to merge the new data with any existing document
  // to avoid overwriting entire documents
  set(collection, id, data, args = null): Promise<any> {
    const { objData, path } = this.prepareQuery(collection, args, data)
    objData.id = id
    this.showConsoles && console.groupCollapsed(`%c[FSS] set ${path}`, 'color: cyan')
    this.showConsoles && console.log('ID:', id)
    this.showConsoles && console.log('ARGS:', args)
    this.showConsoles && console.log('DATA:', objData)
    this.showConsoles && console.groupEnd()
    const notify = use<NotifyService>(NotifyService)
    return (this.privateWriteCollections.includes(collection) ? this.fbApi.setViaApi(collection, id, objData, args) : this.fs.collection(path).doc(id).set(objData))
      .then((result) => { if (!args || !args.silent) notify.update(`${this.g.user && this.g.user.role === 'admin' ? collection + ':' : ''} Dados gravados com sucesso.`, 'btn-success', 3000); return result })
      .catch(error => console.log('[FSS] ERROR set', error.message, path))
  }

  setBatch(collection: string, items: any[], args: any = null): Promise<void> {
    const { path } = this.prepareQuery(collection, args)
    return this.saveBatch(path, items)
  }

  // merge (set with merge) will update fields in the document or create it if it doesn't exists
  merge(collection, id, data, args = null): Promise<any> {
    const { objData, path } = this.prepareQuery(collection, args, data, 'update')
    objData.id = id
    this.showConsoles && console.groupCollapsed(`%c[FSS] merge ${path}`, 'color: cyan')
    this.showConsoles && console.log('path:', path)
    this.showConsoles && console.log('ID:', id)
    this.showConsoles && console.log('ARGS:', args)
    this.showConsoles && console.log('DATA:', objData)
    this.showConsoles && console.groupEnd()
    const notify = use<NotifyService>(NotifyService)
    return (this.privateWriteCollections.includes(collection) ? this.fbApi.mergeViaApi(collection, id, objData, args) : this.fs.collection(path).doc(id).set(objData, { merge: true }))
      .then((result) => { if (!args || !args.silent) notify.update(`${this.g.user && this.g.user.role === 'admin' ? collection + ':' : ''} Dados gravados com sucesso.`, 'btn-success', 3000); return result })
      .catch(error => console.log('[FSS] ERROR merge', error.message, path))
  }

  // sometimes there isn't a meaningful ID for the document, and it's more convenient to let Cloud Firestore
  // auto-generate an ID for you; you can do this by calling add()
  // .add() and .doc().set() are completely equivalent, so you can use whichever is more convenient
  // only difference is that .doc().set() doesn't add createdAt automatically, you have to set it
  add(collection, data, args = null): Promise<any> {
    const { objData, path } = this.prepareQuery(collection, args, data)
    if (!objData.hasOwnProperty('active')) objData.active = true
    this.showConsoles && console.groupCollapsed(`%c[FSS] add ${path}`, 'color: cyan')
    this.showConsoles && console.log('ARGS:', args)
    this.showConsoles && console.log('DATA:', objData)
    this.showConsoles && console.groupEnd()
    objData['id'] = this.createId
    const notify = use<NotifyService>(NotifyService)
    return (this.privateWriteCollections.includes(collection) ? this.fbApi.setViaApi(collection, objData.id || this.createId, objData, args) : this.fs.collection(path).doc(objData.id).set(objData))
      .then((result) => { if (!args || !args.silent) notify.update(`${this.g.user && this.g.user.role === 'admin' ? collection + ':' : ''} Dados gravados com sucesso.`, 'btn-success', 3000); return result })
      .catch(error => console.log('[FSS] ERROR add', error.message, path))
  }

  addBatch(collection: string, items: any[], args: any = null): Promise<void> {
    const { path } = this.prepareQuery(collection, args)
    const clearItems = items.map(i => ({ ...i, id: this.createId }))
    return this.saveBatch(path, clearItems)
  }

  // preferably, use update with active: false ... don't EVER(!!!) expose this method to users' features
  delete(collection, id, args = null): Promise<any> {
    const { path } = this.prepareQuery(collection, args)
    this.showConsoles && console.groupCollapsed(`%c[FSS] delete ${path}`, 'color: red')
    this.showConsoles && console.log('ID:', id)
    this.showConsoles && console.log('ARGS:', args)
    this.showConsoles && console.groupEnd()
    return this.fs.collection(path).doc(id).delete()
  }

  async deleteBatch(collection: string, items: any[], args: any = null): Promise<void> {
    const { path } = this.prepareQuery(collection, args)
    const splited = chunkArray(items, 499)

    for (const spl of splited) {
      const batch = this.fs.batch()
      for (const doc of spl) {
        if (doc.id) {
          const ref = this.fs.collection(path).doc(doc.id)
          batch.delete(ref)
        }
      }
      try {
        await batch.commit()
        console.log(path, items.length)
      } catch (e) {
        console.log('[FSS] ) ERRO:', e.message)
      }
    }
  }

  async saveBatch(path: string, itemsDocs: any[]): Promise<void> {
    const splited = chunkArray(itemsDocs, 499)

    for (const spl of splited) {
      const batch = this.fs.batch()
      for (const doc of spl) {
        const id = doc.id || this.createId
        const ref = this.fs.collection(path).doc(id)
        batch.set(ref, { ...doc, id }, { merge: true })
      }
      try {
        await batch.commit()
        console.log(path, itemsDocs.length)
      } catch (e) {
        console.log('[FSS] ) ERRO:', e.message)
      }
    }
  }

  count(collection: string): Observable<any> {
    return this.get('collectionsTotals', collection).pipe(map(count => count || { totals: 0, actives: 0 }))
  }

}
