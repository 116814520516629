import { NgModule } from '@angular/core'
import { TranslocoModule } from '@ngneat/transloco'
import { DialogModule } from 'primeng/dialog'
import { NotificationsComponent } from './notifications'
import { CommonModule } from '@angular/common'
import { ConfirmModalComponent } from './confirm-modal.component'
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog'
import { FormsModule } from '@angular/forms'
import { PromptModalComponent } from './prompt-modal.component'

@NgModule({
  declarations: [
    NotificationsComponent,
    PromptModalComponent,
    ConfirmModalComponent
  ],
  imports: [
    CommonModule,
    DynamicDialogModule,
    FormsModule,
    DialogModule,
    TranslocoModule
  ],
  exports: [
    NotificationsComponent
  ],
  providers: [ DialogService ]
})
export class NotificationsModule { /* WG */}
