<p-dialog closeIcon="fas fa-times" [modal]="true" [closable]="false" [visible]="display" header="AdBlock detectado!">
  <div class="modal-body text-center">
    <p>Notámos que está a usar um adBlock!<br>
      Por favor desactive o Adblock para este site!</p>
    <div style="width: 100%;font-size:96px;font-weight: 900;">!</div>
  </div>
  <div class="modal-footer mx-auto d-block text-center">
    <button type="button" class="btn btn-warning" (click)="reload()" data-dismiss="modal">
      <i class="fa fa-refresh" aria-hidden="true"></i>Já desactivei o Adblock!
    </button>
  </div>
</p-dialog>
