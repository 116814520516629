import { Injectable, Inject, NgZone } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { LoadScriptsService } from './load-scripts.service'

@Injectable({ providedIn: 'root' })
export class StyleLoader {

  constructor(@Inject(DOCUMENT) private document: Document, private zone: NgZone, private load: LoadScriptsService) {
    console.info(`## ${this.constructor.name}`)
  }

  loadStyle(nick: string, url: string = null) {
    this.load.loadStyle(url || `assets/${nick}/styles.css`).subscribe(() => null)
  }

  loadFont(name: string, fonts: any) {
    if (fonts) {
      if (fonts.urlFamilyMonospace) this.load.loadStyle(fonts.urlFamilyMonospace).subscribe(() => null)
      if (fonts.urlFamilySansSerif) this.load.loadStyle(fonts.urlFamilySansSerif).subscribe(() => null)
      // for (const f of fonts.split(',')) {
      //   if (f.indexOf('sans-serif') === -1 ) {
      //     this.load.loadStyle(`https://fonts.googleapis.com/css2?family=${f}&display=swap`).subscribe(() => null)
      //   }
      // }
    }
  }

}
