import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, of } from 'rxjs'
import { switchMap, tap, first } from 'rxjs/operators'
import { NotifyService } from './notifications/notify.service'
import { Cry } from './cry.service'
import { GlobalService } from './global.service'
import { FirestoreService2 } from './firestore.service2'
import firebase from "firebase/app"
import "firebase/auth"
import { MenusService } from './menus.service'
import { timeOut, use } from './utils'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { TranslocoService } from '@ngneat/transloco'

let env: any
let BUILD: any

@Injectable({ providedIn: "root" })
export class AuthService {
  user: Observable<any | null>
  afAuth: any = null
  private fss: FirestoreService2

  constructor(
    private router: Router,
    private notify: NotifyService,
    public g: GlobalService,
    private http: HttpClient,
    private menuService: MenusService,
    private translocoSrv: TranslocoService,
  ) {
    env = (window as any).y4wenvs.ENV
    BUILD = (window as any).y4wenvs.BUILD

    // test for SSR
    if (this.g.isBrowser) {
      try {
        // console.log('* AuthService starting firebase if no present');
        if (env.firebaseConfig) {
          firebase.initializeApp(env.firebaseConfig)
        } else throw Error('Firebase não foi iniciado')
      } catch (e) {
        if (e.message.indexOf('already exists') === -1) console.log('** ERROR', e.message)
      }

      this.afAuth = firebase.auth()
      this.init()
    }
  }

  private async init() {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    const host = window ? window.location.host || window.location.hostname : 'https://'
    if (this.g.program && this.g.program.logins.anonymous && (host.indexOf('.bo.') === -1 && host.indexOf('admin.') === -1)) {
      await this.anonymousLogin().then(() => this.router.navigate([`/${this.g.program.type}`]))
    }
    this.user = this.onAuthStateChanged().pipe(
      // tap(u => console.log('# onAuthStateChanged', u)),
      switchMap((authUser: any) => (authUser ? this.fss.get('users', authUser.uid, { api: false }) : of(null))),
      tap(user => { if (user) { this.g.set('user', user); this.menuService.setProfile(user); this.menuService.loadMenus() } }),
      tap(user => { if (user) this.listenBatchLogoff() }),
      // tap(user => { if (user && user.segment) { this.fss.getBy('segments', 'name', user.segment).subscribe(segment => this.g.set('segment', segment)) } })
    )
  }

  private onAuthStateChanged() {
    return new Observable<any>(obs => this.afAuth.onAuthStateChanged(user => obs.next(user)))
  }

  anonymousLogin() {
    return FingerprintJS.load()
      .then(fp => fp.get())
      .then(result => {
        // Este é o identificador único do navegador
        const visitorId = result.visitorId
        // console.log('# FingerprintJS', visitorId)
        return this.http.post(`${this.g.apiUrl}/user/userAction`, Cry.crypt(JSON.stringify({ action: 'anonymousLogin', uid: visitorId, email: `${visitorId}@yesanon.com` }), true)).pipe(first()).toPromise()
          .then((anon: string) => {
            const _anon = JSON.parse(Cry.decrypt(anon, true))
            // console.log(_anon)
            return this.afAuth.signInWithCustomToken(_anon.token)
              .then(async (result) => {
                let dbUser = await this.fss.get('users', result.user.uid, { api: true }).pipe(first()).toPromise()
                if (!dbUser) {
                  dbUser = {
                    active: true,
                    id: result.user.uid,
                    uid: result.user.uid,
                    displayName: 'Convidado',
                    firstName: 'Convidado',
                    nick: this.g.nick,
                    role: 'user',
                    email: result.user.email || `${visitorId}@yesanon.com`,
                    isAnonymous: true,
                    createdAt: new Date().toISOString(),
                  }
                  // cria o user via cripted API
                  return this.http.post(this.g.apiUrl + '/db/action', Cry.crypt(JSON.stringify({
                    action: 'createUser',
                    id: result.user.uid,
                    nick: this.g.nick,
                    data: dbUser
                  }), true)).pipe(first()).toPromise().then(() => {
                    this.http.post(this.g.apiUrl + '/users/updateUserAuth', Cry.crypt(JSON.stringify({ uid: result.user.uid, displayName: dbUser.displayName, email: dbUser.email }), true))
                      .pipe(first()).toPromise().then(ok => !env.production && console.log('#updateUserAuth OK', ok)).catch(_er => console.log('#updateUserAuth', _er.message))
                    return result.user
                  })
                } else return result.user
              })
          })
      })

    // return this.afAuth.signInAnonymously()
    //   .then(credential => this.updateUserData(credential.user))
    //   .catch(error => this.handleError(error))
  }

  emailSignUp(email: string, password: string) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then(credential => this.updateUserData(credential.user))
    // .catch(error => this.handleError(error))
  }

  emailLogin(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then(credential => credential.user)
  }

  resetPassword(email: string) {
    this.afAuth.sendPasswordResetEmail(email)
      .then(() => this.notify.update('Password update email sent', 'btn-success', 3000))
      .catch(error => this.handleError(error))
  }

  resetPasswordEmail(body = null): Promise<any> {
    if (body === null) {
      const _program = {
        name: this.g.program.name,
        nick: this.g.nick,
        email: this.g.program.email,
        sender: this.g.program.sender,
        imgHeader: this.g.program.images['emailHeader'],
        imgFooter: this.g.program.images['emailFooter'],
        templateId: this.g.program.emails['resetPassword'],
        url: this.g.program.url
      }
      body = {
        nick: this.g.nick,
        email: this.g.user.email,
        displayName: this.g.user.displayName,
        program: _program
      }
    }
    const data = { email: body.email, expires: new Date().getTime() + (60 * 60 * 1000) }
    const expires = Cry.crypt(JSON.stringify(data), true)
    const host = (window as any).location.hostname
    const link = `${host}${host.indexOf('yes4') > -1 ? ':4400' : ''}/login/resetPassword/${expires}`
    return this.post('user/userAction', Cry.crypt(JSON.stringify({ ...body, link, action: 'resetPassword' }), true)).pipe(first())
      .toPromise()
      .then(ok => {
        const _ok: any = JSON.parse(Cry.decrypt(ok, true))
        // console.log(_ok)
        if (_ok.success) this.notify.update(this.translocoSrv.translate('email_sent_check_your_mailbox'), 'btn-success', 8000)
        else if (_ok.error) this.notify.update(ok.error, 'btn-danger', 5000)
      })
  }

  signOut(navigate = true) {
    this.afAuth.signOut().then(() => {
      Cry.removeLocal('bo-nick')
      if (navigate) this.router.navigate(['/login'])
      this.g.set('user', null, true)
    })
  }

  // If error, console log and notify user
  private handleError(error: Error) {
    // console.error(error)
    this.notify.update(error.message, 'btn-danger', 3000)
  }

  // Sets user data to firestore after succesful signup
  updateUserData(user, setGlobal = true) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    const data = {
      uid: user.uid,
      isAnonymous: user.isAnonymous || false,
      email: user.email || null,
      displayName: user.displayName || 'nameless user',
      photoURL: user.photoURL || 'https://goo.gl/Fz9nrQ',
      role: 'user'
    }

    if (setGlobal) this.g.set('user', data) // added by walter
    return this.fss.merge('users', user.uid, data).then(() => data)
  }

  post(endpoint: string, body: any): Observable<any> {
    return this.http.post(`${this.g.apiUrl}/${endpoint}`, body)
  }

  // Used by the http interceptor to set the auth header
  getUserIdToken(): Observable<string> {
    if (this.afAuth && this.afAuth.currentUser) {
      return new Observable<any>(obs => {
        this.afAuth.currentUser.getIdToken().then(token => { obs.next(token); obs.complete() }).catch(e => obs.error(e))
      })
    }
    // this.signOut()

    return of(null)
  }

  isSuperUser = (user: any) => {
    return [
      'mrsilva@yesmkt.com',
      'waltergandarella@yesmkt.com',
      'pedroferreiro@yesmkt.com'
    ].indexOf(user.email) > -1
  }

  afterSignIn(authUser: any): Promise<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    return new Promise((resolve, reject) => {
      if (!authUser || (authUser && authUser.error)) {
        this.notify.update(authUser.error, 'btn-danger', 3000)
        reject(authUser.error || 'noauth')
      } else {
        this.fss.get('users', authUser['uid'], { api: true }).toPromise().then(user => {
          if (user && user.active) {
            this.g.set('user', user)
            const host = window.location.host || window.location.hostname
            // this.push.registerDeviceToUser()
            // if (!user.firstLogin) this.automation.dispatchEvent('first_login', { ...user, lastLogin: new Date().toISOString(), firstLogin: user.firstLogin || new Date().toISOString() })
            this.fss.merge('users', user.uid, { lastLogin: new Date().toISOString(), firstLogin: user.firstLogin || new Date().toISOString() }, { silent: true })
            if (host.indexOf('.bo.') > -1 || host.indexOf('admin.') > -1) this.router.navigate(['/admin'])
            else if (this.g.get('redirect') && this.g.get('redirect') !== '/') this.router.navigate([this.g.get('redirect')])
            else if (this.g.program.options.segmentsRedirectByType) this.redirectBySegmentType(this.g.user, true)
            else this.router.navigate([this.g.type, 'home'])
            resolve(user)
          } else {
            timeOut(() => this.signOut(false), 500)
            reject(!user ? 'auth-user-not-found' : 'auth-user-not-active')
            // this.notify.update(this.translocoSrv.translate('user_not_registed_or_not_activated'), 'btn-danger', 3000)
          }
        })
      }
    })
  }

  async redirectBySegmentType(user: any, login: boolean = false) {
    const path = window.location.pathname
    console.log('redirectBySegmentType', path)
    const segmentsMapping = await this.fss.list('settings', { subcol: 'segmentsMapping' }).pipe(first()).toPromise()
    if (user && user.uid && user.active) {
      console.log(user)
      const userSegments = user.segments || []
      if (!userSegments.filter(o => o === user.segment.id).length) userSegments.push(user.segment.id)

      for (const map of segmentsMapping) {
        if (userSegments.includes(map.segment)) {
          Cry.set('type', map.type)
          this.g.set('type', map.type)
          if (login || path === '/') this.router.navigate([`/${map.type}/home`])
        }
      }
}
    // console.log(segmentsMapping, userSegments)
  }

  private listenBatchLogoff() {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    this.fss.list('logs', { where: [['type', '==', 'batchLogoff']], limit: 1, order: 'createdAt', direction: 'desc' })
      .subscribe(logs => {
        if (logs.length) {
          const log = logs[0]
          const ls = localStorage.getItem(`${log.type}-${log.id}`)
          const time = new Date(log.createdAt).getTime()
          const now = new Date().getTime()
          if (ls === null && log.active && (now - time < (86400000 * 30))) {
            this.fss.get('users', this.g.get('user').id, { api: true }).subscribe(user => {
              if (!user.isAnonymous && user.provider !== 'OpenID') {
                timeOut(() => {
                  console.log('# batch logoff')
                  localStorage.setItem(`${log.type}-${log.id}`, 'true')
                  this.signOut(true)
                  if (window) window.location.reload()
                }, 4000)
              }
            })
          }
        }
      })
  }

  ////// temporariamente

  saveDebug(error: any) {
    const user = this.g.get('user')
    const err = {
      error: {
        message: error.message,
        stack: error.stack || '',
        name: error.name || ''
      },
      path: window.location.href,
      user: user ? user.email : 'indefinido',
      nick: this.g.nick,
      timestamp: new Date().getTime(),
      createdAt: new Date().toISOString(),
      build: BUILD
    }

    this.http.post(`${this.g.apiUrl}/user/userAction?noAuthRequest`, Cry.crypt(JSON.stringify({ action: 'debug', err }), true)).pipe(first()).toPromise()
  }
}
