import { Injectable } from '@angular/core'
import { saveAs } from 'file-saver-es'
import { read, utils, write } from 'xlsx'
import uniqBy from 'lodash-es/uniqBy'
import pick from 'lodash-es/pick'
import filter from 'lodash-es/filter'
import { GlobalService } from './global.service'
import { UploadService } from './upload.service'

type AOA = Array<Array<any>>
// let XLSX = null;

function s2ab(s: string): ArrayBuffer {
  const buf: ArrayBuffer = new ArrayBuffer(s.length)
  const view: Uint8Array = new Uint8Array(buf)
  // eslint-disable-next-line no-bitwise
  for (let row = 0; row !== s.length; ++row) view[row] = s.charCodeAt(row) & 0xFF
  return buf
}

@Injectable({ providedIn: "root" })
export class XlsService {
  wopts: any = { bookType: 'xlsx', type: 'binary' }
  XLSX = null

  constructor(
    private uploadService: UploadService,
    public g: GlobalService
  ) { console.info(`## ${this.constructor.name}`) }

  loadXls(evt: any, sheet: string = null) {
    return new Promise(async (resolve, reject) => {
      // if (!this.XLSX) this.XLSX = await import('xlsx')
      let data: any = []
      const target: DataTransfer = <DataTransfer>(evt.target)
      if (target.files.length !== 1) throw new Error('Cannot use multiple files')
      const reader: FileReader = new FileReader()
      reader.onload = (e: any) => {
        const bstr: string = e.target.result
        // const wb: any = this.XLSX.read(bstr, { type: 'binary' })
        const wb: any = read(bstr, { type: 'binary' })
        let ws: any
        if (sheet) {
          if (sheet && !wb.Sheets[sheet]) resolve(null)
          ws = wb.Sheets[sheet || 0] /* grab sheet */
        } else {
          ws = wb.Sheets[Object.keys(wb.Sheets)[0]] /* grab sheet */
        }
        // data = <AOA>(this.XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false }))
        data = <AOA>(utils.sheet_to_json(ws, { header: 1, blankrows: false }))
        resolve(data)
      }
      reader.readAsBinaryString(target.files[0])
    })
  }

  AOAtoJSON(data: any) {
    const items = []
    for (let row = 1, rows = data.length; row < rows; row++) {
      const item = {}
      let colName = ''
      for (let col = 0; col < data[0].length; col++) {
        colName = data[0][col]
        if (typeof colName === 'string') colName = colName.trim()
        item[colName] = data[row][col] ? data[row][col] : ''
        if (typeof item[colName] === 'string') item[colName] = item[colName].trim()
      }
      items.push(item)
    }
    return items
  }

  exportCsv(collection, data) {
    let csv = ''

    for (const [i, line] of data.entries()) {
      if (i === 0) {
        for (const prop in line) {
          csv += prop + ';'
        }
        csv += '\n'
      }

      for (const prop in line) {
        csv += line[prop] + ';'
      }
      csv += '\n'
    }

    saveAs(new Blob([s2ab(csv)]), `${collection}.csv`)
  }

  async export(_collection, _data, seller?, storage?, filename?): Promise<any> {
    const collection = Array.isArray(_collection) ? _collection : [_collection]
    const data = Array.isArray(_collection) ? _data : [_data]
    const wb: any = utils.book_new()

    for (const [i, coll] of collection.entries()) {
      const ws: any = utils.json_to_sheet(data[i]) /* generate worksheet */
      utils.book_append_sheet(wb, ws, coll)
    }
    const wbout: string = write(wb, this.wopts) /* save to file */
    const file = new Blob([s2ab(wbout)]) // use the Blob or File API
    if (!storage || storage === false) {
      saveAs(new Blob([s2ab(wbout)]), filename || `${collection[0].replace('.xls', '').replace('.xlsx', '')}.xlsx`)
    } else {
      const storageUrl = await this.uploadService.uploadXls$$(wbout, this.g.nick, seller, collection)
      return storageUrl
    }
  }

  unflatten(items, property, properties, objName) {
    let uniqueItems = uniqBy(items, property)
    // console.log(uniqueItems)
    uniqueItems = this.getNestedArrays(uniqueItems, items, property, properties, objName)
    return uniqueItems
  }

  getNestedArrays(uniqueItems, items, property, properties, objName) {
    for (const unique of uniqueItems) {
      const obj = {}
      unique[objName] = []
      obj[property] = unique[property]
      const filtered: any = filter(items, obj)
      // console.log(unique, property, unique[property], obj, filtered)
      if (filtered.length > 0) {
        // console.log(filtered, properties)
        for (const pack of filtered) {
          const picked = pick(pack, properties)
          // console.log(picked)
          unique[objName].push(picked)
        }
      }
    }
    return uniqueItems
  }

  getInfo(colName) {
    let type
    switch (colName.toString().substr(5, 1)) {
      case 'P': type = 'permanent'; break
      case 'C': type = 'calendar'; break
      case 'T': type = 'calendar'; break
    }
    const name = colName.toString().substr(9, 99)
    return { type, name }
  }

  getTemplate(templateType, templates) {
    const template = templates[templateType]
    return template
  }

  getNestedData(items) {
    const ItemsProducts = this.unflatten(items, 'packId', ['sellerCode', 'unitsPack'], 'products')
    const itemsPacks = this.unflatten(ItemsProducts, 'promotionId', ['packId', 'priceTable_Continente', 'priceTable_Madeira', 'products'], 'packs')
    for (const item of itemsPacks) {
      delete item['products']
      delete item['sellerCode']
      delete item['unitsPack']
      delete item['priceTable_Continente']
      delete item['priceTable_Madeira']
      item['uniq'] = item['promotionId']
      delete item['packId']
    }
    return itemsPacks
  }

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  count(arr) {
    return arr.reduce(function (a, b) {
      return a + b
    }, 0)
  }

  sum(items, prop) {
    return items.reduce(function (a, b) {
      return a + b[prop]
    }, 0)
  }

}
