import { Inject, Injectable, OnDestroy } from '@angular/core'
import { Observable, ReplaySubject } from 'rxjs'
import { TranslocoService } from '@ngneat/transloco'
import { ApiService } from '../api.service'
import { FirestoreService2 } from '../firestore.service2'
import { GlobalService } from '../global.service'
import { LoadScriptsService } from '../load-scripts.service'
import { use } from '../utils'
import { GatewayPartner, IPayment, IPaymentBase2, PaymentMethod } from './payment.interface'
import { PaymentEasypay } from './drivers/payment.easypay'
import { PaymentStripe } from './drivers/payment.stripe'
import { PaymentBraintree } from './drivers/payment.braintree'
import { SubSink } from 'subsink'

@Injectable({ providedIn: 'root' })
export class PaymentService implements OnDestroy {

  private subs = new SubSink()
  private fss2: FirestoreService2

  constructor(
    private api: ApiService,
    public g: GlobalService,
    private transloco: TranslocoService,
    private loadSrv: LoadScriptsService
  ) {
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  /********************
   ** PAYMENT GATEWAY
   *******************/

  generatePaymentID(user: any = null, orderID: string = null): string {
    if (!this.fss2) this.fss2 = use<FirestoreService2>(FirestoreService2)
    // return `${user.id.substr(0, 5)}:${new Date().getTime()}:${this.g.nick}:${orderID}`;
    // return `${user.id}:${this.g.nick}:${orderID}`;
    return this.fss2.createId
  }

  /**
   * Cria o payload do método de pagamanto configurado no programa
   * @param payment
   * @param program
   * @param user
   */
  gatewayFactory(payment: Partial<IPayment>, user: any, paymentGateway: any): IPaymentBase2 /*PaymentBase*/ {
    const partner = paymentGateway.methods[payment.method] || null
    const gateway = paymentGateway.gateways[partner] || null

    if (gateway && partner) {
      switch (partner) {
        // case GatewayPartner.EASYPAY: return new EasyPayment({...payment}, user, paymentGateway);
        case GatewayPartner.EASYPAY:
          return new PaymentEasypay(this).createInstance({ value: payment.value, method: payment.method }, user, gateway)
        // case GatewayPartner.STRIPE: return new StripePayment({...payment, currency: paymentGateway.currency || 'EUR'}, user);
        case GatewayPartner.STRIPE:
          return new PaymentStripe(this).createInstance({ value: payment.value, method: payment.method }, user, gateway)
        // case GatewayPartner.BRAINTREE: return new BraintreePayment({...payment, currency: paymentGateway.currency || 'EUR'}, user, this.g.get('paymentGateway').tokenizationKey);
        case GatewayPartner.BRAINTREE:
          return new PaymentBraintree(this).createInstance({ value: payment.value, method: payment.method }, user, gateway)
        default:
          return null
      }
    }
    return null
  }

  /**
   * Gera os objetos de Gateway baseado em JSON de resposta ou armazenado
   * Serve para retomar um método de pagamento em sessões futuras do sistema
   * garantindo todas as funcionalidades das classes de Gateway
   * @param json
   */
  paymentObjectFromJson(json: any): IPaymentBase2 {
    switch (json.partner) {
      // case GatewayPartner.EASYPAY: return new EasyPayment().fromJson(json) as EasyPayment;
      case GatewayPartner.EASYPAY:
        return new PaymentEasypay(this).fromJson(json)
      // case GatewayPartner.STRIPE: return new StripePayment().fromJson(json) as StripePayment;
      case GatewayPartner.STRIPE:
        return new PaymentStripe(this).fromJson(json)
      // case GatewayPartner.BRAINTREE: return new BraintreePayment().fromJson(json) as BraintreePayment;
      case GatewayPartner.BRAINTREE:
        return new PaymentBraintree(this).fromJson(json)
      default:
        return null
    }
  }

  /**
   * Faz a requisição de pagamento ao Gateway selecionado.
   * Caso não haja itens payable no utilizador selecionado, o método devolverá NULL
   * Caso o gateway responda com sucesso, o método devolve o objecto de resposta
   * Caso contrário, o método devolverá o erro do gateway
   * @param user
   * @param paymentDetail
   */
  // async requestPayment(user: any, paymentDetail: Partial<IPayment>, orderID: string): Promise<PaymentBase | Error | string> {
  //   return new Promise<any>(async (resolve, reject) => {
  //     const payables = this.listPayables(user);
  //
  //     if (payables.length && paymentDetail) {
  //       const paymentID = this.generatePaymentID(user, orderID);
  //       const payment = this.gatewayFactory({...paymentDetail, paymentID}, user);
  //
  //       if (!payment.hasFirstPayload()) {
  //         payment.setResult({});
  //       } else {
  //         try {
  //           const gatewayData = await this.createNewPaymentRequest(payment, paymentID, user).toPromise();
  //           payment.setResult(gatewayData);
  //         } catch (err) {
  //           reject(err.message || err);
  //         }
  //       }
  //
  //       if (payment.gatewayData) {
  //         await this.fss2.save('transactionsGateway').id(paymentID).data({user: user.id, nick: this.g.nick, order: orderID}).promise();
  //
  //         /** Chama o processamento específico de cada Gateway **/
  //         resolve(payment.processResult());
  //       } else resolve(null);
  //
  //     } else resolve(null);
  //   });
  // }

  async requestNewPayment(user: any, payment: IPaymentBase2, orderID: string): Promise<IPaymentBase2 | Error | string> {
    if (!this.fss2) this.fss2 = use<FirestoreService2>(FirestoreService2)
    return new Promise<any>(async (resolve, reject) => {
      const payables = this.listPayables(user)

      if (payables.length) {
        // const paymentID = this.generatePaymentID(user, orderID);
        const paymentID = payment.getId()

        if (!payment.hasFirstPayload()) {
          payment.setResult({})
        } else {
          try {
            const gatewayData = await this.createNewPaymentRequest(payment, paymentID, user).toPromise()
            // console.log('# service 117', gatewayData);
            payment.setResult(gatewayData)
          } catch (err) {
            console.log('# requestNewPayment', err)
            reject(err.message || err)
          }
        }

        if (payment.getGatewayData()) {
          await this.fss2.update('transactionsGateway', paymentID, { user: user.id, nick: this.g.nick, order: orderID })

          /** Chama o processamento específico de cada Gateway **/
          resolve(payment)
        } else resolve(null)

      } else resolve(null)
    })
  }

  /**
   * Cria uma nova solicitação de pagamento na plataforma EasyPay
   * @param payment
   * @param user
   */
  createNewPaymentRequest(payment: IPaymentBase2, paymentID: string, user: any): Observable<any> {
    if (!this.fss2) this.fss2 = use<FirestoreService2>(FirestoreService2)
    return new Observable<any>(obs => {
      const paymentObject: any = payment.getConfig()
      this.api.post('opayment/new', { ...paymentObject, payload: payment.getPayload(), nick: this.g.nick, type: 'create' }).subscribe((result: any) => {
        // this.api.post('opayment/new', {...payment, payload: payment.toJson(true), nick: this.g.nick, type: 'create'}).subscribe((result: any) => {
        if (result) {
          // console.log('# createNewPaymentRequest()', result);
          if (result.success && result.status === 'ok') {
            this.fss2.update('transactionsGateway', result.success.id || this.fss2.createId, {
              ...result.success,
              gateway: payment.getPartner(),
              type: 'created',
              user: user.id,
              nick: this.g.nick,
              key: paymentID
            }).then(() => {
              payment.dispatchSuccessHook(this.api, result.success)
              obs.next({ ...result.success, key: paymentID })
              obs.complete()
            }).catch(er => {
              obs.error(this.transloco.translate('payment_gateway_request_error_1'))
              obs.complete()
            })
          } else {
            obs.error(this.transloco.translate('payment_gateway_request_error_2'))
            obs.complete()
          }
        }
      }, err => {
        // console.log('# erro', err.message || err);
        obs.error(this.transloco.translate('payment_gateway_request_error_1'))
        obs.complete()
      })
    })
  }

  /**
   * Verifica se há itens que dever ser pagos via gateway de pagamantos
   * @param user
   */
  hasPayables(user: any): boolean {
    return this.listPayables(user).length > 0
  }

  /**
   * Retorna uma lista com os itens que devem ser pagos via gateway de pagamanto
   * @param user
   */
  listPayables(user: any): any[] {
    if (this.g.type === 'shop') return [ ...user.cart ]
    return [ ...user.cart.filter(i => i.id === 'buy-point' || i.payable), ...(user.pointsCart || []) ]
  }

  /**
   * Retorna o total a ser pago em moeda num cart ativo
   * @param user
   * @param cart
   */
  getPaymentValue(user: any = null, cart: any[] = null): number {
    if ((!user || (!user.cart && !user.pointsCart)) && !cart) return 0
    const theCart: any[] = cart || user.cart || []

    if (this.g.type === 'shop') {
      const total = theCart.map(i => (+i.qty * (+i.price || +i.points || 0))).reduce((acc, val) => acc + val, 0)
      const promotions = theCart.filter(i => i.promotionBalance).map(i => (+i.promotionBalance || 0) * ((+i.price || +i.points || 0))).reduce((acc, val) => acc + val, 0)
      return total - promotions
    }
    return theCart.filter(i => i.id === 'buy-point' || i.payable).map(i => (i.qty * (i.price || i.points || 0))).reduce((acc, val) => acc + val, 0) +
      (user.pointsCart || []).map(i => i.qty).reduce((acc, val) => acc + val, 0)
  }

  getPromotionValue(cart: any[]) {
    const theCart = cart || []
    return theCart.filter(i => i.promotionBalance).map(i => (+i.promotionBalance * (+i.price || +i.points || 0))).reduce((acc, val) => acc + val, 0)
  }

  hasPromotions(cart: any[]) {
    return cart.filter(i => i.promotionBalance).length > 0
  }

  /**
   * Faz um cancelamento de order pendente de pagamanto
   * Envia para a API cancelar atravez do firebase.transaction
   * @param orderId
   */
  orderPaymentCancelation(orderId: string): Observable<any> {
    if (!this.fss2) this.fss2 = use<FirestoreService2>(FirestoreService2)
    return this.api.post('payment/paymentCancelation', {
      orderId,
      userId: this.g.user.id,
      email: this.g.user.email,
      collections: {
        users: this.fss2.path('users'),
        transactions: this.fss2.path('transactions')
      }
    })
  }

  /**
   * Devolve o status da transação para cada tipo de gateway de pagamento
   * @param order
   */
  getTransactionStatus(order: any): any {
    if (order.payment && order.payment.partner) {
      switch (order.payment.partner) {
        // case GatewayPartner.STRIPE: return new StripePayment().fromJson(order.payment).getTransactionStatus();
        case GatewayPartner.STRIPE:
          return new PaymentStripe(this).fromJson(order.payment).getTransactionStatus()
        // case GatewayPartner.EASYPAY: return new EasyPayment().fromJson(order.payment).getTransactionStatus();
        case GatewayPartner.EASYPAY:
          return new PaymentEasypay(this).fromJson(order.payment).getTransactionStatus()
        // case GatewayPartner.BRAINTREE: return new BraintreePayment().fromJson(order.payment).getTransactionStatus();
        case GatewayPartner.BRAINTREE:
          return new PaymentBraintree(this).fromJson(order.payment).getTransactionStatus()
        default:
          throw new Error('Gateway not found')
      }
    }
  }

  verifyPaymentStatus(id: any, partner: GatewayPartner): Observable<any> {
    return this.api.post('opayment/verifyPayment?noAuthRequest=1', { id, partner, nick: this.g.nick })
  }

  /**
   * Inicializa a configuração de paymentGateway
   * Verifica se o user existe e então consulta a collection privada com as configurações de pagamento
   * Também carrega a biblioteca externa do partner de pagamento, caso esta exista
   * @param user
   */
  initPaymentGateway(user: any) {
    if (!this.fss2) this.fss2 = use<FirestoreService2>(FirestoreService2)
    // console.log('# initPaymentGateway()');
    if (user !== null && this.g.nick) {
      this.subs.add(this.fss2.get('settings', this.g.nick, { subcol: 'payments', take: 1 }).subscribe(paymentConfig => {
        this.g.set('paymentGateway', paymentConfig)

        if (paymentConfig && paymentConfig.gateways && !this.g.get('paymentScriptLoaded')) {
          const methods = Object.keys(paymentConfig.methods).map(prop => paymentConfig.methods[prop])
          for (const partner in paymentConfig.gateways) {
            if (paymentConfig.gateways[partner] && methods.indexOf(partner) > -1) {
              if (paymentConfig.gateways[partner].useLibrary && paymentConfig.gateways[partner].scripts && paymentConfig.gateways[partner].scripts instanceof Array) {
                for (const scriptUrl of paymentConfig.gateways[partner].scripts) {
                  this.loadSrv.loadScript(scriptUrl).subscribe(() => {
                    this.g.set('paymentScriptLoaded', true)
                    // console.log(`LOADED ${scriptUrl}`);
                  })
                }
              }
            }
          }
        }

      }))
    }
  }

}
