import { catchError, first, map } from 'rxjs/operators'
import { Cry } from './cry.service'
import { EmailService } from './email.service'
import { FirestoreService2 } from './firestore.service2'
import { GlobalService } from './global.service'
import { NgxSpinnerService } from 'ngx-spinner'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { chunkArray, use } from './utils'

let env: any

@Injectable({ providedIn: 'root' })
export class ApiService {
  apiUrl: string
  count: number
  private showConsoles = false

  constructor(
    private http: HttpClient,
    private spin: NgxSpinnerService,
    public g: GlobalService,
  ) {
    env = (window as any).y4wenvs.ENV
    const isDebug = localStorage.getItem('debug')
    this.showConsoles = env.production ? !!isDebug : !env.production && true
  }

  save(dataType, collection, data, key?): Promise<any> {
    const fss: FirestoreService2 = use<FirestoreService2>(FirestoreService2)
    const emailServ: EmailService = use<EmailService>(EmailService)
    this.spin.show().then()
    const emailProgram = emailServ.emailProgram()
    emailProgram.templateId = this.g.program.emails[dataType.toLowerCase()] || ''
    if (dataType === 'Prospects') emailProgram.subject = this.g.program.name + ' | Ativa a tua conta'
    const chunks = chunkArray(data, 100)
    const promises = []

    for (const chunk of chunks) {
      const body = {
        nick: this.g.nick,
        collection: collection,
        key: key,
        program: emailProgram,
        username: this.g.program.username,
        items: chunk,
        path: fss.path(collection)
      }
      promises.push(this.post(`dataImport/create${dataType}`, body, true).pipe(first()).toPromise())
    }

    return Promise.all(promises).then(values => { this.spin.hide(); return values }).catch(er => { this.spin.hide(); return er })
  }

  get(endpoint: string): Observable<any> {
    const url = endpoint.startsWith('http') ? endpoint : `${this.g.apiUrl}/${endpoint}`
    return this.http.get(url)
  }

  post(endpoint: string, body: any, crypt = false): Observable<any> {
    const url = endpoint.startsWith('http') ? endpoint : `${this.g.apiUrl}/${endpoint}`
    const _body = crypt ? Cry.crypt(JSON.stringify(body), true) : body
    return this.http.post(url, _body).pipe(
      catchError(err => {
        let errMessage = `[${err?.statusText || 'GENERIC ERROR'}]: ${err?.message || 'unknow'}`
        if (err.error) {
          if (typeof err.error === 'string') errMessage = `[${err.statusText || 'GENERIC ERROR'}]: ${err.error}`
          else if (err.error.message) errMessage = `[${err.statusText || 'GENERIC ERROR'}]: ${err.error.message}`
        }
        if (this.showConsoles) {
          console.groupCollapsed(`%c[POST] ${url}`, 'color: red')
          console.log('BODY:', JSON.stringify(body))
          console.log('RESULT', errMessage)
          console.groupEnd()
        }
        return of(crypt ? Cry.crypt(JSON.stringify({ success: false, error: errMessage }), true) : err)
      }),
      map(res => {
        if (this.showConsoles) {
          console.groupCollapsed(`%c[POST] ${url}`, 'color: dodgerblue')
          console.log('BODY:', JSON.stringify(body))
          console.log('RESULT', crypt ? Cry.decrypt(res as string, true) : res)
          console.groupEnd()
        }
        return crypt && res ? JSON.parse(Cry.decrypt(res as string, true)) : res
      })
    )
  }

  youtube(count): Observable<any> {
    const q = this.generateRandomLetter(3)
    const API_KEY = 'AIzaSyAMzgkhtgCb2v2ggRY6pEicHhi6GfaNMwQ'
    return this.http.get('https://www.googleapis.com/youtube/v3/search?key=' + API_KEY + '&maxResults=' + count + '&part=snippet&type=video&q=' + q)
  }

  generateRandomLetter(length) {
    const characters = 'abcdefghijklmnopqrstuvwxyz'
    let result = ' '
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  updateSegmentBalance(data): Promise<any> {
    return new Promise<any>(resolve => {
      this.post('transactions/updateSegmentBalance', data, true).pipe(first()).toPromise().then(data => {
        resolve(data)
      })
    })
  }

  copyObjectToProd(object: any, collection: string, subcol: string = null, nick: string = null): Observable<any> {
    if (object.id) {
      const fss: FirestoreService2 = use<FirestoreService2>(FirestoreService2)
      return new Observable<any>(obs => {
        this.post('copyCollection/copyObject', {
          fromProject: env.id,
          toProject: 'yes4eu',
          collection: fss.path(collection, nick || this.g.nick || null, subcol),
          objectId: object.id
        }).subscribe(ok => obs.next(true), err => obs.next(false))
      })
    }
    return of(false)
  }

  copyObjectToPP(object: any, collection: string, subcol: string = null): Observable<any> {
    if (object.id) {
      console.log('###', this.g.apiUrl)
      const fss: FirestoreService2 = use<FirestoreService2>(FirestoreService2)
      return new Observable<any>(obs => {
        this.post('copyCollection/copyObject', {
          fromProject: env.id,
          toProject: 'yes4eupp',
          collection: fss.path(collection, this.g.nick || null, subcol),
          objectId: object.id
        }).subscribe(ok => obs.next(true), err => obs.next(false))
      })
    }
    return of(false)
  }
}
