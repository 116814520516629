/**
 * ENUM Gateways disponíveis no sistema
 */
import {Observable} from "rxjs"

export enum GatewayPartner {
  EASYPAY = 'EASYPAY',
  STRIPE = 'STRIPE',
  REDUNIQ = 'REDUNIQ',
  BRAINTREE = 'BRAINTREE'
}

/**
 * ENUN Métodos de pagamento disponíveis no sistema
 */
export enum PaymentMethod {
  CC = 'cc',
  MB = 'mb',
  MBW = 'mbw'
}

export enum PaymentStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  ERROR = 'ERROR',
  CANCEL = 'CANCEL'
}

/**
 * Interface Base que deve ser implementada, obrigatóriamente, por toda nova classe de
 * gateway de pagamento introduzida no sistema
 */
export interface IPaymentBase {
  getPartner(): GatewayPartner
  getValue(): number
  getPaymentLink(program: any): string
  getPaymentErrorMessage(): string
  getTransactionStatus(): {paymentDate: string, partner: string}
  processResult(): any
  onRedirect(data: any, api: any): Observable<{success: boolean, transaction: any}>
}

export interface IPaymentBase2 {
  // transaction: any;
  // gatewayData: any;
  // userLibrary: boolean;
  // paymentID: string;
  // method: PaymentMethod;
  // status: PaymentStatus;
  // partner: GatewayPartner;

  getMethod(): PaymentMethod
  getValue(): number
  getPartner(): GatewayPartner
  getTransaction(): any
  getStatus(): PaymentStatus
  getGatewayData(): any
  getId(): string
  getPaymentErrorMessage(): string
  useLocalLibrary(): boolean
  isCreditCard(): boolean
  isMBWay(): boolean
  isMultibanco(): boolean
  setTransaction(data: any): void
  setStatus(status: PaymentStatus): void
  fromJson(json: any): any

  //

  getConfig(): any
  getMBReference(): {entity: string, reference: string}
  getPayload(): any
  getPaymentLink(program: any): string
  getTransactionStatus(): {paymentDate: string, partner: string}

  hasFirstPayload(): boolean
  hasMBWPaid(): boolean
  setMBWPhone(phone: string): void
  setResult(data: any): void

  processResult(data: any): any
  createInstance(payment: {value: number, method: PaymentMethod}, user: any, paymentConfig: any): any
  createNewPayment(user: any): Promise<any>
  onRedirect(data: any): Observable<{success: boolean, transaction: any}>
  dispatchSuccessHook(api: any, data: any): void
}

export interface IPayment {
  gateway: GatewayPartner
  currency: 'EUR' | 'BRL'
  paymentID: string // internal transaction ID
  fiscalNumber: string
  phoneNumber: string
  value: number
  method: PaymentMethod // 'mb' | 'cc' | 'mbw' | 'dd' | 'bb';
}
