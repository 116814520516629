import { Observable } from 'rxjs'
import { GatewayPartner, IPaymentBase2, PaymentMethod } from '../payment.interface'
import { PaymentService } from '../payment.service'
import { PaymentBase } from './payment.base'

export class PaymentStripe extends PaymentBase implements IPaymentBase2 {

  private amount: number
  private setup_future_usage: string
  private statement_descriptor: string
  private payment_method_types: string[]
  private _clientSecret: string = null

  constructor(paymentSrv: PaymentService) {
    super()
    this._paymentSrv = paymentSrv
  }

  createInstance(payment: { value: number; method: PaymentMethod }, user: any, paymentConfig: any): PaymentStripe {
    const id = this._paymentSrv.generatePaymentID()
    this.paymentID = id
    this.partner = GatewayPartner.STRIPE
    this.currency = paymentConfig.currency
    this.method = payment.method || PaymentMethod.CC
    this.amount = +payment.value
    this.useLibrary = paymentConfig.useLibrary

    this.payment_method_types = [ 'card' ]
    this.statement_descriptor = `Compra no sistema de prémios YES Marketing`
    this.setup_future_usage = 'off_session'
    return this
  }

  createNewPayment(user: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (!this._clientSecret) {
        this._paymentSrv.requestNewPayment(user, this, this.paymentID).then((payment: IPaymentBase2) => {
          resolve(this.processResult())
        }).catch(err => reject(err))
      } else resolve(this)
    })
  }

  dispatchSuccessHook(api: any, data: any): void { }

  fromJson(json: any): PaymentStripe {
    return super.fromJson(json)
  }

  get clientSecret(): string {
    return this._clientSecret
  }

  get statementDescriptor(): string {
    return this.statement_descriptor
  }

  getConfig(): any {
    return {
      amount: this.amount,
      setup_future_usage: this.setup_future_usage,
      statement_descriptor: this.statement_descriptor,
      payment_method_types: this.payment_method_types,
      clientSecret: this._clientSecret,
      method: this.method,
      partner: this.partner
    }
  }

  getMBReference(): { entity: string, reference: string } {
    return undefined
  }

  getPayload(): any {
    const obj = {
      amount: this.amount.toFixed(2).replace('.', ''),
      currency: this.currency.toLowerCase(),
      setup_future_usage: 'off_session',
      payment_method_types: this.payment_method_types,
      statement_descriptor: 'Custom descriptor',
      metadata: {
        paymentID: this.paymentID,
        integration_check: 'accept_a_payment'
      },
    }
    return JSON.parse(JSON.stringify(obj))
  }

  getPaymentLink(program: any): string {
    if (!program) throw new Error('PROGRAM must be provided')
    else return `https://${program.url}/${program.type}/checkout/order/list`
  }

  getPaymentErrorMessage(): string {
    return this.gatewayData.error && this.gatewayData.error.decline_code ? `payment_${this.gatewayData.error.decline_code}` : 'payment_error_generic_1'
  }

  getTransactionStatus(): { paymentDate: string; partner: string } {
    if (this.transaction && this.transaction.type === 'payment_intent.succeeded') {
      return {
        paymentDate: new Date(this.transaction.created).toISOString(),
        partner: GatewayPartner.STRIPE
      }
    }
  }

  getValue(): number {
    return this.amount
  }

  hasFirstPayload(): boolean {
    return true
  }

  hasMBWPaid(): boolean {
    return false
  }

  onRedirect(data: any): Observable<{ success: boolean; transaction: any }> {
    return undefined
  }

  processResult(data: any = null): any {
    return this
  }

  setMBWPhone(phone: string): void {
    return undefined
  }

  setResult(data: any): void {
    // console.log(data)
    this.gatewayData = data
    if (data.transaction) {
      this.transaction = data.transaction
      delete this.gatewayData.transaction
    }
    if (data.client_secret) this._clientSecret = data.client_secret
  }

}
