import { Router } from '@angular/router'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { BehaviorSubject, Observable, of } from "rxjs"
import { Cry } from './cry.service'
import { defineGlobal } from './firestore.utils'
import { capitalize, use } from './utils'
import { isPlatformBrowser, Location } from '@angular/common'
import { switchMap, tap } from 'rxjs/operators'
import { DeviceDetectorService } from 'ngx-device-detector'

let PROGRAMDEF: any

@Injectable({ providedIn: "root" })
export class GlobalService {
  path
  public _menus = {
    global: ['collections', 'programs', 'langs', 'settings', 'menus', 'versions', 'help', 'terms', 'fixes', 'debug'],
    contents: ['sections', 'posts', 'questionnaires', 'games'],
    rewards: ['dashboard', 'calendar', 'tasks', 'goals', 'questionnaires', 'visits', 'priceList', 'promotionsList', 'catalogList'],
    sales: ['dashboard', 'calendar', 'tasks', 'goals', 'questionnaires', 'visits', 'priceList', 'promotionsList', 'catalogList', 'help', 'terms', 'cart', 'ranking', 'userManual'],
    tables: ['categories', 'products', 'promotions', 'fnac', 'segments', 'prospects', 'users', 'sellers', 'stores', 'cards', 'goalsTemplate', 'rankingsConfig', 'accesses'],
    transactions: ['payments', 'points', 'pointsBought', 'orders', 'purchases', 'purchasesVerification', 'purchaseInvoices', 'batches', 'usersBalances', 'productsStats', 'ranking', 'templates', 'billing', 'cardsReceived', 'transactions-drill-down'],
    events: ['messages'],
    cashbacks: ['requests', 'cbProducts'],
  }
  now = new Date()
  isIos = PROGRAMDEF ? PROGRAMDEF.platform === 'ios' : false

  private observables: any = {}
  private data = {}
  private stratAngular = 0

  constructor(
    public formBuilder: FormBuilder,
    public deviceService: DeviceDetectorService,
    private location: Location,
    private route: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    PROGRAMDEF = (window as any).y4wenvs.PROGRAMDEF
    this.isIos = PROGRAMDEF ? PROGRAMDEF.platform === 'ios' : false
    window['timings'].push({time: new Date().getTime(), name: 'start GlobalService - constructor'})
    console.info(`## ${this.constructor.name}`)
    this.set('isMobile', this.deviceService.isMobile())
    this.set('isDesktop', this.deviceService.isDesktop())
    this.set('isTablet', this.deviceService.isTablet())
    this.set('deviceInfo', this.deviceService.getDeviceInfo())
    this.set('os', this.deviceService.os)
    this.set('innerWidth', window.innerWidth)
    this.set('innerHeight', window.innerHeight)
    this.set('isAdminArea', false)
    this.set('isVideo', false)
    this.set('paymentGateway', null)
    this.set('user', Cry.get('user', true))
    defineGlobal(this)
  }

  set(variable, value, saveLocal = false) {
    if (variable === 'store') Cry.set('store', value, true)
    if (variable === 'storeId') Cry.set('storeId', value, true)
    if (variable === 'user') Cry.set('user', value, true)
    if (saveLocal) Cry.set(variable, value, true)
    this.data[variable] = value
    if (this.observables[variable]) {
      this.observables[variable].next(this.data[variable] || Cry.get(variable, true) || null)
    }
  }

  getAll() { return this.data }

  get(variable) {
    return this.data.hasOwnProperty(variable) ? this.data[variable] : Cry.get(variable, true)
  }
  // get logoYES() { return this.data['logoYES'] }
  get developers() { return ['mrsilva@yesmkt.com', 'waltergandarella@yesmkt.com', 'pedroferreiro@yesmkt.com'] }
  get prevCampaignNick() { return this.data['prevCampaignNick'] }
  get campaignNick() { return this.data['campaignNick'] || this.data['nick'] }
  get campaign() { return this.data['campaign'] }
  get campaigns() { return this.data['campaigns'] }
  get stores() { return this.data['stores'] }
  get mode() { return (<any>document).location.pathname.split('/')[1] }
  get projectId() { return Cry.get('projectId') }
  get apiUrl() {
    // return `https://europe-west3-${this.projectId}.cloudfunctions.net`
    return this.data['apiUrl']
  }
  get displayRatio() { return this.data['displayRatio'] }
  get user() { return this.data['user'] }
  get type() { return this.data['type'] || Cry.get('type') }
  get nick() { return this.data['nick'] || Cry.get('nick') }
  get program() { return this.data['program'] || Cry.get('program', true) }
  get access() { return this.data['access'] }
  get isAdminArea() { return this.data['isAdminArea'] }
  get isVideo() { return this.data['isVideo'] }
  get isMobile() { return this.data['isMobile'] }
  get isDesktop() { return this.data['isDesktop'] }
  get isTablet() { return this.data['isTablet'] }
  // TODO WG verificar se isto ficou a funcionar!
  get isNative() { return Cry.get('native') || false }
  get isIOS() { return this.isIos }
  get isBrowser() { return isPlatformBrowser(this.platformId) }
  get os() { return this.data['deviceInfo'].os }
  get os_version() { return this.data['deviceInfo'].os_version }
  get userAgent() { return this.data['deviceInfo'].userAgent }
  get device() { return this.data['deviceInfo'].device }
  get browser() { return this.data['deviceInfo'].browser }
  get month() { return this.now.toLocaleString('pt-pt', { month: "long" }) }
  get nextMonth() { return this.firstOfNextMonth().toLocaleString('pt-pt', { month: "long" }) }
  get thisWeek() { return this.getWeek(this.now) }
  get thisYearWeek() { return this.now.getFullYear() * 100 + this.getWeek(this.now) }
  get widescreen() { return Math.round((16 / 9) * 10) / 10 }
  get standard() { return Math.round((4 / 3) * 10) / 10 }
  get iPad() { return Math.round((3 / 4) * 10) / 10 }
  get isFullscreen() { return window.innerHeight === window.outerHeight ? true : false }
  get isPortrait() { return window.outerWidth < window.outerHeight ? true : false }
  get isLandscape() { return window.outerWidth > window.outerHeight ? true : false }
  get userRatio() { return Math.round((window.screen.width / window.screen.height) * 10) / 10 }
  get ratio() {
    let ratio
    if (this.userRatio === this.widescreen) {
      ratio = 'widescreen'
    } else if (this.userRatio === this.standard || this.userRatio === this.iPad) {
      ratio = 'standard'
    } else {
      ratio = 'other'
    }
    return ratio
  }

  get$(variable: string): BehaviorSubject<any> {
    if (!this.observables[variable] || this.observables[variable] === undefined) {
      this.observables[variable] = new BehaviorSubject(this.data[variable] || Cry.get(variable, true) || null)
    }
    return this.observables[variable]
  }

  get canWrite() {
    const allowed = this.get('rawMenus').filter(r => this.get('profile').writeables.indexOf(r.id) > -1).map(r => r.route)
    return allowed.filter(url => this.route.url.indexOf(url) > -1).length > 0
  }

  // back() { this.location.back(); }

  clear() {
    return this.data = {}
  }

  delete(variable) {
    return this.data[variable] = null
  }

  routify = (menuItems: string[], type: string = '') => {
    const routes = []
    for (const path of menuItems) {
      if (path) {
        const route = {
          path: path,
          loadChildren: () => import(`${type}/${path}/module`).then(m => m[capitalize(path) + 'Module']),
        }
        routes.push(route)
      }
    }
    return routes
  }

  getWeek(dt) {
    const tdt: any = new Date(dt.valueOf())
    const dayn = (dt.getDay() + 6) % 7
    tdt.setDate(tdt.getDate() - dayn + 3)
    const firstThursday = tdt.valueOf()
    tdt.setMonth(0, 1)
    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7)
    }
    return 1 + Math.ceil((firstThursday - tdt) / 604800000)
  }

  firstOfNextMonth() {
    const d = new Date()
    d.setMonth(d.getMonth() + 1, 1)
    return d
  }

  /**
   * TIMING
   */
  calculateSysTime() {
    // window['timings'].push({time: new Date().getTime(), name: ''});
    const timeToHMS = time => {
      const dt = new Date(time)
      return `${dt.getHours()}:${dt.getMinutes()}:${dt.getSeconds()}.${dt.getMilliseconds()}`
    }
    const times = []
    for (const [i, event] of window['timings'].entries()) {
      if (i === 0) times.push({ name: event.name, time: timeToHMS(event.time), secondsFromStart: '--', secondsFromLastStep: '--' })
      else times.push({ name: event.name, time: timeToHMS(event.time), secondsFromStart: (event.time - window['timings'][0].time) / 1000, secondsFromLastStep: (event.time - window['timings'][i - 1].time) / 1000 })
    }
    console.table(times)
  }

  globalFetchProgram(fss: any, nick: string) {
    const start = new Date()
    window['timings'].push({time: new Date().getTime(), name: 'Global fetching program'})
    fss.get('programs', nick).subscribe((_program: any) => {
      window['timings'].push({time: new Date().getTime(), name: 'Global fetched program ' + ((new Date().getTime() - start.getTime()) / 1000)})
      if (_program && _program.name) {
        this.set('program', _program)
        Cry.set('program', _program, true)
        this.set('type', _program.type)
        Cry.set('type', _program.type)
        this.set('access', _program.access)
        Cry.set('access', _program.access)
        this.set('nick', _program.nick)
        if (_program.campaigns) {
          this.set('campaigns', _program.campaigns)
          this.set('campaign', _program.campaigns[nick])
        }
      }
    })
  }

}
/**
 * Faz a substituição das TAGS dinâmicas dentro dos templates por uma correspondencia em USER ou PARAMS
 * @param user Objecto do utilizador
 * @param str Template a ser substituído
 * @param g GlobalService
 * @param params Objecto co  parâmetros adicionais
 */
 export const replaceStringTags = (user: any, str: string, g: GlobalService, params: any = null) => {
  // lista de TAGS disponíveis para substituição
  // const tags: any[] = [[/{{NAME}}/g, 'displayName'], [/{{FIRST_NAME}}/g, 'firstName'], [/{{EMAIL}}/g, 'email'], [/{{POINTS}}/g, 'balance'], [/{{SEGMENT}}/g, 'segment'], [/{{PROGRAM}}/g, 'program']];
  const tags: any[] = [
    [ /\{\{NAME\}\}/g, 'displayName' ],
    [ /\{\{FIRST_NAME\}\}/g, 'firstName' ],
    [ /\{\{EMAIL\}\}/g, 'email' ],
    [ /\{\{POINTS\}\}/g, 'balance' ],
    [ /\{\{SEGMENT\}\}/g, 'segment' ],
    [ /\{\{PROGRAM\}\}/g, 'program' ],
    [ /\{\{PROGRAM_URL\}\}/g, 'program_url' ],
  ]
  user = {...user, program: g.program.name, program_url: g.program.url}

  // faz o replace das TAGS por seu valores
  for (const tag of tags) str = str.replace(tag[0], user[tag[1]] || '')

  // verifica se há o objecto de parametros estras
  if (params) {
    // faz a substituição dos parametros esxtras encontrados no template
    str = str.replace(/{{PARAMS\.(\w+)}}/g, (match, p1) => params[p1])

    // verifica se há tabelas dinâmicas a serem preenchidas
    if (/\[\[FOR=/g.test(str)) {
      // contabiliza as tabelas a susbtituir
      const tables = str.match(/(\[\[FOR=)(.+)(\]\])(.+)(\[\[\/FOR=\2\]\])/g) || []

      // percorre as tabelas uma-a-uma
      for (const table of tables) {
        // coleta o nome da propriedade que contem o array a usar na tabela
        const prop = table.replace(/(\[\[FOR=)(.+)(\]\])(.+)(\[\[\/FOR=\2\]\])/g, '$2')
        // coleta o template das linhas a substituir
        const templ = table.replace(/(\[\[FOR=)(.+)(\]\])(.+)(\[\[\/FOR=\2\]\])/g, '$4')

        // garante que o array das linhas emxiste no object o de parametros estras
        if (params[prop]) {
          // faz a substituição das linhas
          str = str.replace(table, () => {
            let rows = ''
            // faz a substituição de cada coluna
            for (const item of params[prop]) {
              rows += templ.replace(/{{ITEM\.(\w+)}}/g, (match, p1) => item[p1])
            }
            return rows
          })
        }
      }
    }
  }

  return str
}
export const getFromPreRender = (flag: string, query: Observable<any>): Observable<any> => {
  const g = use<GlobalService>(GlobalService)
  // console.log('# pre-render', flag, window['startRender'].hasOwnProperty(flag), window['startRender'][flag].length);
  return of(g.get(flag) && !g.get(`${flag}-loaded`) ? g.get(flag) : null)
    .pipe(
      switchMap(items => items ? of(items) : query),
      tap(() => g.set(`${flag}-loaded`, true))
    )
}
