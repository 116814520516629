import { Injectable } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { AuthGuard } from './auth.guard'
import { capitalize } from './utils'

@Injectable({ providedIn: 'root' })
export class RoutingService {

  constructor(
    private router: Router,
  ) { console.info(`## ${this.constructor.name}`) }

  buildRoutes(menu) {
    // console.log(menu)
  }

  update(paths) {
    const appRoutes = [...this.router.config]
    const routes = this.routify(paths)
    for (const route of routes) {
      appRoutes.push(route)
    }
    // console.log(appRoutes)
    this.router.resetConfig(appRoutes)
  }

  add(paths, path) {
    const appRoutes = [...this.router.config]
    const routes = this.routify(paths)
    path.children = routes
    appRoutes.push(path)
    // console.log(appRoutes)
    this.router.resetConfig(appRoutes)
  }

  routify = (paths) => {
    const routes = []
    for (const path of paths) {
      if (path) {
        const route = {
          path: path,
          loadChildren: () => import(`app/${path}/${path}.module`).then(m => m[capitalize(path) + 'Module']),
        }
        if (path !== 'login') { route['canActivate'] = [AuthGuard] }
        routes.push(route)
      }
    }
    return routes
  }

  routifyPath = (path) => {
    const route = {
      path: path,
      loadChildren: () => import(`app/${path}/${path}.module`).then(m => m[capitalize(path) + 'Module']),
    }
    if (path !== 'login') { route['canActivate'] = [AuthGuard] }
    return route
  }

  objectToRoute(obj, idName?, valueName?, copy?, flag?): any {
    const array = []
    for (const prop in obj) {
      if (prop) {
        if (flag && prop !== copy && obj[prop]) {
          // console.log('---------------------')
          // console.log('prop: ', prop)
          // console.log('copy: ', copy)
          // console.log('obj[prop]: ', obj[prop])
          // console.log('obj[prop][copy]: ', obj[prop][copy])
          // console.log(this.routifyPath(prop))
          array.push(this.routifyPath(prop))
        }
      }
    }
    return array
  }
}
