import { DOCUMENT, Location } from '@angular/common'
import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AnalyticsService, AuthService, ApiService, Cry, GlobalService, LoadScriptsService, StyleLoader } from '@y4w-core'
import { timeOut, verifyLoggedIn, orientation, sleep } from '@y4w-core'
import { SubSink } from 'subsink'
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subs = new SubSink()
  networkBlock = false
  isLocal: string
  bg: string

  constructor(
    public router: Router,
    public g: GlobalService,
    private styleLoader: StyleLoader,
    private loadSrv: LoadScriptsService,
    private location: Location,
    private analitycs: AnalyticsService,
    private auth: AuthService,
    private api: ApiService,
    @Inject(DOCUMENT) private _document: HTMLDocument,
  ) {
    this.verifyNetwork()
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  async ngOnInit() {
    this.isLocal = Cry.get('isLocal') || false
    this.bg = orientation() === 'landscape' ? this.g.program.images?.desktopBg : this.g.program.images?.mobileBg
    // verificação obrigatoria para apps nativos IOS após 2secs
    timeOut(() => {
      // if (this.g.user && this.g.user.role === 'admin' && this.g.user.debug) alert(`_Admin debug_\nisIOS: ${this.g.isIOS}`);
      if (this.g.isIOS) {
        try {
          // "capacitor-idfa-ios-app-tracking": "^1.0.2",
          // IOSAppTracking.requestPermission().then((res: any) => console.log(res)).catch(e => {
          //   if (this.g.user && this.g.user.role === 'admin' && this.g.user.debug) alert(`_Admin debug_\nIOSAppTracking: ${e.message || e}`);
          // });
          AppTrackingTransparency.getStatus().then(res => console.log(`_Admin debug_\ngetStatus():\n${JSON.stringify(res)}`))
            .catch(e => console.log(`_Admin debug_\ngetStatus:\n${e.message || e}`))
          AppTrackingTransparency.requestPermission().then((res: any) => {
            console.log(`_Admin debug_\nrequestPermission():\n${JSON.stringify(res)}`)
            if (this.g.isBrowser) Cry.setLocal('accepted-gdpr', true)
          })
            .catch(e => console.log(`_Admin debug_\nrequestPermission():\n${e.message || e}`))
        } catch (e) {
          console.log(`_Admin debug_\nIOSAppTracking:\n${e.message || e}`)
        }
      }
    }, 1000)

    // console.log('###', this.g.nick, this.g.program);

    // loadStyle em app.init não estava a funcionar no primeiro load
    if (this.g.isBrowser && Cry.get('isLocal')) this.styleLoader.loadStyle(this.g.nick) //  || Cry.get('native')
    else this.styleLoader.loadStyle(this.g.nick, this.g.program.cssUrl)

    // test for SSR
    if (this.g.isBrowser) {
      window['ngNav'] = path => this.router.navigate([path]) // debug em tablets android
    }


    await verifyLoggedIn(this.g, this.router)

    if (this.g.program.options.segmentsRedirectByType) {
      await sleep(300)
      console.log(this.g.get('user'))
      this.auth.redirectBySegmentType(this.g.user)
    }
    this.loadExternalScripts()

    this.analitycs.trackPageViews()

    // if (this.g.isNative) {
    try {
      import('@capacitor/app').then((CapacitorApp: any) => {
        if (CapacitorApp && CapacitorApp.hasOwnProperty('addListener')) {
          CapacitorApp.addListener('backButton', ({ canGoBack }) => {
            if (!canGoBack) {
              CapacitorApp.exitApp()
            } else {
              this.location.back()
            }
          })
        }
      })
    } catch (e) {
      console.log('CapacitorApp is not installed')
    }
    // }

    this.persistPubLink()

    // oculta o app-shell
    this._document.querySelector('.js-bootstrap')['style'].display = 'none'
  }

  loadExternalScripts() {
    const urls = [
      // 'https://cdnjs.cloudflare.com/ajax/libs/firebase/8.7.1/firebase.min.js',
      // 'https://kit.fontawesome.com/9ee3ec35ba.js',
      // 'https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta3/dist/js/bootstrap.bundle.min.js'
      // 'assets/js/firebase.min.js',
      'assets/js/font-awesome.js',
      'assets/js/bootstrap-v5-beta3.bundle.min.js'
    ]
    for (const url of urls) {
      this.loadSrv.loadScript(url).subscribe(() => null)
    }
  }

  private persistPubLink() {
    const pub = Cry.get('pub')
    if (pub) this.router.navigate([`/pub/${pub}`])
  }

  private verifyNetwork() {
    if (this.g.program && this.g.program.options.ipWhitelist) {
      // verify network block
      this.api.post('open/startapp', this.g.nick, true).toPromise().then(res => {
        console.log('# network', res)
        const parsed = JSON.parse(res.ic)
        if (typeof parsed === 'object') {
          (document as any).querySelector('.js-bootstrap')['style'].display = 'none'
          this.networkBlock = true
        }
      })
    }
  }
}
