import { Injectable } from '@angular/core'
import Dexie from 'dexie'

let env: any

@Injectable({
  providedIn: 'root'
})
export class LocalDbService {

  private indexedDb: any = new Dexie("y4w")

  constructor() {
    console.info(`## ${this.constructor.name}`)
    env = (window as any).y4wenvs.ENV
    this.indexedDb.version(1).stores({
      cache: ``,
    })
  }

  setToCache(resourceId: string, data: any) {
    this.indexedDb.cache.put({ data: JSON.stringify(data) }, resourceId)
      .then(() => !env.production && console.log(`[LOCAL_DB] ${resourceId} CACHED`))
      .catch(e => console.log(e.message))
  }

  async getFromCache(resourceId: string, data: any = null) {
    const cache_ = await this.indexedDb.cache.get(resourceId)
    if (cache_) {
      const cache = JSON.parse(cache_.data)
      if (cache) {
        return data && JSON.stringify(cache) !== JSON.stringify(data) ? data : cache
      }
    }
    return data || null
  }
}
