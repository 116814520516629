import { OrderStatus } from './enums'
import { Product } from './interfaces'

export enum CartType {
  CART = 'cart',
  WHISHLIST = 'wishlist'
}

export enum TransactionType {
  ORDER = 'ORDER',
  MESSAGE = 'MESSAGE',
  POINTS = 'Pontos'
}

export interface Item extends Product {
  qty: number,
  points: number,
  price: number,
  amtPoints: number,
  amtEuros: number,
  // display only
  plus: string,
  minus: string,
  status: string,
  idx: number,
}

export interface ITransaction {
  code: TransactionType,
  deliveryDate?: string,
  deliveryTo: any,
  id?: string,
  historic?: any[],
  nick: string,
  orderDate: string | number,
  orderEuros?: number,
  orderPoints?: number,
  orderQty: number,
  primaryCode: TransactionType,
  program?: any,
  segment?: string,
  status: string,
  transactionId: string,
  uid: string,
  updatedAt: string | number,
  updatedBy: string,
  userCode?: string,
  userEmail: string,
  userName: string,
  xrate?: number,
}

export interface ICartItem {
  cardId?: string,
  cardTemp?: string,
  category?: string,
  deliveryPeriod?: number,
  id?: string,
  nick: string,
  points: number,
  price: number,
  product: Product,
  qty: number,
  status: OrderStatus,
  transactionId: string,
  uniqueCartItemNo: string,
}
