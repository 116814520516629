export const COLUMNS = {
  points: [
    { title: 'User', name: 'userCode', filtering: { filterString: '', placeholder: 'Filtre por userCode' } },
    { title: 'Nome', name: 'displayName', filtering: { filterString: '', placeholder: 'Filtre por displayName' } },
    { title: 'Email', name: 'email', filtering: { filterString: '', placeholder: 'Filtre por email' } },
    { title: 'Pontos', name: 'orderPoints', className: 'p-text-right', summable: true },
    { title: 'Descrição', name: 'description', filtering: { filterString: '', placeholder: 'Filtre por description' } },
  ],
  prospects: [
    // { title: 'Op', name: 'op' },
    // { title: 'userCode', name: 'userCode', filtering: { filterString: '', placeholder: 'Filtre por userCode' } },
    { title: 'name', name: 'name', filtering: { filterString: '', placeholder: 'Filtre por name' } },
    { title: 'email', name: 'email', filtering: { filterString: '', placeholder: 'Filtre por email' } },
    { title: 'segment', name: 'segment', filtering: { filterString: '', placeholder: 'Filtre por segment' } },
    { title: 'role', name: 'role', filtering: { filterString: '', placeholder: 'Filtre por role' } },
  ],
  users: [
    { title: 'Activo', name: 'active' },
    { title: 'userCode', name: 'userCode', filtering: { filterString: '', placeholder: 'Filtre por userCode' } },
    { title: 'displayName', name: 'displayName', filtering: { filterString: '', placeholder: 'Filtre por displayName' } },
    { title: 'email', name: 'email', filtering: { filterString: '', placeholder: 'Filtre por email' } },
    { title: 'oldEmail', name: 'oldEmail', filtering: { filterString: '', placeholder: 'Filtre por oldEmail' } },
    { title: 'segment', name: 'segment', filtering: { filterString: '', placeholder: 'Filtre por segment' } },
    { title: 'role', name: 'role', filtering: { filterString: '', placeholder: 'Filtre por role' } }
  ],
  cards: [
    { title: 'seller', name: 'seller' },
    { title: 'cardId', name: 'cardId', filtering: { filterString: '', placeholder: 'Filtre por nº cartão' } },
    { title: 'displayName', name: 'displayName', filtering: { filterString: '', placeholder: 'Filtre por nome' } },
    { title: 'email', name: 'email', filtering: { filterString: '', placeholder: 'Filtre por email' } },
    { title: 'platform', name: 'platform', filtering: { filterString: '', placeholder: 'Filtre por plataforma' } },
    { title: 'Expire', name: 'expirationDate' },
  ],
  stores: [
    { title: 'Activo', name: 'active' },
    { title: 'Loja', name: 'name', filtering: { filterString: '', placeholder: 'Filtre por loja' }, className: 'store', "sort": "asc" },
    { title: 'Tipo', name: 'storeType', filtering: { filterString: '', placeholder: 'Filtre por tipo' }, className: 'store' },
    { title: 'User', name: 'user', filtering: { filterString: '', placeholder: 'Filtre por tipo' }, className: 'store' },
    { title: 'Nº loja', name: 'id', filtering: { filterString: '', placeholder: 'Filtre por cliente' }, className: 'store' },
  ],
  'sales-stores': [
    { title: 'Activo', name: 'active' },
    { title: 'Loja', name: 'name', filtering: { filterString: '', placeholder: 'Filtre por loja' }, className: 'store', "sort": "asc" },
    { title: 'Tipo', name: 'storeType', filtering: { filterString: '', placeholder: 'Filtre por tipo' }, className: 'store' },
    { title: 'Cadeia', name: 'chain', filtering: { filterString: '', placeholder: 'Filtre por cadeia' }, className: 'store' },
    { title: 'User', name: 'user', filtering: { filterString: '', placeholder: 'Filtre por tipo' }, className: 'store' },
    { title: 'Rank', name: 'rank', filtering: { filterString: '', placeholder: 'Filtre por rank' }, className: 'store' },
    { title: 'Template', name: 'templateType', filtering: { filterString: '', placeholder: 'Filtre por template' }, className: 'store' },
    { title: 'Dt implementação', name: 'completed', className: 'store' },
    { title: 'Nº loja', name: 'id', filtering: { filterString: '', placeholder: 'Filtre por cliente' }, className: 'store' },
  ],
  segments: [
    { title: 'Nome', name: 'name' },
    { title: 'Ativo', name: 'active' },
    { title: 'Rank', name: 'hasRank' },
  ],
  categories: [
    { title: 'Nome', name: 'name', filtering: { filterString: '', placeholder: 'Filtre por Nome' } },
    { title: 'Pai', name: 'parent', filtering: { filterString: '', placeholder: 'Filtre por Pai' } },
    { title: 'Activo', name: 'active' },
    { title: 'Ordem', name: 'order' }
  ],
  routes: [
    { title: 'Nome', name: 'name', filtering: { filterString: '', placeholder: 'Filtre por Menu' } },
    { title: 'Pai', name: 'parent', filtering: { filterString: '', placeholder: 'Filtre por Pai' } },
    { title: 'Perfil de acesso', name: 'role', filtering: { filterString: '', placeholder: 'Filtre por Perfil de acesso' } },
    { title: 'Activo', name: 'active' },
    { title: 'Ordem', name: 'order' }
  ],
  cbProducts: [
    { title: 'Nome', name: 'name', filtering: { filterString: '', placeholder: 'Filtre por Nome' } },
    { title: 'Activo', name: 'active' }
  ],
  'rewards-stores': [
    { title: 'Nome', name: 'name', filtering: { filterString: '', placeholder: 'Filtre por Nome' } },
    { title: 'Activo', name: 'active' }
  ],
  promotions: [
    { title: 'category', name: 'category' },
    { title: 'startWeek', name: 'startWeek' },
    { title: 'endWeek', name: 'endWeek' },
    { title: 'no', name: 'no' },
    { title: 'sellerCode', name: 'sellerCode' },
    { title: 'title', name: 'title' },
  ],
  promotionalPacks: [
    { title: 'promotionId', name: 'promotionId' }
  ],
  products: [
    { title: 'sellerCode', name: 'sellerCode' },
    { title: 'category', name: 'category' },
    { title: 'name', name: 'name' },
    { title: 'validity', name: 'validity' },
    { title: 'variant', name: 'variant' },
    { title: 'obs', name: 'obs' },
  ],
}

export const DATATYPES = {
  users: {
    path: 'tables',
    collection: 'users',
    required: { displayName: 'string', email: 'string', segment: 'string', active: 'boolean' },
    requiredFields: ['displayName', 'email', 'segment', 'active', 'role'],
    uniq: ['email']
  },
  prospects: {
    path: 'tables',
    collection: 'prospects',
    key: 'email',
    required: { name: 'string', email: 'string', segment: 'string', role: 'string' },
    requiredFields: ['name', 'segment', 'email', 'role'],
    uniq: ['email']
  },
  promotions: {
    path: 'tables',
    collection: 'products',
    requiredFields: [],
    uniq: ['promotionId']
  },
  promotionalPacks: {
    path: 'tables',
    collection: 'products',
    key: 'promotionId',
    requiredFields: ['promotionId', 'packId', 'sellerCode', 'priceTable_Continente', 'priceTable_Madeira', 'unitsPack'],
    uniq: ['promotionId', 'packId', 'sellerCode']
  },
  categories: {
    path: 'tables',
    collection: 'categories',
    requiredFields: ['name', 'active', 'parent', 'order', 'role'],
    uniq: ['name', 'parent']
  },
  routes: {
    path: 'tables',
    collection: 'routes',
    requiredFields: ['name', 'active', 'parent', 'order', 'role'],
    uniq: ['name', 'parent']
  },
  points: {
    path: 'transactions',
    collection: 'transactions',
    key: 'id',
    required: { email: 'string', segment: 'string', orderPoints: 'number' },
    requiredFields: ['email', 'segment', 'description', 'orderPoints'],
    defaults: { code: 'Pontos atribuidos', primaryCode: 'Pontos' },
    uniq: ['email', 'description']
  },
  segments: {
    path: 'tables',
    collection: 'segments',
    key: 'id',
    required: { name: 'string' },
    requiredFields: ['name'],
    uniq: ['name']
  },
  cards: {
    path: 'tables',
    collection: 'cards',
    required: { cardId: 'string', displayName: 'string' },
    requiredFields: ['cardId', 'displayName', 'email', 'platform'],
    uniq: ['cardId']
  },
  'sales-stores': {
    path: 'tables',
    collection: 'stores',
    required: {
      name: 'string', active: 'boolean', user: 'string', rank: 'number', templateType: 'string', storeType: 'string',
      completedDate: 'date', id: 'string', chain: 'string', goals: 'object'
    },
    requiredFields: ['name', 'active', 'user', 'rank', 'templateType', 'storeType', 'completedDate', 'id', 'chain', 'goals'],
    uniq: ['id']
  },
  cbProducts: {
    path: 'cashbacks',
    collection: 'cbProducts',
    required: { name: 'string', active: 'boolean' },
    requiredFields: ['name', 'active'],
    uniq: ['productId']
  },
}
export const CALENDAR: Object = {
  perfectstore: {
    '50110-C-Promo':
      { name: 'PROMO', id: '50110-C-Promo', eventType: 'Promo', goalType: 'C-Promo', period: 'Jan-Fev', start: '2020-01-01', order: 10, end: '2020-02-29', },
    '50120-C-1-Evento':
      { name: 'BFY', id: '50120-C-1-Evento', eventType: '1-Evento', goalType: 'C-1-Evento', period: 'Jan-Fev', start: '2020-01-01', order: 20, end: '2020-02-29', },
    '50130-C-2-Evento':
      { name: 'DORITOS', id: '50130-C-2-Evento', eventType: '2-Evento', goalType: 'C-2-Evento', period: 'Jan-Fev', start: '2020-01-01', order: 30, end: '2020-02-29', },
    '50310-C-Promo':
      { name: 'PROMO', id: '50310-C-Promo', eventType: 'Promo', goalType: 'C-Promo', period: 'Mar-Abr', start: '2020-03-01', order: 10, end: '2020-04-30', },
    '50320-C-1-Evento':
      { name: 'MIDLIFERS', id: '50320-C-1-Evento', eventType: '1-Evento', goalType: 'C-1-Evento', period: 'Mar-Abr', start: '2020-03-01', order: 20, end: '2020-04-30', },
    '50330-C-2-Evento':
      { name: 'UCL', id: '50330-C-2-Evento', eventType: '2-Evento', goalType: 'C-2-Evento', period: 'Mar-Abr', start: '2020-03-01', order: 30, end: '2020-04-30', },
    '50610-C-Promo':
      { name: 'PROMO', id: '50610-C-Promo', eventType: 'Promo', goalType: 'C-Promo', period: 'Jun-Jul', start: '2020-06-01', order: 10, end: '2020-07-31', },
    '50620-C-1-Evento':
      { name: 'LAYS', id: '50620-C-1-Evento', eventType: '1-Evento', goalType: 'C-1-Evento', period: 'Jun-Jul', start: '2020-06-01', order: 20, end: '2020-07-31', },
    '50630-C-2-Evento':
      { name: 'SUNBITES', id: '50630-C-2-Evento', eventType: '2-Evento', goalType: 'C-2-Evento', period: 'Jun-Jul', start: '2020-06-01', order: 30, end: '2020-07-31', },
    '50810-C-Promo':
      { name: 'PROMO', id: '50810-C-Promo', eventType: 'Promo', goalType: 'C-Promo', period: 'Ago-Set', start: '2020-08-01', order: 10, end: '2020-09-30', },
    '50820-C-1-Evento':
      { name: 'DORITOS', id: '50820-C-1-Evento', eventType: '1-Evento', goalType: 'C-1-Evento', period: 'Ago-Set', start: '2020-08-01', order: 20, end: '2020-09-30', },
    '50830-C-2-Evento':
      { name: 'R STRONG', id: '50830-C-2-Evento', eventType: '2-Evento', goalType: 'C-2-Evento', period: 'Ago-Set', start: '2020-08-01', order: 30, end: '2020-09-30', },
    '50910-C-Promo':
      { name: 'PROMO', id: '50910-C-Promo', eventType: 'Promo', goalType: 'C-Promo', period: 'Set-Out', start: '2020-10-01', order: 10, end: '2020-10-31', },
    '50920-C-1-Evento':
      { name: 'BFY', id: '50920-C-1-Evento', eventType: '1-Evento', goalType: 'C-1-Evento', period: 'Set-Out', start: '2020-10-01', order: 20, end: '2020-10-31', },
    '51110-C-Promo':
      { name: 'PROMO', id: '51110-C-Promo', eventType: 'Promo', goalType: 'C-Promo', period: 'Nov-Dez', start: '2020-11-01', order: 10, end: '2020-12-31', },
    '51120-C-1-Evento':
      { name: 'MIDLIFERS', id: '51120-C-1-Evento', eventType: '1-Evento', goalType: 'C-1-Evento', period: 'Nov-Dez', start: '2020-11-01', order: 20, end: '2020-12-31', },
    '51130-C-2-Evento':
      { name: 'BFY', id: '51130-C-2-Evento', eventType: '2-Evento', goalType: 'C-2-Evento', period: 'Nov-Dez', start: '2020-11-01', order: 30, end: '2020-12-31', },
    '80610-T-Topo-ECR':
      { name: 'Topo-ECR', id: '80610-T-Topo-ECR', eventType: 'Topo-ECR', goalType: 'T-Topo-ECR', period: 'Jun-Jul', start: '2020-06-01', order: 10, end: '2020-07-31', status: 'mandatory' },
    '80620-T-1-Evento':
      { name: '1-Evento', id: '80620-T-1-Evento', eventType: '1-Evento', goalType: 'T-1-Evento', period: 'Jun-Jul', start: '2020-06-01', order: 20, end: '2020-07-31', status: 'mandatory' },
    '80630-T-2-Evento':
      { name: '2-Evento', id: '80630-T-2-Evento', eventType: '2-Evento', goalType: 'T-2-Evento', period: 'Jun-Jul', start: '2020-06-01', order: 30, end: '2020-07-31', status: 'optional' },
    '80810-T-Topo-ECR':
      { name: 'Topo-ECR', id: '80610-T-Topo-ECR', eventType: 'Topo-ECR', goalType: 'T-Topo-ECR', period: 'Ago-Set', start: '2020-08-01', order: 10, end: '2020-09-30', status: 'mandatory' },
    '80820-T-1-Evento':
      { name: '1-Evento', id: '80620-T-1-Evento', eventType: '1-Evento', goalType: 'T-1-Evento', period: 'Ago-Set', start: '2020-08-01', order: 20, end: '2020-09-30', status: 'mandatory' },
    '80830-T-2-Evento':
      { name: '2-Evento', id: '80630-T-2-Evento', eventType: '2-Evento', goalType: 'T-2-Evento', period: 'Ago-Set', start: '2020-08-01', order: 30, end: '2020-09-30', status: 'optional' },
  }
}
export const STORETYPES: Object = [
  { name: 'key' },
  { name: 'mainstream-a' },
  { name: 'mainstream-b' },
  { name: 'mainstream-c' },
  { name: 'small' },
  { name: 'star' },
  { name: 'top-jumbo' }
]
export const TEMPLATES: Object = {
  perfectstore: {
    'key-7-goals': {
      goals: { permanent: 7, calendar: 3 },
      points: { permanent: 15, calendar: 10 },
      permanent: {
        '10010-P-Planograma': { status: 'mandatory', order: 10010 }, '10020-P-Doritos': { status: 'mandatory', order: 10020 }, '10030-P-T-Away': { status: 'mandatory', order: 10030 }, '10040-P-BFY': { status: 'mandatory', order: 10040 }, '10050-P-Salty': { status: 'mandatory', order: 10050 }, '10060-P-Bacalhau': { status: 'mandatory', order: 10060 }, '10070-P-Nuts': { status: 'mandatory', order: 10070 }, '10080-P-Quaker': { status: 'optional', order: 10080 }, '10090-P-Checkout': { status: 'optional', order: 10090 },
      },
      calendar: { 'C-Promo': { status: 'mandatory' }, 'C-1-Evento': { status: 'mandatory' }, 'C-2-Evento': { status: 'mandatory' }, }
    },
    'mainstream-a-5-goals': {
      goals: { permanent: 5, calendar: 3 },
      points: { permanent: 6, calendar: 4 },
      permanent: {
        '10010-P-Planograma': { status: 'mandatory', order: 10010 }, '10020-P-Doritos': { status: 'mandatory', order: 10020 }, '10030-P-T-Away': { status: 'mandatory', order: 10030 }, '10040-P-BFY': { status: 'mandatory', order: 10040 }, '10050-P-Salty': { status: 'mandatory', order: 10050 }, '10060-P-Bacalhau': { status: 'optional', order: 10060 }, '10070-P-Nuts': { status: 'optional', order: 10070 }, '10080-P-Quaker': { status: 'optional', order: 10080 }, '10090-P-Checkout': { status: 'optional', order: 10090 },
      },
      calendar: { 'C-Promo': { status: 'mandatory' }, 'C-1-Evento': { status: 'mandatory' }, 'C-2-Evento': { status: 'mandatory' }, }
    },
    'mainstream-a-5-goals-nuts': {
      goals: { permanent: 5, calendar: 3 },
      points: { permanent: 6, calendar: 4 },
      permanent: {
        '10010-P-Planograma': { status: 'mandatory', order: 10010 }, '10020-P-Doritos': { status: 'mandatory', order: 10020 }, '10030-P-T-Away': { status: 'mandatory', order: 10030 }, '10040-P-BFY': { status: 'optional', order: 10040 }, '10050-P-Salty': { status: 'mandatory', order: 10050 }, '10060-P-Bacalhau': { status: 'optional', order: 10060 }, '10070-P-Nuts': { status: 'mandatory', order: 10070 }, '10080-P-Quaker': { status: 'optional', order: 10080 }, '10090-P-Checkout': { status: 'optional', order: 10090 },
      },
      calendar: { 'C-Promo': { status: 'mandatory' }, 'C-1-Evento': { status: 'mandatory' }, 'C-2-Evento': { status: 'mandatory' }, }
    },
    'mainstream-b-4-goals': {
      goals: { permanent: 4, calendar: 3 },
      points: { permanent: 5, calendar: 3 },
      permanent: {
        '10010-P-Planograma': { status: 'mandatory', order: 10010 }, '10020-P-Doritos': { status: 'mandatory', order: 10020 }, '10030-P-T-Away': { status: 'mandatory', order: 10030 }, '10040-P-BFY': { status: 'mandatory', order: 10040 }, '10050-P-Salty': { status: 'optional', order: 10050 }, '10060-P-Bacalhau': { status: 'optional', order: 10060 }, '10070-P-Nuts': { status: 'optional', order: 10070 }, '10080-P-Quaker': { status: 'optional', order: 10080 }, '10090-P-Checkout': { status: 'optional', order: 10090 },
      },
      calendar: { 'C-Promo': { status: 'mandatory' }, 'C-1-Evento': { status: 'mandatory' }, 'C-2-Evento': { status: 'mandatory' }, }
    },
    'mainstream-c-3-goals': {
      goals: { permanent: 3, calendar: 3 },
      points: { permanent: 3, calendar: 2 },
      permanent: {
        '10010-P-Planograma': { status: 'mandatory', order: 10010 }, '10020-P-Doritos': { status: 'mandatory', order: 10020 }, '10030-P-T-Away': { status: 'mandatory', order: 10030 }, '10040-P-BFY': { status: 'optional', order: 10040 }, '10050-P-Salty': { status: 'optional', order: 10050 }, '10060-P-Bacalhau': { status: 'optional', order: 10060 }, '10070-P-Nuts': { status: 'optional', order: 10070 }, '10080-P-Quaker': { status: 'optional', order: 10080 }, '10090-P-Checkout': { status: 'optional', order: 10090 },
      },
      calendar: { 'C-Promo': { status: 'mandatory' }, 'C-1-Evento': { status: 'mandatory' }, 'C-2-Evento': { status: 'mandatory' }, }
    },
    'small-1-goal': {
      goals: { permanent: 1, calendar: 3 },
      points: { permanent: 3, calendar: 2 },
      permanent: {
        '10010-P-Planograma': { status: 'mandatory', order: 10010 }, '10020-P-Doritos': { status: 'optional', order: 10020 }, '10030-P-T-Away': { status: 'optional', order: 10030 }, '10040-P-BFY': { status: 'optional', order: 10040 }, '10050-P-Salty': { status: 'optional', order: 10050 }, '10060-P-Bacalhau': { status: 'optional', order: 10060 }, '10070-P-Nuts': { status: 'optional', order: 10070 }, '10080-P-Quaker': { status: 'optional', order: 10080 }, '10090-P-Checkout': { status: 'optional', order: 10090 },
      },
      calendar: { 'C-Promo': { status: 'mandatory' }, 'C-1-Evento': { status: 'mandatory' }, 'C-2-Evento': { status: 'mandatory' }, }
    },
    'star-9-goals': {
      goals: { permanent: 9, calendar: 3 },
      points: { permanent: 30, calendar: 20 },
      permanent: {
        '10010-P-Planograma': { status: 'mandatory', order: 10010 }, '10020-P-Doritos': { status: 'mandatory', order: 10020 }, '10030-P-T-Away': { status: 'mandatory', order: 10030 }, '10040-P-BFY': { status: 'mandatory', order: 10040 }, '10050-P-Salty': { status: 'mandatory', order: 10050 }, '10060-P-Bacalhau': { status: 'mandatory', order: 10060 }, '10070-P-Nuts': { status: 'mandatory', order: 10070 }, '10080-P-Quaker': { status: 'mandatory', order: 10080 }, '10090-P-Checkout': { status: 'mandatory', order: 10090 },
      },
      calendar: { 'C-Promo': { status: 'mandatory' }, 'C-1-Evento': { status: 'mandatory' }, 'C-2-Evento': { status: 'mandatory' }, }
    },
    'top-jumbo': {
      goals: { permanent: 0, calendar: 2 },
      points: { permanent: 0, calendar: 10 },
      calendar: { 'T-Topo-ECR': { status: 'mandatory' }, 'T-1-Evento': { status: 'mandatory' }, 'T-2-Evento': { status: 'optional' }, }
    },
  },
  lojaveraoitm: {
    lojaveraoitm: {
      permanent: {
        '10010-P-Ilha-3P': { status: 'optional', order: 10010, points: 9 },
        '10020-P-Ilha-2P': { status: 'optional', order: 10020, points: 5 },
        '10030-P-Ilha-1P': { status: 'optional', order: 10030, points: 4 },
        '10035-P-Ilha-MeiaP': { status: 'optional', order: 10035, points: 2 },
        '10040-P-Topo': { status: 'optional', order: 10040, points: 3 },
        '10050-P-Topo-Meio': { status: 'optional', order: 10050, points: 1.5 },
        '10060-P-Topo-Terço': { status: 'optional', order: 10060, points: 1 },
        '10070-P-Exp-Grande': { status: 'optional', order: 10070, points: 1.25 },
        '10080-P-Exp-Peq-Checkout': { status: 'optional', order: 10080, points: 0.5 },
        '10090-P-Exp-Pilhas-Box': { status: 'optional', order: 10090, points: 1 },
        '10100-P-Outros-Materiais': { status: 'optional', order: 100100, points: 0.1 }
      }
    }
  }
}

export const ZONES = [
  { id: 1, name: '1a posição' },
  { id: 2, name: 'Lateral Topo' },
  { id: 3, name: 'Corredor Central' },
  { id: 4, name: 'Promo' },
  { id: 5, name: 'Iogurtes' },
  { id: 6, name: 'Bio' },
  { id: 7, name: 'Frutas e Legumes' },
  { id: 8, name: 'Leite' },
  { id: 9, name: 'Take-Away' },
  { id: 10, name: 'Peixaria' },
  { id: 11, name: 'Charcutaria' },
  { id: 12, name: 'Refrigerantes' },
  { id: 13, name: 'Cervejas' },
  { id: 14, name: 'Águas' },
  { id: 15, name: 'Bebidas' },
  { id: 16, name: 'Vinhos' },
  { id: 17, name: 'Salty' },
  { id: 18, name: 'Checkouts' },
]
export const DISPLAYS = [
  { id: 1, name: 'Ilha +3P' },
  { id: 2, name: 'Ilha 2P' },
  { id: 3, name: 'Ilha 1P' },
  { id: 4, name: 'Ilha 1/2P' },
  { id: 5, name: 'Topo' },
  { id: 6, name: 'Topo 1/2' },
  { id: 7, name: 'Topo 1/3' },
  { id: 8, name: 'Exp Grande' },
  { id: 9, name: 'Push' },
  { id: 10, name: 'Exp Pequeno' },
  { id: 11, name: 'Parasitos' },
  { id: 12, name: 'Linear' },
]
export const CATEGORIES = [
  { id: 1, name: 'Alvalle' },
  { id: 2, name: 'Sumos' },
  { id: 3, name: 'Better For You' },
  { id: 4, name: 'Families' },
  { id: 5, name: 'Premium' },
  { id: 6, name: 'Quaker' },
  { id: 7, name: 'Frutos Secos' },
  { id: 8, name: 'Outros Foods' },
]
export const AVGS = {
  '90001347': .4,
  '90016742': 5.4,
  '90024315': 7.8,
  '90025447': 4.5,
  '90088486': 2.5,
  '90097035': 5.8,
  '90097586': 8.0,
  '90098287': 14.0,
  '90100299': 2.8,
  '90100302': 9.6,
  '90100891': 6.4,
  '90100913': 3.2,
  '90101025': 7.8,
  '90101492': 6.6,
  '90101503': 3.5,
  '90124016': 5.5,
  '90128678': 5.9,
  '90135808': 1.7,
  '90138015': 2.2,
  '90140784': 4.6,
  '90141834': 12.7,
  '90142807': 2.6,
  '90148538': 3.6,
  '90150262': .4,
  '90153165': 3.7,
  '90153166': 3.0,
  '90156124': 2.4,
  '90156125': 5.0,
  '90156126': 4.5,
  '90160022': 5.6,
  '90161900': 6.7,
  '90162731': 4.1,
  '90163135': 5.5,
  '90163304': 6.6,
  '90163504': 15.9,
  '90163506': 6.4,
  '90163987': 2.9,
  '90165345': 7.2,
  '90165726': 2.5,
  '90168265': 3.2,
  '90171270': 3.1,
  '90171439': 1.7,
  '90173349': 6.5,
  '90173352': 1.1,
  '90179492': 3.1,
  '90179794': 3.1,
  '90179952': 3.5,
  '90180213': .5,
  '90180215': 4.2,
  '90180216': 2.0,
  '90180217': 3.5,
  '90180218': 7.6,
  '90180219': 5.9,
  '90180221': 4.3,
  '90180410': 3.9,
  '90180469': 5.5,
  '90180471': 3.3,
  '90180472': 6.9,
  '90180475': 5.8,
  '90180476': 2.6,
  '90180623': 2.2,
  '90180624': 1.1,
  '90180625': 8.1,
  '90180626': 3.0,
  '90180724': 3.3,
  '90180728': 4.8,
  '90180932': 5.5,
  '90180933': 1.8,
  '90181000': 4.1,
  '90181002': 5.7,
  '90181004': 2.3,
  '90181308': 3.3,
  '90181309': 5.6,
  '90181310': 4.2,
  '90181519': 6.9,
  '90181651': 11.7,
  '90181703': 6.8,
  '90182063': 5.0,
  '90182064': 13.7,
  '90182498': 1.5,
  '90182499': 3.9,
  '90182684': 7.1,
  '90182685': .6,
  '90183140': 6.0,
  '90183141': 8.5,
  '90183144': 2.0,
  '90183145': 11.9,
  '90183146': 2.7,
  '90183504': 7.9,
  '90183970': 14.9,
  '90183971': 4.2,
  '90184234': .3,
  '90184657': 5.2,
  '90184799': 7.5,
  '90185631': 1.9,
  '90186362': 1.8,
  '90186859': 3.4,
  '90187164': 4.5,
  '90187165': 2.8,
  '90187659': 4.3,
  '90188614': .4,
  '90188615': 2.8,
  '90188616': 12.6,
  '90188835': 12.4,
  '90190390': 4.2,
  '90190391': 6.9,
  '90193185': 10.5,
  '90193644': .7,
  '90199776': 2.1,
  '90211756': 4.1,
  '90214296': 9.6,
  '90214829': 1.1,
  '90215120': 1.6,
  '90220504': .3,
  '90220743': 11.3,
  '90227540': .6,
  '90228500': 8.1,
  '90229846': 10.5,
  '90231033': 3.0,
  '90231034': .9,
  '90232576': 9.3,
  '90240081': .9,
  '90240124': 8.9,
  '90240125': 2.5,
  '90240126': 5.7,
  '90251652': 6.3,
  '90251653': 9.3,
  '90254088': 5.5,
  '90255263': .2,
  '90256391': 5.7,
  '90256784': 1.0,
  '90257001': 3.5,
  '90258132': 5.0,
  '90258785': 14.8,
  '90259673': 4.7,
  '90267479': 2.5,
  '90268594': 5.6,
  '90271634': 2.0,
  '90274268': 5.2,
  '90275747': 2.0,
  '90275748': 1.7,
  '90279566': 4.8,
  '90281693': 6.9,
  '90281695': 4.0,
  '90281696': 4.7,
  '90281697': 7.5,
  '90281699': 1.9,
  '90284197': 4.7,
  '90286273': 2.3,
  '90288952': 5.5,
  '90292111': 2.4,
  '90292210': 7.3,
  '90292288': .2,
  '90292328': 4.8,
  '90292426': 2.4,
  '90293292': 5.9,
  '90293322': 5.9,
  '90293323': 1.4,
  '90293470': 2.0,
  '90294352': 6.4,
  '90294878': 1.0,
  '90295585': 2.2,
  '90295846': 3.9,
  '90296435': 3.2,
  '90296554': 1.1,
  '90296559': .4,
  '90296634': .8,
  '90296931': .0,
  '90297472': .0,
  '90308306': 2.6,
  '90308718': 5.2,
  '90312194': 4.2,
  '90318805': 5.4,
  '90339134': 2.2,
  '90339135': 1.1,
  '90339136': 2.9,
  '90339137': 4.8,
  '90339138': 2.3,
  '90339139': 3.8,
  '90339140': 4.1,
  '90339141': .7,
  '90339142': .1,
  '90339143': 1.9,
  '90339144': 1.0,
  '90339145': 5.1,
  '90339146': 5.2,
  '90339147': 4.5,
  '90339148': 1.0,
  '90339149': 1.4,
  '90339150': .0,
  '90339151': 2.7,
  '90339152': 5.6,
  '90339153': 2.0,
  '90339154': 4.3,
  '90339155': 6.0,
  '90339156': 3.0,
  '90339157': 5.2,
  '90339158': 1.5,
  '90339159': 2.5,
  '90339160': 5.6,
  '90339161': 3.7,
  '90339162': 3.1,
  '90339163': 2.2,
  '90339180': 2.3,
  '90339181': 4.1,
  '90341486': 9.8,
  '90341487': 7.5,
  '90341488': .0,
  '90341489': 3.9,
  '90341490': 1.6,
  '90341491': .3,
  '90341492': 4.1,
  '90341493': 3.3,
  '90341494': 5.1,
  '90341495': 3.4,
  '90341496': 2.9,
  '90341497': 7.8,
  '90341498': 3.4,
  '90341499': 8.6,
  '90341500': 3.0,
  '90341501': 6.7,
  '90353459': .7,
  '90353461': 16.6,
  '90353462': .8,
  '90353463': 8.2,
  '90353464': 7.0,
  '90353465': 7.3,
  '90354769': 3.4,
  '90355893': 6.7,
  '90355894': 8.0,
  '90356064': 3.3,
  '90356065': 3.1,
  '90362182': 4.3,
  '90364233': 2.1,
  '90367315': 1.8,
  '90368029': 8.5,
  '90368030': 8.0,
  '90368032': 1.5,
  '90368034': 1.4,
  '90368035': 3.1,
  '90368036': 1.1,
  '90368505': 11.5,
  '90368506': 3.6,
  '90369559': 3.0,
  '90369561': 10.0,
  '90369562': 5.8,
  '90369564': 1.8,
  '90369565': 9.8,
  '90369566': 5.8,
  '90369567': 5.8,
  '90369568': 7.2,
  '90369657': 3.3,

}
