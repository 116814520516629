import { Injectable, OnDestroy } from '@angular/core'
import orderBy from 'lodash-es/orderBy'
import { combineLatest, of } from 'rxjs'
import { Observable } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'
import { SubSink } from 'subsink'
import { ApiService } from './api.service'
import { FirestoreService2 } from './firestore.service2'
import { GlobalService } from './global.service'
import { use } from './utils'

@Injectable({ providedIn: 'root' })

export class RankingService implements OnDestroy {

  private subs = new SubSink()
  private fss: FirestoreService2

  constructor(
    public g: GlobalService,
  ) { console.info(`## ${this.constructor.name}`) }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  getRanking(view = null, challenge = null) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    return new Promise(async (resolve, reject) => {
      if (view === 'segment') {
        this.subs.add(
          this.fss.list('segments', { where: [['active', '==', true]], api: true }).subscribe(segments => {
            if (challenge) {
              segments.map(e => e.ranking && e.ranking.filter(o => o.challenge === challenge ? e[challenge] = o.credits : null))
              segments = segments.sort((a, b) => a[challenge] === b[challenge] ? 0 : a[challenge] < b[challenge] ? -1 : 1).reverse().map((row, i) => ({ ...row, rank: i + 1 }))
              const ranking = segments.filter(o => o.id === this.g.user.segment.id)
              if (ranking && ranking[0]) {
                this.g.user[challenge] = { rank: ranking[0].rank, credits: ranking[0].credits }
                resolve({ rank: ranking[0].rank, credits: ranking[0].credits })
              }
            } else {
              segments.map(e => {
                e.credits = 0
                e.ranking && e.ranking.filter(o => e.credits += o.credits)
              })
              segments = segments.sort((a, b) => a['credits'] === b['credits'] ? 0 : a['credits'] < b['credits'] ? -1 : 1).reverse().map((row, i) => ({ ...row, rank: i + 1 }))
              const ranking = segments.filter(o => o.id === this.g.user.segment.id)
              if (ranking && ranking[0]) {
                this.g.user.rankingSegment = { rank: ranking[0].rank, credits: ranking[0].credits }
                resolve({ rank: ranking[0].rank, credits: ranking[0].credits })
              }
            }
          })
        )
      } else {
        // TODO PF passar o ranking para as function utilizando o action
        const api: ApiService = use<ApiService>(ApiService)
        let users = await api.post(`db/action`, { action: 'list', nick: this.g.nick, collection: 'users', api: true }, true).toPromise()
        users = users.sort((a, b) => a['credits'] === b['credits'] ? 0 : a['credits'] < b['credits'] ? -1 : 1).reverse().map((row, i) => ({ ...row, rank: i + 1 }))
        const ranking = users
        if (ranking && ranking[0]) {
          this.g.user.ranking = { rank: ranking[0].rank, credits: ranking[0].credits }
          resolve({ rank: ranking[0].rank, credits: ranking[0].credits })
        }
      }
    })

  }

  /**
   * Cria um ranking por segmento
   * Se challengeId for fornacido, faz um ranking beseado num desafio específico
   * Se challengeId não for fornecido, faz o ranking geral
   * @param {string} challengeId
   * @returns {Observable<any[]>}
   */
  getSegmentRanking(ranking: any = null, mode = 'absolute'): Observable<any[]> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    if (!ranking || ranking.type === 'general') {
      return this.fss.list('segments', { api: true, where: [['active', '==', true], ['hasRank', '==', true]] }).pipe(
        map((segments: any[]) => segments.map(s => ({ ...s, credits: s.credits || 0, debits: s.debtis || 0, balance: s.balance || 0 }))),
        map((segments: any[]) => orderBy(segments, ['credits'], ['desc']).map((s, index) => ({ ...s, index: index + 1, rank: index + 1 }))),
        tap((segments: any[]) => this.g.user.rankingSegment = {
          rank: (segments.find(s => s.id === this.g.user.segment.id) || { index: null }).index,
          credits: (segments.find(s => s.id === this.g.user.segment.id) || { credits: 0 }).credits
        }),
      )
    } else {
      // TODO PF passar ranking para functions
      const api: ApiService = use<ApiService>(ApiService)
      return api.post('db/action', { action: 'list', nick: this.g.nick, collection: 'transactions', api: true, where: [['active', '==', true], ['type', '==', 'segments'], ['challengeId', '==', ranking.challengeId]] }, true)
        .pipe(
          map((trans: any[]) => trans.filter(t => typeof t.segment === 'object')),
          switchMap((trans: any) => this.fss.list('segments', { api: true, where: [['active', '==', true], ['hasRank', '==', true]] })
            .pipe(map((segments: any[]) => trans.filter(tr => !!segments.find(s => s.id === tr.segment.id)).map(tr => ({ ...tr, segment: segments.find(s => s.id === tr.segment.id) })))))
        )
        .pipe(
          map((trans: any[]) => {
            const segmentMap: any = {}
            for (const tr of trans) {
              if (segmentMap.hasOwnProperty(tr.segment.id)) segmentMap[tr.segment.id] += tr.orderPoints
              else segmentMap[tr.segment.id] = tr.orderPoints
            }
            const array = Object.keys(segmentMap).map(segID => ({
              id: segID,
              name: trans.find(t => t.segment.id === segID).segment.name,
              credits: segmentMap[segID]
            }))
            return array
          }),
          switchMap((ranks: any[]) => {
            if (mode === 'averange') {
              const api: ApiService = use<ApiService>(ApiService)
              return api.post('db/action', { action: 'list', nick: this.g.nick, collection: 'users', api: true, where: [['active', '==', true]] }, true)
                .pipe(
                  map((users: any[]) => ranks.map(r => ({
                    ...r,
                    _credits: r.credits,
                    credits: parseFloat((r.credits / users.filter(u => u.segment.id === r.id).length).toFixed(2)),
                    users: users.filter(u => u.segment.id === r.id).length
                  }))),
                )
            }
            return of(ranks)
          }),
          map(ranks => orderBy(ranks, ['credits'], ['desc']).map((s, index) => ({ ...s, index: index + 1 }))),
          // tap(console.log)
        )
    }
  }

  /**
   * Cria um ranking por utilizador
   * Se showAll for true, cria um ranking com TODOS os utilizadores
   * Se showAll for false, cria um ranking apenas com os utilizadores que possuem creditos
   * @param {boolean} showAll
   * @returns {Observable<any[]>}
   */
  getUserRanking(ranking: any = null, showAll = false): Observable<any[]> {
    if (!ranking || ranking.type === 'general') {
      const api: ApiService = use<ApiService>(ApiService)
      const obs = showAll ? api.post('db/action', { action: 'list', nick: this.g.nick, collection: 'users', api: true }, true) : api.post('db/action', { action: 'list', nick: this.g.nick, collection: 'users', api: true, where: [['credits', '>', 0]] }, true)
      return obs.pipe(
        map((users: any[]) => users.map(s => ({ ...s, credits: s.credits || 0 }))),
        map((users: any[]) => orderBy(users, ['credits'], ['desc'])),
        map((users: any[]) => users.map((s, index) => ({
          displayName: s.displayName || s.firstName || s.name,
          uid: s.uid || s.id,
          email: s.email,
          credits: s.credits,
          index: index + 1,
          rank: index + 1
        })))
      )
    } else if (ranking.type === 'challenge') {
      // TODO PF passar para functions
      const api: ApiService = use<ApiService>(ApiService)
      return api.post('db/action', { action: 'list', nick: this.g.nick, collection: 'transactions', api: true, where: [['active', '==', true], ['challengeId', '==', ranking.challengeId]] }, true).pipe(
        map((trans: any[]) => {
          trans = trans.filter(o => !o.type || o.type !== 'segments')
          if (ranking.rankingByGroup) trans = trans.filter(o => o.group === ranking.group)
          const userMap: any = {}
          for (const tr of trans) {
            if (userMap.hasOwnProperty(tr.email)) userMap[tr.email].value += tr.orderPoints
            else userMap[tr.email] = { name: tr.displayName, value: tr.orderPoints }
          }
          const array = Object.values(userMap).map(user => ({ displayName: user['name'], credits: user['value'] }))
          // console.log(array)
          return orderBy(array, ['credits'], ['desc']).map((s, index) => ({ ...s, index: index + 1 }))
        })
      )
    } else {
      const api: ApiService = use<ApiService>(ApiService)
      // TODO PF passar para functions
      return api.post('db/action', { action: 'list', nick: this.g.nick, collection: 'transactions', api: true, where: [['active', '==', true], ['tag', '==', ranking.tag]] }, true).pipe(
        map((trans: any[]) => {
          trans = trans.filter(o => !o.type || o.type !== 'segments')
          if (ranking.rankingByGroup) trans = trans.filter(o => o.group === ranking.group)
          const userMap: any = {}
          for (const tr of trans) {
            if (userMap.hasOwnProperty(tr.email)) userMap[tr.email].value += tr.orderPoints
            else userMap[tr.email] = { name: tr.displayName, value: tr.orderPoints }
          }
          const array = Object.values(userMap).map(user => ({ displayName: user['name'], credits: user['value'] }))
          // console.log(array)
          return orderBy(array, ['credits'], ['desc']).map((s, index) => ({ ...s, index: index + 1 }))
        })
      )
    }
  }

  selectRankMethod(ranking: any): Observable<any> {
    if (ranking.view === 'segment') {
      return this.getSegmentRanking(ranking, ranking.mode || 'absolute')
    } else {
      return this.getUserRanking(ranking)
    }
  }

  generateRankEmailRows(ranksConfig: any[], htmlBase: string): Observable<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    if (!ranksConfig || !ranksConfig.length) return of(htmlBase)
    htmlBase = htmlBase.replace(/\n/g, '').replace(/(\[\[RANK=(.+?)\]\])(.+?)(\[\[\/RANK\]\])/gm, '$1_ROWS_$4')
    const matches = htmlBase.match(/(\[\[RANK=(.+?)\]\])(.+?)(\[\[\/RANK\]\])/gm)
    const observables = []

    for (const mat of matches) {
      const id = mat.replace(/(\[\[RANK=(.+?)\]\])(.+?)(\[\[\/RANK\]\])/gm, '$2')
      const config = ranksConfig.find(c => c.challenge === id)
      observables.push(
        this.fss.get('rankings', id, { api: true })
          .pipe(switchMap((rankConfig: any) => this.selectRankMethod(rankConfig).pipe(map((ranks: any[]) => {
            const rows = []
            for (const r of ranks.splice(0, config.qty).map((r, i) => ({ ...r, i: i + 1 }))) {
              rows.push(config.row.replace('[[POINTS]]', r.credits).replace('[[EQUIPA]]', r.name || r.displayName || r.name).replace('[[NO]]', r.i))
            }
            return { mat, rows: rows.join('') }
          }))))
      )
    }

    return combineLatest(observables).pipe(map((rows: any[]) => {
      let html = htmlBase
      for (const r of rows) html = html.replace(r.mat, r.rows)
      return html
    }))
  }
}
