import { Injectable, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs/internal/Subscription'
import { first } from 'rxjs/operators'
import { SubSink } from 'subsink'
import { ApiService } from './api.service'
import { AuthService } from './auth.service'
import { FirestoreService2 } from './firestore.service2'
import { GlobalService } from './global.service'
import { NotifyService } from './notifications/notify.service'
import { chunkArray, use } from './utils'
import { XlsService } from './xls.service'

@Injectable({ providedIn: 'root' })
export class UserService implements OnDestroy {
  type: string
  nick: string
  apiUrl: string
  count: number
  program: any

  private subs = new SubSink()
  private segmentSubscription: Subscription = null
  private fss: FirestoreService2

  constructor(
    public g: GlobalService,
    private notify: NotifyService,
    private auth: AuthService,
    private xlsService: XlsService,
    private api: ApiService
  ) {
    // console.info(`## ${this.constructor.name}`)
    this.listenUserUpdates()
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  async updateUserEmail(user: any, userOldEmail: string) {
    const api = use<ApiService>(ApiService)
    api.post('user/userAction', { action: 'updateUserEmail', uid: user.uid, email: user.email }, true).pipe(first()).toPromise().then(updated => {
      if (updated && updated.hasOwnProperty('success') && !updated.success) {
        throw Error(updated.error.indexOf('already in') > -1 ? 'Este email já é cadastrado por outro usuário.' : updated.error)
      } else {
        api.post('users/changeEmailsInCollections', { oldEmail: userOldEmail, newEmail: user.email, nick: user.nick }, true).pipe(first()).toPromise().then(ok => {
          if (ok.success) this.notify.update('Coleções alteradas', 'btn-success', 3000)
          else if (ok.error) this.notify.update(ok.error, 'btn-danger', 3000)
        })
      }
    })
  }

  listenUserUpdates() {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    // console.log('*# listenUserUpdates()');
    if (this.g.user && (this.g.user.uid || this.g.user.id)) this.subs.add(
      this.fss.get('users', this.g.user.uid || this.g.user.id).subscribe(user => {
        this.g.set('user', user)
        if (user.segment) this.listenUserSegment(user)
      })
    )
  }

  private listenUserSegment(user: any) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    if (!this.segmentSubscription) {
      this.segmentSubscription = this.fss.get('segments', user.segment.id).subscribe(segment => this.g.set('segment', segment))
      this.subs.add(this.segmentSubscription)
    }
  }

  recalcBalance(user: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const api = use<ApiService>(ApiService)
      this.notify.update('Recalculando ...', 'btn-success', 3000)
      api.post('transactions/points', user, true).pipe(first()).toPromise().then((result) => {
        this.notify.update('Recálculo efectuado.', 'btn-success', 3000)
        console.log('# calculado')
        resolve(result)
      }, err => {
        console.log(err)
        reject(err.message)
      })
    })
  }

  sendAccountActivationEmail(data) {
    const api = use<ApiService>(ApiService)
    api.post('sendinblue/sendAccountActivation', data, true).pipe(first()).toPromise()
      .then(() => { this.notify.update('Emails enviados.', 'btn-success', 3000) })
    this.notify.update('A enviar emails ...', 'btn-success', 3000)
  }

  updateAllBalances(data) {
    const api = use<ApiService>(ApiService)
    api.post('db/action', { action: 'list', nick: this.g.nick, collection: 'users', api: true }, true).subscribe(async users => {
      for (const chunk of chunkArray(users, 250)) {
        await api.post(`transactions/updateAllBalances`, { nick: this.g.nick, data: chunk }, true).pipe(first()).toPromise().then()
      }
    })
  }

  // TODO PF/WG migrar para templates de email e mover para email.service
  sendWelcomeUsers(items) {
    const program = {
      name: this.g.program.name,
      email: this.g.program.email,
      sender: this.g.program.sender,
      url: this.g.program.url,
      imgHeader: this.g.program.images['emailHeader'],
      imgFooter: this.g.program.images['emailFooter'],
      subject: `${this.g.program.name} | Ativa a tua conta`,
      templateId: Number(this.g.program.emails.newAccount),
    }
    const api = use<ApiService>(ApiService)
    api.post('sendinblue/sendMany', { items: items, program: program }, true).toPromise()
  }

  sendResetEmail(email: string) {
    const _program = {
      name: this.g.program.name,
      nick: this.g.nick,
      email: this.g.program.email,
      sender: this.g.program.sender,
      imgHeader: this.g.program.images['emailHeader'],
      imgFooter: this.g.program.images['emailFooter'],
      templateId: this.g.program.emails['resetPassword'],
      url: this.g.program.url
    }
    const body = {
      nick: this.g.nick,
      email: email,
      displayName: email.split('@')[0],
      program: _program
    }
    if (this.g.program.logins.lookup) {
      const api = use<ApiService>(ApiService)
      // this.subs.add(
      // this.api.post(`db/action`, { action: 'list', nick: this.g.nick, collection: 'prospects', queryArray: [['email', '==', email]] }, true).subscribe(prospect => {
      api.post(`open/d/action`, {
        action: 'list',
        collection: 'prospects',
        nick: this.g.nick,
        queryArray: [ [ 'email', '==', email ] ]
      }, true).pipe(first()).toPromise().then(prospect => {
        let used = false
        if (prospect.length > 0) used = prospect[0].used
        if (prospect.length <= 0 || !used) {
          this.notify.update('Este email não está registado, por favor registe-se primeiro.', 'btn-danger', 3000)
        } else this.auth.resetPasswordEmail(body).then(link => console.log(link))
      })
      // )
    } else this.auth.resetPasswordEmail(body).then(link => console.log(link))
  }

  async createUserVisit() {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)

    if (this.g.user && this.g.user.email) {
      const dt = new Date()
      dt.setUTCHours(0, 0, 0, 0)
      this.fss.list(`logs`, { where: [ [ 'timestamp', '>=', dt.getTime() ], [ 'type', '==', 'access' ], [ 'email', '==', this.g.user.email ] ] })
        .subscribe((logs: any[]) => {
          if (!logs.length) {
            const visit = {
              displayName: this.g.user.displayName,
              email: this.g.user.email,
              segment: this.g.user.segment,
              device: this.g.device || 'Unknown',
              timestamp: new Date().getTime(),
              type: 'access'
            }
            this.fss.add(`logs`, visit).then(res => { }).catch(err => console.log(err))
            this.giveBadge()
          }
        })
    }
  }

  async giveBadge() {
    if (this.g.user) {
      const userBadges = this.g.user.badges || []

      const loginBadges = await this.fss.list(`badges`, { where: [ [ 'condition.script', '==', 'login' ], [ 'active', '==', true ] ] }).pipe(first()).toPromise()

      for (const loginBadge of loginBadges) {

        if (!userBadges.find(userBadge => userBadge.id === loginBadge.id)) {
          this.subs.add(
            this.fss.list(`logs`, { where: [ [ 'email', '==', this.g.user.email ], [ 'type', '==', 'access' ] ] })
              .subscribe((logs: any[]) => {
                if (logs.length >= loginBadge.condition.value) {
                  loginBadge.receivedAt = new Date().toISOString()
                  userBadges.push(loginBadge)
                  this.fss.update('users', this.g.user.id, { badges: [ ...userBadges ] }).then(() => console.log('##Updated user badges'))
                }
              })
          )
        }
      }
    }
  }

  async getPointsExpiring(users) {
    console.log(users)
    const d = new Date()
    const year = d.getFullYear()
    const month = d.getMonth()
    const beginDate = new Date(year - 1, month, 1)
    const endDate = new Date(year, month + 1, 1)
    const activeUsers = users.filter(o => o.active)
    const data = []
    const api = use<ApiService>(ApiService)
    this.subs.add(api.post(`db/action`, {
      action: 'list',
      nick: this.g.nick,
      collection: 'transactions',
      where: [ [ 'active', '==', true ], [ 'code', '==', 'Pontos atribuidos' ] ]
    }, true).subscribe(_points => {
      for (const user of activeUsers) {
        const points = _points.filter(o => o.email === user.email)
        let pointDate, credits = 0, expired = 0, userCredits = 0
        for (const point of points) {
          pointDate = new Date(point.orderDate)
          if (point.description !== 'Pontos expirados') {
            userCredits = userCredits + Number(point.orderPoints)
          }
          if (point.description !== 'Pontos expirados' && beginDate <= pointDate && pointDate < endDate) {
            credits = credits + Number(point.orderPoints)
          }
          if (point.description === 'Pontos expirados') {
            expired = expired + Number(Math.abs(point.orderPoints))
          }
        }
        if (expired > 0) {
          data.push({
            'user de rede': user.userCode,
            'nome': user.displayName,
            'email': user.email,
            'segmento': user.segment,
            'pontos atribuidos desde o início': userCredits,
            'pontos gastos desde o início': user.debits,
            'saldo antes de expirar': user.balance + expired,
            'pontos atribuidos nos últimos 12 meses': credits,
            'pontos expirados': expired
          })
        }
      }
    }))
    if (data.length > 0) this.xlsService.export('users', data)
  }

  async givePromotionalPoints(user, promoCode: string = null, manager: boolean = false): Promise<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    console.log('givePromotionalPoints', user.id)
    const d = new Date()
    if (!user.promoCodes && !manager) user.promoCodes = []
    if (user.promoCodes.includes(promoCode) && !manager) this.notify.update('Código já utilizado, por favor use outro!', 'btn-danger', 5000)
    else {
      if (this.g.program.logins.promos.some(promo => promo.name === promoCode)) {
        this.notify.update('Inseriu um código válido.', 'btn-success', 5000)
        for (const promo of this.g.program.logins.promos) {
          // console.log(promo, user, new Date(promo.endDt) < d)
          if (promo.name === promoCode && new Date(promo.endDt) >= d) {
            const points = [ {
              beginDt: this.g.program.cashbacks.beginDt,
              cashbackId: promoCode,
              code: 'Pontos atribuidos',
              description: `Código promocional ${promoCode}`,
              displayName: user['displayName'],
              email: user['email'],
              nick: this.nick,
              orderDate: d.toISOString(),
              orderPoints: promo.points,
              orderEuros: promo.points,
              primaryCode: 'Pontos',
              programPriceMax: this.g.program.programPriceMax,
              segment: user['segment'],
              userPriceMax: this.g.program.userPriceMax,
              xRate: 1,
            } ]

            const api = use<ApiService>(ApiService)
            await api.save('Points', 'transactions', points, 'id').then(async data => {
              if (!manager) user.promoCodes.push(promoCode)
              if (user.id) this.fss.update(`users`, user.id, { promoCodes: !manager ? user.promoCodes : [ promoCode ], createdAt: user.createdAt, createdBy: user.createdBy })
              this.notify.update(`Código promocional ${promoCode} usado. Pontos atribuidos.`, 'btn-success', 10000)
            })

          } else if (promo.name === promoCode && new Date(promo.endDt) < d) {
            this.notify.update('Data limite do código ultrapassada, por favor use outro!', 'btn-danger', 5000)
          }
        }
      } else this.notify.update('Código inválido, por favor use outro!', 'btn-danger', 5000)
    }
  }
}
