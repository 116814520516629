import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { timeOut } from '../utils'
import { ConfirmModalComponent } from './confirm-modal.component'
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog'
import { PromptModalComponent } from './prompt-modal.component'

/// Notify users about errors and other helpful stuff
export interface Msg {
  content: string
  content2: string
  style: string
  link: {
    name: string,
    url: string
  }
}

export interface IModalConfig {
  message?: string
  title?: string
}

@Injectable({ providedIn: 'root' })
export class NotifyService {

  private _msgSource = new Subject<Msg | null>()
  private ref: DynamicDialogRef
  private dialogService: DialogService
  msg = this._msgSource.asObservable()

  modalEvent = new BehaviorSubject<any>(null)

  constructor() { }

  defineDialogService(srv: DialogService) {
    this.dialogService = srv
  }

  update(content: string, style: 'btn-danger' | 'btn-info' | 'btn-success' | 'btn-warning' | 'btn-link' | 'btn-primary' | 'btn-secondary', timeout: number, link?, content2?) {
    const msg: Msg = { content, content2, style, link }
    this._msgSource.next(msg)
    timeOut(() => { this._msgSource.next(null) }, timeout)
  }

  openModal(config: any = {}): any {
    // return this.dialogRef.open(modalComponent, { panelClass: 'notity-generic-modal', ...config });
    this.modalEvent.next(config)
  }

  awaitForNotification(awaitPromise: Promise<string>, type = 'snackbar') {
    awaitPromise.then(msg => {
      if (type === 'snackbar') {
        // this.snackRef.open(msg, 'ok', { duration: 2000 })
        this.update(msg, 'btn-success', 2000)
      } else if (type === 'modal') {
        this.openModal({ data: { title: 'Aviso', message: msg } })
      } else {
        this.update(msg, 'btn-success', 10000)
      }
    })
  }

  clear() {
    this._msgSource.next(null)
  }

  confirm(title: string, message: string, btnOkText: string = 'OK', btnCancelText: string = 'Cancel', dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
    return new Promise(resolve => {
      this.ref = this.dialogService.open(ConfirmModalComponent, {
        header: title,
        width: '300px',
        contentStyle: {
          'overflow': 'auto',
        },
        baseZIndex: 10000,
        closable: false,
        data: {
          title,
          message,
          btnOkText,
          btnCancelText
        }
      })
      this.ref.onClose.subscribe((result: any) => resolve(result))
    })
  }

  prompt(title: string, message: string, btnOkText: string = 'OK', btnCancelText: string = 'Cancel', type = 'text', dialogSize: 'sm' | 'lg' = 'sm'): Promise<any> {
    return new Promise(resolve => {
      const config = {
        width: '300px',
        contentStyle: {
          'overflow': 'auto',
        },
        baseZIndex: 100000,
        closable: false,
        data: {
          title,
          message,
          type,
          btnOkText,
          btnCancelText
        }
      }
      if (title) config['header'] = title
      this.ref = this.dialogService.open(PromptModalComponent, config)
      this.ref.onClose.subscribe((result: any) => resolve(result))
    })
  }

  alert(title: string, message: string, btnOkText: string = 'OK', dialogSize: 'sm' | 'lg' = 'sm', style = {}): Promise<boolean> {
    return new Promise(resolve => {
      const config = {
        // width: '300px',
        contentStyle: {
          'overflow': 'auto',
          'width': '300px',
          'max-width': 'inherit',
          ...style
        },
        baseZIndex: 10000,
        closable: false,
        data: {
          title,
          message,
          btnOkText: btnOkText || 'OK',
          btnCancelText: null
        }
      }
      if (title) config['header'] = title
      this.ref = this.dialogService.open(ConfirmModalComponent, config)
      this.ref.onClose.subscribe((result: any) => resolve(result))
    })
  }
}
