import { Injectable, OnDestroy } from '@angular/core'
import { SubSink } from "subsink"
import orderBy from 'lodash-es/orderBy'
import uniqBy from 'lodash-es/uniqBy'
import { first } from "rxjs/operators"
import { EventDetailType, EventType } from './calendars.service'
import { EmailService } from './email.service'
import { PrimaryCode } from './enums'
import { EventService } from './event.service'
import { FirestoreService2 } from './firestore.service2'
import { GlobalService } from './global.service'
import { use } from './utils'

@Injectable({ providedIn: 'root' })

export class NotificationService implements OnDestroy {
  order = ['imediato', 'muito urgente', 'urgente', 'normal', 'sem urgência']
  goalsChatsCount = 0
  totalNotifications = 0
  program: any
  calls = []
  cashbacks = []
  goals = []
  chats = []
  stores = []
  challenges = []

  private subs = new SubSink()
  private fss: FirestoreService2

  constructor(
    public g: GlobalService,
  ) {
    console.info(`## ${this.constructor.name}`)
    this.program = this.g.get('program')
    EventService.get('logged-out').subscribe(() => {
      this.subs.unsubscribe()
      this.goalsChatsCount = 0
      this.totalNotifications = 0
      this.calls = []
      this.cashbacks = []
      this.goals = []
      this.chats = []
      this.stores = []
    })
  }
  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  getMessages(user) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    const query1 = user.role !== 'user' && user.role !== 'influencer' ? [['active', '==', true], ['status', '==', 'Pendente']] : [['active', '==', true], ['status', '==', 'Resolvido'], ['email', '==', user.email]]
    const where = [['type', '==', EventType.CHAT], ['active', '==', true], ['seenBy', '==', '']]
    if (user.role === 'admin' || user.role === 'manager') where.push(['support', '==', false])
    else where.push(['support', '==', true])


    this.subs.add(
      this.fss.list(`messages`, { where: query1 }).subscribe(calls => {
        if (calls && calls.length) {
          this.calls = this.calls.filter(o => o.primaryCode !== PrimaryCode.MESSAGE)
          this.prepareMessages(calls, user)
        }
      }),
      this.fss.list(`events`, { where: where }).subscribe(calls => {
        if (calls && calls.length) {
          const segments = this.g.user.segments
          if (segments && segments.length && !segments.filter(o => o === this.g.user.segment.id).length) segments.push(this.g.user.segment.id)

          this.calls = this.calls.filter(o => o.type !== EventType.CHAT)
          const _calls = this.g.user.role === 'user' ? calls.filter(o => segments.includes(o.detail.store.segment.id)) : calls
          this.prepareMessages(_calls, user)
        }
      })
    )
  }

  getAlerts(user, eventType) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    let where = []
    if (eventType === 'EXPIRED_GOAL') {
      where = [['eventType', '==', eventType], ['active', '==', true], ['seenBy', '==', ''], ['user', '==', user.email]]
    } else {
      where = [['eventType', '==', eventType], ['active', '==', true], ['seenBy', '==', '']]
      if (user.role === 'user' && eventType === EventDetailType.EXPIRED_GOAL) where.push(['user', '==', user.email])
    }
    this.subs.add(
      this.fss.list(`events`, { where: where }).subscribe(calls => {
        this.calls = this.calls.filter(o => o.eventType !== eventType)
        this.prepareMessages(calls, user)
      })
    )
  }

  async prepareMessages(calls, user) {
    const end = new Date()
    const workStart = new Date("1/1/0001 " + "8:00").getHours() + (new Date("1/1/0001 " + "8:00").getMinutes() / 60)
    const workEnd = new Date("1/1/0001 " + "17:00").getHours() + (new Date("1/1/0001 " + "17:00").getMinutes() / 60)

    let elapsed
    let start = new Date()
    calls = calls.filter(o => !o.closed)
    for (let i = 0; i < calls.length; i++) {
      start = new Date(calls[i].updatedAt)
      elapsed = this.elapsedTime(start, end, workStart, workEnd, true)
      // CHANGES MESSAGE URGENCY BY HOW MUCH TIME PASSED
      if (!calls[i].urgency && elapsed >= 8 && elapsed < 12) calls[i].urgency = 'sem urgência'
      if (elapsed >= 12 && elapsed < 18) calls[i].urgency = 'normal'
      if (elapsed >= 18 && elapsed < 21) calls[i].urgency = 'urgente'
      if (elapsed >= 21 && elapsed < 23) calls[i].urgency = 'muito urgente'
      if (elapsed >= 23) calls[i].urgency = 'imediato'
      // console.log(elapsed, calls[i])
      // DEFINES THE COLORS OF THE MESSAGES BY URGENCY
      if (calls[i].urgency === 'imediato') calls[i].className = 'immediate'
      if (calls[i].urgency === 'muito urgente') calls[i].className = 'very-urgent'
      if (calls[i].urgency === 'urgente') calls[i].className = 'urgent'
      if (calls[i].urgency === 'normal') calls[i].className = 'standard'
      if (calls[i].urgency === 'sem urgência') calls[i].className = 'non-urgent'
      if (!calls[i].urgency) calls[i].className = ''
      if (this.g.program.emails.contactUsToMgr && user.role === 'manager') {
        calls[i]['op-30'] = calls[i].type === 'Atribuição de Pontos' ? false : true
      } else if (user.role === 'admin') {
        calls[i]['op-30'] = calls[i].type === 'Atribuição de Pontos' ? true : false
      }
      this.calls.push(calls[i])
    }

    // calcula dias caducados
    const _now = new Date().getTime()
    const oneDayMs = 60000 * 60 * 24
    for (const alert of this.calls) {
      if (alert.type === EventType.ALERT && alert.eventType === EventDetailType.EXPIRED_GOAL && alert.detail && alert.detail.lastExecutionDate && alert.detail.message) {
        const days = Math.ceil((_now - (+alert.detail.lastExecutionDate)) / oneDayMs)
        alert.detail.message = alert.detail.message.replace('__days__', days)
        alert.days = days
        // alert.path = `${alert.path}?EXPIRED_GOAL=${days}&event=${alert.id}`;
      }
    }

    this.calls = this.calls.sort((a, b) => this.order.indexOf(a.urgency) - this.order.indexOf(b.urgency))
    this.totalNotifications = this.calls?.length + this.cashbacks?.length + this.goals?.length + this.challenges?.length
    // console.log(this.calls.filter(c => c.type !== 'GOAL'))
  }

  getGoals(user: any) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    // let _goals = [];
    const where = [['active', '==', true], ['type', '==', EventType.GOAL], ['seenBy', '==', ''], ['status', '==', 'completed']]
    if (user && user.role === 'user') where.push(['user', '==', user.email])

    this.fss.list(`events`, { where: where }).subscribe((events: any[]) => {
      this.goals = []
      if (events.length) {

        this.goals = orderBy(uniqBy(events, 'storeId'), 'createdAt')
        // console.log(this.goals)
      }
      this.totalNotifications = this.calls?.length + this.cashbacks?.length + this.goals?.length + this.challenges?.length
    })
  }

  getCashbacks(user) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    if (user.role !== 'user') {
      this.fss.list(`cashbacks`, { where: [['approved', '==', false]] }).subscribe(cashbacks => {
        cashbacks = cashbacks.filter(o => !o.invalid)
        this.cashbacks = cashbacks
        this.totalNotifications = this.calls?.length + this.cashbacks?.length + this.goals?.length + this.challenges?.length
      })
    }
  }

  getChallenges(user) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    this.fss.list(`events`, { where: [['active', '==', true], ['type', '==', EventType.CHALLENGE], ['user', '==', user.email]] }).subscribe((events: any[]) => {
      this.challenges = []
      if (events.length) {
        this.challenges = orderBy(events, 'createdAt')
      }
      this.totalNotifications = this.calls?.length + this.cashbacks?.length + this.goals?.length + this.challenges?.length
    })
  }

  elapsedTime(startDate, endDate, dayStart, dayEnd, includeWeekends) { // http://rion.io/2014/06/20/calculating-business-hours-in-javascript/
    /*const includeHolidays = includeWeekends // simplification!
    // console.log(startDate, endDate)
    let minutesWorked = 0; // Store minutes worked
    if (endDate < startDate) {
      return 0;
    } // Validate input
    const current = startDate; // Loop from your Start to End dates (by hour)
    const workHoursStart = dayStart;
    const workHoursEnd = dayEnd; // Define work range
    while (current <= endDate) { // Loop while currentDate is less than end Date (by minutes)
      const currentTime = current.getHours() + (current.getMinutes() / 60); // Store the current time (with minutes adjusted)
      // Is the current time within a work day (and if it occurs on a weekend or not)
      if (currentTime >= workHoursStart && currentTime < workHoursEnd
        && (includeWeekends ? current.getDay() !== 0 && current.getDay() !== 6 : true)
        && (includeHolidays ? !this.getHoliday(current) : true)) {
        minutesWorked++;
      }
      current.setTime(current.getTime() + 1000 * 60); // Increment current time
    }
    // console.log(startDate, endDate, dayStart, dayEnd, (minutesWorked / 60).toFixed(2))
    return (minutesWorked / 60).toFixed(2); // Return the number of hours*/

    const DAYS = 86400000
    const HOURS = 3600000
    return ((endDate.getTime() - startDate.getTime()) / DAYS) * 7
  }

  getHoliday(current): boolean {
    // console.count('# getHoliday')
    // TODO PF fetch from DB an array with holidays for the current year ... doesn´t google have an API for this?
    const holidays = ['2020-04-10', '2020-05-01', '2020-06-10', '2020-10-05', '2020-11-01', '2020-12-01', '2020-12-08', '2020-12-25']
    // console.log(current.toISOString())
    for (let i = 0; i < holidays.length; i++) {
      if (current.toISOString().substr(0, 10) === holidays[i]) {
        return true
      }
    }
    return false
  }

  createGoalNotification(goal: any, store: any, user: any) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    this.subs.add(
      this.fss.list(`events`, { where: [['eventId', '==', `${goal.id}`], ['status', '==', 'completed'], ['seenBy', '==', '']], api: true }).subscribe(events => {
        if (!events.length) {
          let lastExecutionDate = Math.max.apply(null, goal.executions.map(e => {
            return new Date(e.executionDate)
          }))
          if (!isNaN(lastExecutionDate)) lastExecutionDate = new Date(lastExecutionDate).toISOString()
          const dt = new Date()
          const notify = {
            active: true,
            campaignNick: goal.campaignNick,
            createdAt: lastExecutionDate || dt.toISOString(),
            detail: {
              store: store ? (({ name, id, chain, storeType, templateType }) => ({ name, id, chain, storeType, templateType }))(store) : null,
              goal: (({ id, goalId, name, status, prevStatus, type, updatedBy }) => ({ id, goalId, name, status, prevStatus, type, updatedBy }))(goal)
            },
            dev: true,
            eventId: goal.id,
            eventType: EventDetailType.GOAL,
            icon: null,
            nick: this.g.nick,
            seenBy: '',
            storeId: store.id,
            startTimestamp: dt.getTime(),
            status: goal.status,
            templateType: goal.templateType,
            type: EventType.GOAL,
            updatedAt: lastExecutionDate || dt.toISOString(),
            user: user.email || '',
          }
          this.fss.add('events', notify).then()
        }
      })
    )
  }

  markReadGoalNotification(goal: any, user: any) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    const storeId = goal.id.split('-')[0]
    const goalId = goal.id.replace(`${storeId}-`, '')
    this.subs.add(this.fss.list(`events`, { where: [['eventId', '==', goal.id]], take: 1 }).subscribe((events: any[]) => {
      if (events && events.length)
        for (const evt of events) {
          const updEvent = { ...evt, seenBy: user.email, }
          this.fss.update(`events`, evt.id, updEvent)
        }
    }))
  }

  deleteGoalNotification(id: string) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    this.subs.add(this.fss.list(`events`, { where: [['eventId', '==', id]], take: 1 }).subscribe((events: any[]) => {
      if (events && events.length)
        for (const evt of events) {
          this.fss.update(`events`, evt.id, { active: false })
        }
    }))
  }

  readGoalApproved(goal: any) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    if (goal.status === 'approved')
      this.fss.update(`events`, goal.id, { seenBy: goal.user })
  }

  createPointsApproval(user: any, eventType: string, type: string) {

    this.sendEventAlert({
      eventType: eventType,
      detail: {
        message: `Novo utilizador registado com pontos por aprovar.`,
        user: {
          email: user.email,
          name: user.displayName,
          uid: user.uid,
          segment: user.segment,
          userCode: user.userCode,
        }
      },
      path: `/admin/transactions/validation/points/${user.uid || user.id}`,
      type: type,
      userEmail: user.email
    })
  }

  async createUserVerification(user: any, otherUser: any = null, data: any, eventType: string, type: string) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    const events = await this.fss.list(`events`, { where: [['user', '==', user.email], ['seenBy', '==', ''], ['eventType', '==', eventType]], take: 1 }).pipe(first()).toPromise()

    const item = {
      eventType: eventType,
      detail: {
        message: `Tentativa de registo com dados errados ou já inseridos por outros utilizador`,
        user: {
          email: user.email,
          name: user.displayName,
          uid: user.uid,
          userCode: user.userCode,
          mobile: data.mobile,
          nif: data.nif,
          segment: data.segment
        }
      },
      path: `/admin/transactions/validation/users/${user.uid}`,
      type: type,
      userEmail: user.email
    }

    if (otherUser) item.detail['otherUser'] = {
      email: otherUser.email,
      name: otherUser.displayName,
      uid: otherUser.uid,
      segment: otherUser.segment,
      userCode: otherUser.userCode,
    }

    if (!events.length) {
      this.sendEventAlert(item)

      this.sendEmailToManager(item)
    }
  }

  createChallengeNotification(data: any, id: string) {

    const item = {
      detail: {
        message: `Desafiou-te para um Grande Duelo! Clica para ver os teus duelos e responderes a quem te desafiou.`,
        challenged: {
          displayName: data.challenged.displayName,
          email: data.challenged.email
        },
        challenger: {
          displayName: data.challenger.displayName,
          email: data.challenger.email
        },
        challengeId: id,
      },
      eventType: 'CHALLENGE',
      path: `/${this.g.type}/challenges/${data.code}`,
      type: 'CHALLENGE',
      userEmail: data.challenged.email
    }

    this.sendEventAlert(item)
  }

  private async sendEventAlert(options: any) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    const dt = new Date()
    const notify = {
      active: true,
      createdAt: dt.toISOString(),
      detail: options.detail,
      eventType: options.eventType,
      nick: this.g.nick,
      path: options.path,
      seenBy: '',
      type: options.type,
      updatedAt: dt.toISOString(),
      user: options.userEmail || '',
    }

    await this.fss.add(`events`, notify)
  }

  sendEmailToManager(event) {
    console.log('sendEmailToManager', event)

    const program = {
      name: this.g.program.name,
      sender: this.g.program.sender,
      email: this.g.program.email,
      url: this.g.program.url,
      subject: 'Tentativa de registo falhada',
      html: `Informamos que o utilizador <strong>${event.detail.user.name}</strong> com o user de rede <strong>${event.detail.user.userCode}</strong> tentou registar com os dados
       ${event.detail.otherUser ? 'de outra pessoa' : 'errados'}.<br>
       Esta notificação está disponivel no sino de mensagens da plataforma.
      `,
      imgHeader: this.g.program.images['emailHeader'],
      imgFooter: this.g.program.images['emailFooter'],
      templateId: this.g.program.emails['generic']
    }
    const item = {
      email: this.g.program.manager, // isabel.machado@nos.pt
    }

    const emailService = use<EmailService>(EmailService)
    emailService.sendOne(item, program).toPromise()
  }
}
