import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { GlobalService } from './global.service'

export interface IQuery {
  field: string,
  operator: string,
  value: any
}

@Injectable({ providedIn: 'root' })
export class TemplateService {
  type: string
  nick: string
  apiUrl: string
  count: number
  program: any

  constructor(
    private http: HttpClient,
    public g: GlobalService,
  ) {
    console.info(`## ${this.constructor.name}`)
    this.apiUrl = this.g.get('apiUrl')
  }
  async updateSmtpTemplate(template, collection) {
    const endpoint = this.apiUrl + '/app/updateSmtpTemplate'
    template.htmlContent = this.makeHtml(template.htmlContent, template.subject).toString()
    const body = { template: template, collection: collection }
    const data = await this.http.post(endpoint, body).toPromise()
    return data
  }

  async getSmtpTemplates(collection) {
    const endpoint = this.apiUrl + 'app/getSmtpTemplates?nick='
    const data = await this.http.get(`${endpoint}?nick=${this.g.nick}&collection=${collection}`).toPromise()
    return data
  }

  async createSmtpTemplate(template, collection) {
    const endpoint = this.apiUrl + 'app/createSmtpTemplate?nick='
    const data = await this.http.post(`${endpoint}?nick=${this.g.nick}&collection=${collection}`, template).toPromise()
    return data
  }

  async getSmtpTemplate(template, collection) {
    const endpoint = this.apiUrl + 'app/getSmtpTemplate?nick='
    const data = await this.http.post(`${endpoint}?nick=${this.g.nick}&collection=${collection}`, template).toPromise()
  }

  public makeHtml(body, subject) {
    let htmlContent = '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">    <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">'
    htmlContent += '<head>'
    htmlContent += '<meta http-equiv="X-UA-Compatible" content="IE=edge" />'
    htmlContent += '<meta name="viewport" content="width=device-width, initial-scale=1" />'
    htmlContent += '<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />'
    htmlContent += '<meta name="apple-mobile-web-app-capable" content="yes" />'
    htmlContent += '<meta name="apple-mobile-web-app-status-bar-style" content="black" />'
    htmlContent += '<meta name="format-detection" content="telephone=no" />'
    htmlContent += '<title>' + subject + '</title>'
    htmlContent += '<!--<![endif]-->'
    htmlContent += '<!--[if gte mso 15]><xml><o:OfficeDocumentSettings><o:AllowPNG/><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml><![endif]-->'
    htmlContent += '<!--[if gte mso 9]><xml><o:OfficeDocumentSettings><o:AllowPNG/><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml><![endif]-->'
    htmlContent += '</head>'
    htmlContent += '<body>'
    htmlContent += body.toString()
    htmlContent += '</body>'
    return htmlContent
  }
}
