import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: "sentencecase" })
export class SentenceCasePipe implements PipeTransform {
  public transform(input: string): string {
    if (!input) {
      return ""
    } else {
      return input
        .toLowerCase()
        .replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) {
          return c.toUpperCase()
        })
    }
  }
}

import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    SentenceCasePipe,
  ],
  exports: [SentenceCasePipe],
  providers: []
})
export class SentenceCasePipeModule { }
