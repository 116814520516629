import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'
import { GlobalService } from '../global.service'

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[canWrite]'
})
export class CanWriteDirective implements OnInit {

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private g: GlobalService) { }

  ngOnInit() {
    if (this.g.canWrite) this.viewContainer.createEmbeddedView(this.templateRef)
    else this.viewContainer.clear()
  }

}
