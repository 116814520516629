import { Component, OnDestroy } from '@angular/core'
import { NotifyService } from './notify.service'
import { Subscription } from "rxjs"

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.html',
  styleUrls: ['./notifications.sass'],

})
export class NotificationsComponent implements OnDestroy {

  modalShow = false
  modalData: any = null
  modalEvent: Subscription = null

  constructor(public notify: NotifyService) {
    this.modalEvent = this.notify.modalEvent.subscribe((event: any) => {
      if (event === null) {
        this.modalShow = false
        this.modalData = null
      } else {
        this.modalShow = true
        this.modalData = event
      }
    })
  }

  modalResult(event) {
    if (event === null) {
      this.modalShow = false
      this.modalData = null
    } else {
      this.modalShow = true
      this.modalData = event
    }
  }

  ngOnDestroy(): void {
    if (this.modalEvent) this.modalEvent.unsubscribe()
  }

}
