import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CanWriteDirective } from './can-write.directive'
import { CheckRoleDirective } from './check-role.directive'
import { ImageUploadDirective } from './image-upload.directive'
import { IsDevDirective } from './is-dev.directive'

@NgModule({
  declarations: [
    CheckRoleDirective,
    CanWriteDirective,
    ImageUploadDirective,
    IsDevDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CheckRoleDirective,
    CanWriteDirective,
    ImageUploadDirective,
    IsDevDirective
  ]
})
export class DirectivesModule {
}
