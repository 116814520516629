import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators'
import { ApiService } from './api.service'

export interface IGptChatMessage {
  role: 'system' | 'user',
  content: string
}

@Injectable({
  providedIn: 'root'
})
export class AiService {

  constructor(private api: ApiService) { }

  translate(text: string, lang: string): Promise<any> {
    return this.api.post('ai/translate', { text, lang }).pipe(first()).toPromise()
  }

  chat(messages: IGptChatMessage[]): Promise<any> {
    return this.api.post('ai/generateChat', { messages }).pipe(first()).toPromise()
  }

  generateImage(prompt: string, size: string): Promise<string[]> {
    return this.api.post('ai/generateImage', { prompt, size }).pipe(first()).toPromise()
  }
}
