import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { TranslocoService } from '@ngneat/transloco'
import { NotifyService } from './notifications/notify.service'
import { first, tap } from 'rxjs/operators'
import { ApiService } from './api.service'
import { AuthService } from './auth.service'
import { FirestoreService2 } from './firestore.service2'
import { GlobalService } from './global.service'
import { use } from './utils'

let env: any
let BUILD: any

@Injectable({
  providedIn: 'root'
})
export class AuthMsadService {

  private fss: FirestoreService2

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private router: Router,
    private notify: NotifyService,
    private g: GlobalService,
    private transloco: TranslocoService) {
    console.info(`## ${this.constructor.name}`)
    env = (window as any).y4wenvs.ENV
    BUILD = (window as any).y4wenvs.BUILD
  }

  /**
   * Método para fazer login Microsoft no Android usando Plugin que criamos para capacitor
   * @param {string} token
   * @returns {Promise<any>}
   */
  microsoftLoginViaCapacitorPlugin(token: string): Promise<any> {
    return this.http.get('https://graph.microsoft.com/v1.0/me', {
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      }
    }).pipe(first()).toPromise().then((user: any) => {
      if (!user || !user.mail || user.mail.toLowerCase().indexOf('.onmicrosoft.com') > -1) {
        this.saveDebug({ message: `# adUser email invalido`, stack: JSON.stringify(user) })
        this.notify.update('O teu email não é valido, por favor contacte o HelpDesk', 'btn-danger', 6000)
        return Promise.reject('email_is_invalid')
      }
      return this.authMicrosoftWithFirebase(user)
    }).catch(async er => {
      console.log('# graph', er.message)
      this.saveDebug({ message: `# microsoftLoginViaCapacitorPlugin`, stack: er.message })
      // this.notify.update(er.message, 'btn-danger', 3000)
      this.notify.alert('Atenção!', er.message)
    })
  }

  /**
   * Ouve o login Microsoft para web e IOS
   * @param {string} swapId
   * @returns {Promise<unknown>}
   */
  listenMicrosoftLogin(swapId: string = null) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    this.saveDebug({ message: `# listenMicrosoftLogin, [${swapId}]` })
    return new Promise((resolve, reject) => {
      const listen = this.fss.get('__swap', swapId).subscribe(swap => {
        if (swap.access_token && !swap.used) {
          this.http.get('https://graph.microsoft.com/v1.0/me', {
            headers: {
              'Authorization': swap.access_token,
              'Content-Type': 'application/json'
            }
          }).pipe(first()).toPromise().then((adUser: any) => {
            // console.log('# adUser', adUser);
            if (!adUser || !adUser.mail || adUser.mail.toLowerCase().indexOf('.onmicrosoft.com') > -1) {
              this.saveDebug({ message: `# adUser email invalido, [${swapId}]`, stack: JSON.stringify(adUser) })
              this.notify.update('O teu email não é valido, por favor contacte o HelpDesk', 'btn-danger', 6000)
              return
            }
            this.saveDebug({ message: `# adUser, [${swapId}]`, stack: JSON.stringify(adUser) })
            this.authMicrosoftWithFirebase(adUser).then(_user => resolve(_user)).catch(_er => reject(_er))
            this.fss.merge('__swap', swapId, { used: true }, { silent: true }).then(() => {
              this.fss.delete('__swap', swapId).then()
            })
          }).catch(async er => {
            this.notify.update(er.message, 'btn-danger', 3000)
            // await sleep(3000);
            // if (this.g.isNative) window.close();
            // else this.router.navigate(['/login']);
            reject(er)
          })
          listen.unsubscribe()
        }
      })
      // return swapId;
    })
  }

  /**
   * Faz a autenticação no FirebaseAuth usando o user que veio do Microsoft AD
   * @param adUser
   * @returns {Promise<unknown>}
   */
  authMicrosoftWithFirebase(adUser: any) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    const api: ApiService = use<ApiService>(ApiService)
    return new Promise((resolve, reject) => {
      // console.log('#adUser', adUser);
      api.post('user/userAction?noAuthRequest=true', {
        action: 'validateToken',
        uid: adUser.uid || adUser.id,
        email: adUser.mail || adUser.givenName
      }, true).pipe(first()).toPromise().then(ok => {
        if (ok) {
          if (ok.success) {
            this.saveDebug({ message: `# validateToken `, stack: ok.token })
            this.authWithMicrosoftCredential(ok.token, adUser)
              .then((user: any) => {
                if (!user.error) {
                  this.saveDebug({ message: `# authWithMicrosoftCredential `, stack: JSON.stringify(user) })
                  api.post('users/updateUserAuth', { uid: user.uid, displayName: adUser.displayName, email: /*'msal__' + */adUser.mail }, true)
                    .pipe(first()).toPromise().then(ok => !env.production && console.log('#updateUserAuth OK', ok)).catch(_er => console.log('#updateUserAuth', _er.message))
                  user.firstLogin = user.firstLogin || new Date().toISOString()
                  user.lastLogin = new Date().toISOString()
                  if (user.id) this.fss.update('users', user.id, { createdAt: user.createdAt, firstLogin: user.firstLogin, lastLogin: user.lastLogin }, { silent: true }).then()
                  resolve(user)
                } else {
                  this.saveDebug({ message: `# authMicrosoftWithFirebase `, stack: JSON.stringify(user) })
                  this.notify.update(this.transloco.translate(user.error), 'btn-danger', 6000)
                  resolve(user)
                }

              })
              .catch(async (error) => {
                this.notify.update(error.message, 'btn-danger', 3000)
                reject(error)
              })
          }

          // ERror
          if (ok.error) {
            this.notify.update(this.transloco.translate(ok.error), 'btn-danger', 6000)
            resolve(ok)
          }
        }
      }).catch(err => {
        this.saveDebug({ ...err, message: `# validateToken ERROR ` + err.message })
        reject(err)
      })
    })
  }

  signInWithCustomToken(token) {
    return this.auth.afAuth.signInWithCustomToken(token)
  }

  /**
   * Cria um utilizador para o login Microsoft (se não existir) verificando os prospects
   * @param {string} token
   * @param adUser
   * @returns {Promise<{error: string} | firebase.User | void>}
   */
  authWithMicrosoftCredential(token: string, adUser: any) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    const api = use<ApiService>(ApiService)
    return this.auth.afAuth.signInWithCustomToken(token)
      .then(async (result) => {
        let msUser: any = null
        const dbUser = await this.fss.get('users', result.user.uid, { api: true }).pipe(first()).toPromise()
        // const prospect = await this.fss.getBy('prospects', 'email', adUser.mail.toLowerCase().replace('@some.where', '@ageas.pt'), { api: true }).pipe(first()).toPromise()
        const prospects: any[] = await api.post('db/action', { action: 'list', collection: 'prospects', nick: this.g.nick, queryArray: [['used', '==', false]] }, true).pipe(first()).toPromise()
        const prospect = prospects.find(p => p && p.email && p.email.toLowerCase() === adUser.mail.toLowerCase().replace('@some.where', '@ageas.pt')) || null

        if (!dbUser) {
          msUser = {
            id: result.user.uid,
            uid: result.user.uid,
            displayName: adUser.displayName || '',
            name: adUser.displayName || '',
            firstName: (adUser.displayName || '').split(' ')[0],
            email: adUser.mail.toLowerCase(),
            emailVerified: result.user.emailVerified || true,
            isAnonymous: result.user.isAnonymous || false,
            photo: result.user.photoURL || null,
            cart: [],
            wishlist: [],
            addresses: [],
            balance: 0,
            nick: this.g.nick,
            active: true,
            role: 'user',
            segment: null,
            provider: 'microsoft',
            providerId: adUser.id || '',
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            firstLogin: new Date().toISOString(),
            lastLogin: new Date().toISOString(),
          }

          if (this.g.program.logins.lookup) {
            if (prospect && prospect.active && !prospect.used) {
              msUser.segment = prospect.segment || null
              msUser.segments = prospect.segments || []
              msUser.displayName = msUser.displayName || prospect.displayName || ''
              msUser.role = prospect.role || 'user'

              // cria o user via cripted API
              await api.post('db/action', {
                action: 'createUser',
                id: result.user.uid,
                nick: this.g.nick,
                data: msUser
              }, true).pipe(first()).toPromise().then(() => {
                this.fss.merge('prospects', prospect.id, { used: true })
              })

              // metodo de criação antigo
              // await this.fss.set('users', result.user.uid, msUser).then(() => {
              //   this.fss.merge('prospects', prospect.id, { used: true })
              // })
            } else return { error: 'user_not_allowed_or_has_registed' }
          } else {
            await api.post('db/action', {
              action: 'createUser',
              id: result.user.uid,
              nick: this.g.nick,
              data: msUser
            }, true).pipe(first()).toPromise()

            // meto de criação antigo
            // await this.fss.set('users', result.user.uid, msUser)
          }

        } else if (!dbUser.active) {
          this.auth.signOut(false)
          return { error: 'user_not_registed_or_not_activated' }
        }

        this.fss.update('users', result.user.uid, { createdAt: result.user.createdAt || undefined, lastLogin: new Date().toISOString() }, { silent: true }).then()

        await this.updateUserSegment(dbUser || msUser, adUser)

        return result.user
      })
      .catch((error) => {
        this.saveDebug({ message: `# authWithMicrosoftCredential `, stack: error })
        console.log('#error', error)
      })
  }

  saveDebug(error: any) {
    const user = this.g.get('user')
    const err = {
      error: {
        message: error.message,
        stack: error.stack || '',
        name: error.name || ''
      },
      path: window.location.href,
      user: user ? user.email : 'indefinido',
      nick: this.g.nick,
      timestamp: new Date().getTime(),
      createdAt: new Date().toISOString(),
      build: BUILD
    }
    const api = use<ApiService>(ApiService)
    api.post(`user/userAction?noAuthRequest`, { action: 'debug', err }, true).pipe(first()).toPromise()
  }

  //////// API NOSS@GENTE ////////

  getConfig(): Promise<any> {
    // return {
    //   url: 'https://bsx-ts.ocidentalgrupo.pt/sh1.ashx/ageas-pt-api-nossagente-commercialstructure-channel/',
    //   endpoints: {
    //     user: `v1/CommercialStructure/user`,
    //     classifications: 'v2/Classifications/AgentClassifications',
    //     structure: `v1/CommercialStructure/structure`,
    //     deltas: `v1/CommercialStructure/deltas`
    //   },
    //   traceparent: '00-f1a9e919dd0a0d4a807b646096876765-444c12c44e877947-01',
    //   bsUsername: 'testyesmkt@ageas.pt',
    //   bsUser: 'SVTSNOSSAGENTE',
    //   bsSubscriptionKey: '11abb564-1fc9-4228-8ac0-a19b32255681',
    //   client_id: '2c046805-ec71-4b48-90d4-9c42b4f2c3f5',
    //   client_secret: 'mPk8Q~D0k2bFGApmkOvQ29fLy2gjZeCxr01Yobrm',
    //   redirect_uri: 'https://nossagente.yesmktg.net/login/token',
    //   // redirect_uri: 'http://nossagente.localhost:4600/login/token',
    //   scope: 'api://broker/.default',
    //   code_challenge: 'NEuhN3fmMWtINl7o77LpO_wuxUS8VJPu1PgKXmI31HI'
    // }
    const api = use<ApiService>(ApiService)
    return api.post('open/d/action', {
      action: 'msadConfig',
      nick: this.g.nick,
    }, true).pipe(first()).toPromise()
  }

  getToken(code: string): Promise<any> {
    const api = use<ApiService>(ApiService)
    // return api.post('user/userAction', {
    return api.post('msad/msadAction', {
      // url: 'https://login.microsoftonline.com/e7f9d69c-13f3-457c-a04c-f555c1134fa4/oauth2/v2.0/token',
      action: 'requestADTokenV2',
      code,
      nick: this.g.nick
    }, true).pipe(first()).toPromise()
  }

  getAdUser(token: string) {
    const api = use<ApiService>(ApiService)
    // return api.post('user/userAction', { action: 'requestADUserV2', token, nick: this.g.nick }, true).pipe(first()).toPromise().then((ok: any) => {
    return api.post('msad/msadAction', { action: 'requestADUserV2', token, nick: this.g.nick }, true).pipe(first()).toPromise().then((ok: any) => {
      if (ok) {
        if (ok.success) {
          const adUser = {
            ...ok.data,
            uid: btoa(ok.data.id + ok.data.email).replace(/=/g, ''),
            mail: ok.data.email,
            displayName: ok.data.name || '',
          }

          return this.authMicrosoftWithFirebase(adUser)
        }

        // ERROR
        if (ok.error) {
          this.notify.update(ok.error, 'btn-danger', 4000)
          return ok
        }
      } else return ({ success: false, error: 'unknow error' })
    }).catch(e => console.error(e.message))
  }

  private updateUserSegment(dbUser: any, adUser: any): Promise<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    // console.log('# updateUserSegment', dbUser, adUser)
    return this.getConfig().then(_config => {
      if (_config && _config.success && _config.config && _config.config.updateSegment) {
        // O código a partir daqui é relativo ao programa do NOSSAGENTE e ao que recebemos do AD deles!
        return new Promise((resolve, reject) => {
          if (dbUser.providerId === adUser.id && adUser.commercialStructures && adUser.commercialStructures[0] && adUser.commercialStructures[0].level && adUser.commercialStructures[0].level.type) {
            if (adUser.networks && adUser.networks[0]) {
              let id = null, uniq = null, parentId = null, parentUniq = null, name = null
              if (adUser.commercialStructures[0].level.type === 'Network') {
                id = adUser.networks[0].code
                uniq = adUser.networks[0].code
                parentId = null
                parentUniq = null
                name = adUser.networks[0].name
                this.prepareSegment(dbUser, { id, parentId, parentUniq, name, uniq })
              }
              if (adUser.commercialStructures[0].level.type === 'Zone') {
                id = `${adUser.networks[0].code}_${adUser.networks[0].zones[0].code}`
                uniq = adUser.networks[0].zones[0].code
                parentId = adUser.networks[0].code
                parentUniq = adUser.networks[0].code
                name = adUser.networks[0].zones[0].name
                this.prepareSegment(dbUser, { id, parentId, parentUniq, name, uniq })
              }
              if (adUser.commercialStructures[0].level.type === 'Branch') {
                id = `${adUser.networks[0].code}_${adUser.networks[0].zones[0].code}_${adUser.networks[0].zones[0].branches[0].workArea}`
                uniq = adUser.networks[0].zones[0].branches[0].workArea
                parentId = `${adUser.networks[0].code}_${adUser.networks[0].zones[0].code}`
                parentUniq = adUser.networks[0].zones[0].code
                name = adUser.networks[0].zones[0].branches[0].name
                this.prepareSegment(dbUser, { id, parentId, parentUniq, name, uniq })
              }
              if (adUser.commercialStructures[0].level.type === 'Inspector') {
                id = `${adUser.networks[0].code}_${adUser.networks[0].zones[0].code}_${adUser.networks[0].zones[0].branches[0].workArea}_${adUser.networks[0].zones[0].branches[0].inspectors[0].code}`
                uniq = adUser.networks[0].zones[0].branches[0].inspectors[0].code
                parentId = `${adUser.networks[0].code}_${adUser.networks[0].zones[0].code}_${adUser.networks[0].zones[0].branches[0].workArea}`
                parentUniq = adUser.networks[0].zones[0].branches[0].workArea
                name = adUser.networks[0].zones[0].branches[0].inspectors[0].name
                this.prepareSegment(dbUser, { id, parentId, parentUniq, name, uniq })
              }
              if (adUser.commercialStructures[0].level.type === 'Agent') {
                this.fss.list('settings', { subcol: 'segmentsMapping', api: true }).toPromise().then(async segmentsMapping => {
                  // console.log('#segmentsMapping', segmentsMapping)
                  const classification = JSON.stringify(adUser.classification.agentClassification.classifications)
                  const asfNumber = adUser.networks[0].zones[0].branches[0].inspectors[0].agents[0].asfNumber

                  if (segmentsMapping.length && (!dbUser.classifications || (dbUser.classifications && dbUser.classifications !== classification) || !dbUser.segment)) {
                    let segmentName = null
                    for (const map of segmentsMapping) {
                      if (!segmentName && adUser.classification.agentClassification.classifications.filter(o => o.code === map.code && o.description === map.description).length) {
                        // console.log(map.segment)
                        segmentName = map.segment.toUpperCase()
                        await this.saveProfileSegment(adUser, segmentName)
                      }
                    }

                    dbUser.classifications = JSON.stringify(adUser.classification.agentClassification.classifications)

                    if (segmentName) {
                      const id = `${adUser.networks[0].code}_${adUser.networks[0].zones[0].code}_${adUser.networks[0].zones[0].branches[0].workArea}_${adUser.networks[0].zones[0].branches[0].inspectors[0].code}_${segmentName}_${asfNumber}`
                      const parentId = `${adUser.networks[0].code}_${adUser.networks[0].zones[0].code}_${adUser.networks[0].zones[0].branches[0].workArea}_${adUser.networks[0].zones[0].branches[0].inspectors[0].code}_${segmentName}`
                      const name = adUser.networks[0].zones[0].branches[0].inspectors[0].agents[0].name

                      await this.prepareSegment(dbUser, { id, parentId, parentUniq: segmentName, name, uniq: asfNumber, lowestLevel: true })
                    }
                  }
                  // if (!dbUser.externalId || dbUser.externalId !== asfNumber) this.fss.update('users', dbUser.uid, { externalId: asfNumber }, { silent: true }).then()
                })
              }
            }
          }
          resolve(true)
        })
      }
      return Promise.resolve(null)
    })
  }

  private prepareSegment(dbUser, segment) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    // console.log('#getSegment', parentId, name)
    this.fss.getBy('segments', 'uniq', segment.uniq).pipe(first()).toPromise().then(async _segment => {
      if (_segment) segment.name = _segment.name
      const newSegment = {
        active: true,
        id: segment.id,
        hasRank: true,
        lowestLevel: segment.lowestLevel || false,
        name: segment.name,
        parentId: segment.parentId,
        parentUniq: segment.parentUniq,
        uniq: segment.uniq,
        xrate: 1
      }
      if (!dbUser.segment || dbUser.segment.id !== segment.id) {
        this.fss.get('segments', segment.id).pipe(first()).toPromise().then(segment => {
          this.saveUserSegment(dbUser, _segment, newSegment)
        })
      }
    })
  }

  private saveUserSegment(dbUser: any, segment = null, newSegment) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    // console.log('#saveUserSegment', segment, newSegment)
    if (segment) {
      dbUser.segment = segment
      this.fss.update('users', dbUser.uid, dbUser, { silent: true }).then()
    } else {
      this.fss.set('segments', newSegment.id, newSegment, { silent: true }).then(res => {
        dbUser.segment = newSegment
        this.fss.update('users', dbUser.uid, dbUser, { silent: true }).then()
      })
    }
  }

  private saveProfileSegment(adUser, segmentName) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    const newSegment = {
      active: true,
      id: null,
      name: null,
      parentId: null,
      parentUniq: null,
      hasRank: true,
      uniq: null,
      xrate: 1
    }

    const branchId = `${adUser.networks[0].code}_${adUser.networks[0].zones[0].code}_${adUser.networks[0].zones[0].branches[0].workArea}`
    const inspectorId = `${adUser.networks[0].code}_${adUser.networks[0].zones[0].code}_${adUser.networks[0].zones[0].branches[0].workArea}_${adUser.networks[0].zones[0].branches[0].inspectors[0].code}`
    const id = `${adUser.networks[0].code}_${adUser.networks[0].zones[0].code}_${adUser.networks[0].zones[0].branches[0].workArea}_${adUser.networks[0].zones[0].branches[0].inspectors[0].code}_${segmentName}`

    this.fss.get('segments', `${adUser.networks[0].code}_${adUser.networks[0].zones[0].code}`).pipe(first()).toPromise().then(async segment => {
      if (!segment) {
        newSegment.id = `${adUser.networks[0].code}_${adUser.networks[0].zones[0].code}`
        newSegment.name = adUser.networks[0].zones[0].name
        newSegment.parentId = adUser.networks[0].code
        newSegment.parentUniq = adUser.networks[0].code
        newSegment.uniq = adUser.networks[0].zones[0].code

        await this.fss.set('segments', newSegment.id, newSegment, { silent: true }).then()
      }
    })
    this.fss.get('segments', branchId).pipe(first()).toPromise().then(async segment => {
      if (!segment) {
        newSegment.id = branchId
        newSegment.name = adUser.networks[0].zones[0].branches[0].name
        newSegment.parentId = `${adUser.networks[0].code}_${adUser.networks[0].zones[0].code}`
        newSegment.parentUniq = adUser.networks[0].zones[0].code
        newSegment.uniq = adUser.networks[0].zones[0].branches[0].workArea

        await this.fss.set('segments', newSegment.id, newSegment, { silent: true }).then()
      }
    })
    this.fss.get('segments', inspectorId).pipe(first()).toPromise().then(async segment => {
      if (!segment) {
        newSegment.id = inspectorId
        newSegment.name = adUser.networks[0].zones[0].branches[0].inspectors[0].name
        newSegment.parentId = branchId
        newSegment.parentUniq = adUser.networks[0].zones[0].branches[0].workArea
        newSegment.uniq = adUser.networks[0].zones[0].branches[0].inspectors[0].code

        await this.fss.set('segments', newSegment.id, newSegment, { silent: true }).then()
      }
    })
    this.fss.get('segments', id).pipe(first()).toPromise().then(async segment => {
      if (!segment) {
        newSegment.id = id
        newSegment.name = segmentName
        newSegment.parentId = inspectorId
        newSegment.parentUniq = adUser.networks[0].zones[0].branches[0].inspectors[0].code
        newSegment.uniq = segmentName

        await this.fss.set('segments', newSegment.id, newSegment, { silent: true }).then()
      }
    })
  }
}
