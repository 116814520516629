import { Injectable } from '@angular/core'
import { createClient } from 'pexels'

@Injectable({ providedIn: 'root' })
export class PexelsService {
  client
  constructor() {
    console.info(`## ${this.constructor.name}`)
    this.client = createClient('563492ad6f91700001000001740f0b38f467458f9df1cd39885089e1')
  }
  getBoth(count): any {
    const query = 'Business People'
    const photos = this.client.photos.search({ query, per_page: count })
    const videos = this.client.videos.search({ query, per_page: count })
    return Promise.all([photos, videos]).then((media) => {
      const posts = []
      for (const m of media) {
        if (m['photos']) {
          for (const p of m['photos']) {
            if (p.width > p.height) posts.push({ option: 'P', image: p['src']['medium'], class: 'panel', headline: 'Título' })
          }
        }
        if (m['videos']) {
          for (const v of m['videos']) {
            const link = v['video_files'][0]['link']
            const type = link.indexOf('mp4') >= 0 ? 'video/mp4' : 'unknown'
            posts.push({ option: 'V', image: v['image'], linkVideo: link, class: 'panel long', headline: 'Título', type: type })
          }
        }
      }
      return posts
    })
  }
}
