import {Component, Input, OnInit} from '@angular/core'
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog"

@Component({
  selector: 'prompt-modal',
  templateUrl: './prompt-modal.component.html',
  styleUrls: ['./prompt-modal.component.sass']
})
export class PromptModalComponent implements OnInit {

  @Input() title: string
  @Input() message: string
  @Input() btnOkText: string
  @Input() btnCancelText: string
  text: string
  type = 'text'

  constructor(private config: DynamicDialogConfig, private ref: DynamicDialogRef) {
  }

  ngOnInit() {
    this.title = this.config.data.title || ''
    this.message = this.config.data.message || ''
    this.btnOkText = this.config.data.btnOkText || ''
    this.btnCancelText = this.config.data.btnCancelText || ''
    this.type = this.config.data.type || ''
  }

  close() {
    this.ref.close(false)
  }

  confirmAction() {
    this.ref.close(this.text)
  }

}
