import { CommonModule } from "@angular/common"
import { NgModule, Pipe, PipeTransform } from "@angular/core"
import orderBy from 'lodash-es/orderBy'

@Pipe({ name: "sort" })
export class ArraySortPipe  implements PipeTransform {
  transform(array: any, field: string): any[] {
    if (!Array.isArray(array) || !field) return array
    return orderBy(array, [field])
  }
}
@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ArraySortPipe,
  ],
  exports: [ArraySortPipe],
  providers: []
})
export class SortPipeModule { }
